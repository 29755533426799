.post-content-contaienr {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  box-sizing: border-box;
  /* cursor: pointer; */
  background: rgba(50, 58, 66, 0.6);
  overflow-x: hidden;
  animation: none;
  /* overflow-y: scroll;
  -webkit-overflow-scrolling: touch; */
}

.modal-spacer {
  height: 48px;
}
.post-content-wrap {
  margin: auto;
  width: 100%;
  min-height: Calc(100% - 48px);
  max-width: 960px;
  padding-bottom: 24px;
  box-sizing: border-box;
  background: #fff;
  cursor: default;
  font-size: 0.75rem;
  color: #323a42;
}

.content-pop-header,
.content-pop-content,
.content-pop-footer {
  padding: 0 16px;
}

.content-pop-header {
  /* background: #323a42; */
  background: #039be5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.content-pop-header .tab-seleciton-lin {
  margin: 0 16px;
}
.content-pop-header-close {
  padding: 6px;
}
.content-pop-header-close svg path {
  fill: rgba(255, 255, 255, 0.6);
}

.topic-container,
.text-container,
.title-container {
  margin: 16px 0;
}

.pop-lable {
  font-size: 0.75rem;
  margin-bottom: 4px;
  font-weight: 600;
}

/* .post-content-wrap .text-container textarea::placeholder, */
.post-content-wrap input::placeholder {
  font-size: 0.875rem;
  font-weight: 400;
  color: #b5c0c5;
}
.post-content-wrap select,
#emailinput {
  padding: 6px 12px;
}
/* .question-answer-wrapper input,
.question-answer-wrapper select {
  margin: 8px;
} */

.post-submission-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  box-sizing: border-box;
  min-width: 80px;
  margin-top: 0;
  margin-bottom: 0;
}

.content-pop-footer {
  /* width: 100%; */
  /* flex-direction: column; */
}
.content-pop-footer > *:first-child {
  margin-bottom: 12px;
}

.post-error-message,
.post-error-message svg,
.post-error-message svg path {
  stroke: rgb(249, 41, 83);
  color: rgb(249, 41, 83);
  margin-right: 12px;
}

/* LOCATOININPUT================================ */
.post-loc-input-wrap {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 5px;
  background: rgba(50, 58, 66, 0.1);
  z-index: 100;
}
.post-loc-input-crd {
  position: fixed;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  flex-direction: column;
  top: 48px;
  padding: 16px;
  margin: 16px;
  box-sizing: border-box;
  width: Calc(100% - 32px);
  height: 267px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.12),
    0px 5px 10px rgba(0, 0, 0, 0.09);
}

.post-loc-input-crd-hed {
  border-radius: 5px;
  border: 1px solid #cfd8dc;
  display: flex;
  align-items: center;
  padding: 0 5px;
  width: 100%;
}
.post-loc-input-wrap .post-loc-input-crd .post-loc-input-crd-hed svg {
  position: relative;
  top: 0;
  left: 0;
  min-width: 24px;
}
.post-loc-input-wrap
  .post-loc-input-crd
  .post-loc-input-crd-hed
  svg:last-of-type {
  padding: 4px;
  border-radius: 5px;
  background: #fff;
  cursor: pointer;
  transition: 0.1s ease;
}
.post-loc-input-wrap
  .post-loc-input-crd
  .post-loc-input-crd-hed
  svg:last-of-type:hover,
.post-loc-input-wrap
  .post-loc-input-crd
  .post-loc-input-crd-hed
  svg:last-of-type:hover
  path {
  background: rgba(52, 168, 254, 0.1);
  fill: #039be5;
}
.post-loc-input-wrap .post-loc-input-crd .post-loc-input-crd-hed svg path {
  fill: #cfd8dc;
}

.post-loc-input-wrap
  .post-loc-input-crd
  .post-loc-input-crd-hed
  .autocomplete-dropdown-container {
  position: absolute;
  width: Calc(100% - 32px);
  left: 16px;
  background: #fff;
}

.post-loc-input-wrap .post-loc-input-crd input {
  background: none;
  outline: none;
  border: none;
  height: 40px;
  box-sizing: border-box;
  width: 100%;
  padding: 0 8px;
}
.post-loc-input-wrap .post-loc-input-crd input:hover.post-loc-input-crd-hed {
  border: 1px solid #039be5;
}
.post-location-pop-back {
  text-align: center;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: -1;
  font-size: 1.25rem;
  font-weight: 600;
  color: #bcc2c7;
  padding-top: 40px;
}
.post-location-pop-back svg:first-of-type {
  position: absolute;
  bottom: 0;
  left: 0;
}
.post-location-pop-back svg:last-of-type {
  position: absolute;
  bottom: 0;
  right: 0;
}

@media only screen and (min-width: 768px) {
  .modal-spacer {
    height: 12%;
    min-height: 48px;
  }
  .post-content-wrap {
    width: Calc(100% - 32px);
    min-height: auto;
    border-radius: 10px;
    /* max-height: Calc(100% - 48px); */
  }
  .content-pop-header,
  .content-pop-content,
  .content-pop-footer {
    padding: 0 32px;
  }
  .content-pop-footer {
    /* width: 100%; */
    flex-direction: row;
  }
  .content-pop-header {
    border-radius: 5px 5px 0 0;
  }
  .content-pop-header-close {
    padding: 6px 16px;
  }
  .content-pop-header-close svg {
    display: none;
  }
  /* ------------------------------------------------------------- */
  .post-loc-input-wrap {
    display: flex;
    justify-content: center;
  }
  .post-loc-input-crd {
    position: relative;
    width: 400px;
  }
}

@media only screen and (min-width: 960px) {
  .post-content-wrap {
    /* padding: 32px; */
  }
}

@media only screen and (min-width: 1200px) {
  .post-content-wrap {
    /* padding: 32px; */
  }
}
