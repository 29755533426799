.forum-msg-content {
  min-height: 100%;
}
.forum-msg-wrap {
  padding: 16px 16px 80px;
  margin: 0 auto;
  box-sizing: border-box;
  min-height: 100%;
}
.forum-msg-grid {
  display: grid;
  grid-template-columns: 1fr;
}

.message-threads-wrap {
  display: none;
}

@media only screen and (min-width: 768px) {
  .forum-msg-wrap {
    padding: 0 0 80px;
    margin-top: 24px;
  }
  .forum-msg-grid {
    grid-template-columns: 256px 1fr;
    grid-gap: 24px;
  }
  .message-threads-wrap {
    display: block;
    width: 100%;
    height: 100%;
    padding: 24px 0;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
}
@media only screen and (min-width: 960px) {
  .forum-msg-grid {
    /* grid-template-columns: 1fr 256px; */
  }
  .more-container-wrap {
    width: 256px;
  }
}
