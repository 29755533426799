.pricing-page-wrap {
  margin: 0 16px;
}

/* .pricing-header h5 {
  font-size: 1.5rem;
  line-height: 28px;
  font-weight: 500;
  margin: 32px 0 16px;
}
.pricing-header h1 {
  font-size: 52px;
  line-height: 60px;
  font-weight: 500;
  margin: 32px 0;
} */

/* ---------------------------------------------------------- */
.table-card-wrap {
  margin-bottom: 24px;
}

/* .pricing-table-wrap-free {
  max-width: 650px;
  margin: 0 auto;
  padding: 0 0 32px;
  text-align: center;
}
.pricing-table-wrap-free h3 {
  font-size: 2rem;
  font-weight: 600;
  line-height: 54px;
  margin: 32px 0 8px;
}
.pricing-table-wrap-free p {
  font-size: 0.875rem;
  color: #757575;
  margin-bottom: 24px;
}
.pricing-table-wrap-free a {
  color: rgb(52, 168, 254);
} */

.pricing-table-wrap-paidLink {
  opacity: 0.6;
  transition: 0.1s ease-in-out;
  cursor: pointer;
}
.pricing-table-wrap-paidLink:hover {
  opacity: 1;
}

.pricing-table-wrap-paidLink p {
  font-size: 0.875rem;
  margin: 24px 0 0;
  color: #939eab;
}

.pricing-table-wrap-divider {
  margin: 32px 0 56px;
  width: 100%;
  border-top: 1px solid #cfd8dc;
}

.pricing-dislaimer {
  color: #939eab;
  height: 24px;
  margin: 8px 0;
  text-align: left;
  font-size: 0.75rem;
}
/* ---------------------------------------------------------- */

.prising-mobile-allPlans {
  text-align: center;
  padding: 32px 8px;
  border: 1px solid #eceff1;
  border-radius: 5px;
  margin-bottom: 24px;
  font-size: 0.75rem;
  color: #939eab;
}
.prising-mobile-allPlans div {
  font-size: 1.5rem;
  font-weight: 500;
  color: #323a42;
}

.pricing-icons-wrapper {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 16px auto;
}
.pricing-icon {
  margin: 6px;
  height: 36px;
  width: 36px;
  border-radius: 100%;
  background: #039be5;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ---------------------------------------------------------- */
.pricing-table-wrap table {
  display: none;
  margin-bottom: 48px;
}

@media only screen and (min-width: 768px) {
  .pricing-page-wrap {
    margin: 0;
  }
  .pricing-table-wrap {
    margin: 8px;
  }
  .table-card-wrap {
    margin-bottom: 48px;
  }
  /* .pricing-table-wrap-free p {
    font-size: 1rem;
    line-height: 28px;
  } */
  /* ---------------------------------------------------------- */
  .pricing-mobile-wrap {
    display: none;
  }
  /* ---------------------------------------------------------- */

  .table-card-wrap {
    margin-bottom: 48px;
  }

  /* ---------------------------------------------------------- */

  .pricing-table-wrap thead,
  th,
  td {
    /* box-sizing: content-box; */
    border-bottom: 1px solid #eceff1;
    border-right: 1px solid #eceff1;
    /* border: 0.5px solid #eceff1; */
  }

  .pricing-table-wrap table {
    display: table;
    border-left: 1px solid #eceff1;
    border-radius: 10px;
    width: 100%;
    padding: 0;
    margin: 0;
    table-layout: fixed;
  }
  .pricing-table-wrap table thead {
    /* border: 1px solid #eceff1; */
    border-radius: 10px;
    width: 100%;
  }
  .pricing-table-wrap table thead {
    width: 100%;
  }

  .pricing-table-wrap table tfoot tr th,
  .pricing-table-wrap table thead tr th {
    padding: 20px;
    text-align: left;
    font-weight: 500;
  }
  .pricing-table-wrap table tfoot tr td,
  .pricing-table-wrap table thead tr td {
    padding: 20px;
    text-align: left;
    font-size: 1.25rem;
    font-weight: 700;
  }
  .pricing-table-wrap table tfoot tr th div,
  .pricing-table-wrap table thead tr th div {
    margin-bottom: 8px;
  }
  .pricing-table-header-subtitle {
    font-size: 0.875rem;
    font-weight: 400;
  }
  .pricing-table-header-price {
    font-size: 1.5rem;
  }

  /* ---------------------------------------------------------- */

  .foot-product,
  .header-product {
    border: 1px solid #e8eaed;
    background: #e8eaed;
  }
  .foot-product {
    border-radius: 0 0 0 5px;
  }
  .header-product {
    border-radius: 5px 0 0 0;
  }

  .header-free-plan {
    border: 1px solid #f6fcfe;
    background: #f6fcfe;
    /* border: 1px solid #e1f5fe;
    background: #e1f5fe; */
    /* color: #2e3a4d; */
  }

  .header-flipper-plan {
    border: 1px solid #4ec2f7;
    background: #4ec2f7;
    color: #fff;
  }

  .header-pro-plan {
    border: 1px solid #039be5;
    background: #039be5;
    color: #fff;
  }

  .footer-enterprise-plan,
  .header-enterprise-plan {
    border: 1px solid #2e3a4d;
    background: #2e3a4d;
    color: #fff;
  }
  .header-enterprise-plan {
    border-radius: 0 5px 0 0;
  }
  .footer-enterprise-plan {
    border-radius: 0 0 5px 0;
  }

  /* ---------------------------------------------------------- */

  .pricing-table-wrap table tbody tr th,
  .pricing-table-wrap table tbody tr td {
    box-sizing: content-box;
    /* border: 1px solid #eceff1; */
    color: #939eab;
  }
  .pricing-table-wrap table tbody tr th {
    padding: 20px 20px;
    font-weight: 400;
    text-align: left;
  }

  .pricing-table-wrap table tbody tr td {
    padding: 40px 20px 20px;
    font-weight: 500;
  }

  .pricing-table-wrap table tbody tr th div:not(:first-child) {
    margin: 16px 0;
    line-height: 24px;
  }

  .pricing-table-wrap table tbody tr td p,
  .pricing-table-wrap table tbody tr td div {
    font-size: 1rem;
    color: #757575;
    font-weight: 500;
    line-height: 24px;
  }
  .pricing-table-wrap table tbody tr td p {
    margin: 0;
  }
  .pricing-table-wrap table tbody tr td div {
    margin: 16px 0;
  }
  .pricing-table-wrap table tbody tr td div svg {
    margin: auto;
  }

  .table-Products-header,
  .header-product {
    font-weight: 700;
    font-size: 1rem;
    color: #323a42;
  }

  .table-Products-name {
    cursor: default;
    transition: 0.05s ease-in-out;
  }
  .table-Products-name:hover {
    color: rgb(223, 226, 230);
    fill: #939eab;
  }
  .table-Products-name > * {
    margin-left: 12px;
  }

  .table-content-free {
    background: #f6fcfe;
    color: #757575;
  }
  /* ---------------------------------------------------------- */

  .pricing-table-wrap table tfoot button {
    margin: 16px 0;
    height: 36px;
    padding: 6px 24px;
  }

  /* ---------------------------------------------------------- */
}

@media only screen and (min-width: 960px) {
  .pricing-table-wrap {
    margin: 56px 24px;
  }
}
@media only screen and (min-width: 1200px) {
  .pricing-table-wrap {
    margin: 72px 56px;
  }
  /* .pricing-table-wrap-free h3 {
    font-size: 2.75rem;
    margin-bottom: 16px;
  } */
}
