.switch-comp-container {
  width: 36px;
  height: 36px;
}

.switch-comp-wrapper {
  height: 16px;
  width: 28px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  position: relative;
  /* justify-content: flex-start; */
  transition: 0.15s ease-in-out;
}

.switch-comp-dot {
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(76, 93, 110, 0.2),
    0px 0px 0px rgba(76, 93, 110, 0.2);
  width: 12px;
  height: 12px;

  border-radius: 100%;
  transition: 0.15s ease-in-out;
  margin: 2px;
  position: absolute;
  left: 0;
}

.active-switch .switch-comp-wrapper {
  background: #34a8fe;
  text-align: right;
  /* justify-content: flex-end; */
}
.active-switch .switch-comp-dot {
  left: 12px;
}

.plan-switch-container {
  text-align: left;
  cursor: pointer;
}
.plan-switch-container p {
  margin: 0 0 0 12px;
  transition: 0.15s ease;
}
.plan-switch-container:hover p {
  color: #fff;
  opacity: 1;
}

.plan-auth-button-wrap button {
  padding: 6px 32px;
  margin-left: 12px;
}

.plan-auth-button-wrap {
  flex-direction: column;
}
.plan-auth-button-wrap > *:first-child {
  margin-bottom: 24px;
}

@media only screen and (min-width: 360px) {
  .plan-auth-button-wrap {
    flex-direction: row;
    align-items: center;
    margin-bottom: 24px;
  }
  .plan-auth-button-wrap > *:first-child {
    margin-bottom: 0;
  }
}
