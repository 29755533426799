.banner-header {
  margin: 0 16px;
}
.banner-header a {
  color: #fff;
  font-size: 1.25rem;
  font-weight: 400;
  margin: 16px 0;
}

/* BANNER STUFF_______________________________ */

.banner-behavior-wrap {
  width: 100%;
  top: 0;
  /* position: relative; */
  position: fixed;
  top: 0;
  margin-top: 48px;
  box-sizing: content-box;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
  z-index: 80;
  /* z-index: 100; */
}

.forum-content {
  position: relative;
  box-sizing: content-box;
  /* border: 1px solid red; */
}

/* ____________________________________________ */
@media only screen and (min-width: 768px) {
  .banner-header {
    margin: 0;
  }
  .banner-header a {
    height: 34px;
    margin: 20px 0 2px;
  }
}
