/* ----------------------------------------------------s */

.home-console-container {
  position: relative;
  margin: 2rem 0;
}
.home-console-container-background {
  position: absolute;
  z-index: -1;
  left: 50%;
  width: 100%;
  height: 100%;
  max-width: 1320px;
}
.home-console-container-background > * {
  position: absolute;
}
.home-console-container-background > *:first-child {
  left: -50%;
  top: 0;
}
.home-console-container-background > *:last-child {
  right: 50%;
  bottom: -10%;
}

/* ---------------------------------------------------------- */
.home-console {
  position: sticky;
  text-align: center;
  font-size: 1.3125rem;
  line-height: 1.71429;
  font-weight: 300;
  margin-bottom: 1rem;
  padding: 0 16px;
}
.home-console > * a {
  margin: 32px auto 0;
}
/* .home-console > * a svg {
  margin-left: 8px;
} */

.home-console img {
  max-width: 100%;
}
.home-console-header {
  margin: auto;
  max-width: 750px;
}
.home-console-header h2 {
  font-size: 2rem;
  font-weight: 700;
  margin: 0 auto 16px;
}
.home-console-header p {
  font-size: 0.875rem;
  color: #757575;
  font-weight: 400;
  max-width: 600px;
  margin: 0 auto 32px;
}

.console-product-button-wrap {
  /* border: 1px solid red; */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
/* ----------------------------------------------------s */

@media only screen and (min-width: 768px) {
  .home-console-header h2 {
    font-size: 2.75rem;
  }
  .home-console-header p {
    font-size: 1rem;
    margin: 0 auto 56px;
  }
}
@media only screen and (min-width: 960px) {
}
@media only screen and (min-width: 1200px) {
}

/* ----------------------------------------------------s */
