.dropdown-wrap {
  color: #fff;
  font-weight: 500;
  font-size: 0.875rem;
  padding: 4px 8px 4px 16px;
  cursor: pointer;
  transition: 0.2s ease-out;
  border-radius: 5px;
}

.dropdown-wrap:hover {
  background: rgba(255, 255, 255, 0.1);
}
.dropdown-wrap:hover > *:last-child {
  opacity: 1;
}

.dropdown-wrap > *:last-child {
  opacity: 0.5;
  margin-left: 8px;
  transition: 0.2s ease-out;
}
