.filter-col-cont {
  /* height: Calc(100vh - 56px - 48px - 48px); */
  margin-top: -24px;
  position: fixed;
  width: 210px;
  z-index: 99;
  /* border: 1px solid red; */
  box-sizing: content-box;
  overflow-x: hidden;
  overflow-y: scroll;
}

/* width */
.filter-col-cont::-webkit-scrollbar {
  width: 0;
  height: 0;
}

/* Track */
.filter-col-cont::-webkit-scrollbar-track {
  background: none;
  width: 0;
}

/* Handle */
.filter-col-cont::-webkit-scrollbar-thumb {
  width: 0;
  background: none;
}

/* ------------------------------------------------------------- */

.filter-col-head {
  margin: 24px 0 12px;
  padding: 8px 16px;
  font-weight: 600;
  font-size: 16px;
  color: #28323c;
  background: #f4f6f8;
  width: 100%;
}
.filter-col-head > *:first-child,
.filter-col-cont-blk-cnt div > *:first-child {
  margin-right: 12px;
}

.filter-col-cont-blk-hd {
  height: 36px;
  padding: 0 16px;
  background: rgba(148, 156, 166, 0.1);
  cursor: pointer;
  transition: 0.15s ease;
  font-weight: 600;
  font-size: 14px;
  color: #28323c;
}

.filter-col-cont-blk-hd svg path {
  fill: rgba(148, 156, 166, 0.1);
  transition: 0.15s ease;
}

.filter-col-cont-blk-hd:hover,
.filter-col-cont-blk-hd:hover svg path {
  fill: #949ca6;
  background: rgba(148, 156, 166, 0.2);
}

.filter-col-cont-blk-cnt-wrap {
  transition: 0.15s ease-in-out;
  overflow-y: hidden;
}

.filter-col-cont-blk-cnt-wrap > *:first-child {
  margin-top: 8px;
}
.filter-col-cont-blk-cnt-wrap > *:last-child {
  margin-bottom: 16px;
}
.filter-col-cont-blk-cnt {
  padding: 4px 16px;
  font-weight: 500;
  font-size: 14px;
  color: #949ca6;
  cursor: pointer;
  transition: 0.15s ease;
}
.filter-col-cont-blk-cnt svg path {
  fill: #949ca6;
}
.filter-col-cont-blk-cnt:hover,
.filter-col-cont-blk-cnt:hover svg path {
  color: #039be5;
  fill: #039be5;
}
.selected-filter,
.selected-filter svg path {
  color: #039be5;
  fill: #039be5;
}
.filter-drop-wrap {
  position: absolute;
  left: 0;
  width: 100%;
  border-radius: 5px;
  box-sizing: border-box;
  background: #fff;
  transition: 0.15s cubic-bezier(0, 1, 0.1, 1);
  z-index: 999;
  opacity: 0;
  overflow: hidden;
  transform: translateY(18px);
  font-size: 14px;
}
.filter-drop-wrap > *:first-child {
  margin-top: 16px;
}
.filter-drop-wrap-open {
  opacity: 1;
  transform: translateY(0);

  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.12), 0px 3px 6px rgba(0, 0, 0, 0.09);
}

.filter-topics-more {
  height: 36px;
  cursor: pointer;
}
.filter-topics-more-lin {
  position: absolute;
  left: 16px;
  z-index: -1;
  width: Calc(100% - 32px);
  border-bottom: 1px solid #dbdee2;
  transition: 0.1s ease;
}
.filter-topics-more-cont {
  border: 1px solid #dbdee2;
  border-radius: 5px;
  font-size: 0.75rem;
  padding: 0 12px;
  height: 24px;
  background: #f4f6f8;
  transition: 0.1s ease;
  color: #949ca6;
}
.filter-topics-more:hover .filter-topics-more-cont {
  border: 1px solid #039be5;
  color: #039be5;
}
.filter-topics-more:hover .filter-topics-more-lin {
  border-bottom: 1px solid #039be5;
}

/* DROPDOWN------------------------------- */
.filter-col-cont-srch-container {
  position: absolute;
  z-index: 12;
  width: 100%;
  margin-top: 44px;
  padding: 0 0 0 16px;
}
.filter-col-cont-srch-container svg {
  min-width: 24px;
}
.filter-col-cont-srch-container input {
  transition: 0.2s ease-in-out;
  outline: 0;
  background: none;
  border: 0;
  font-size: inherit;
  color: #949ca6;
  width: 100%;
  font-size: 0.875rem;
  border-radius: 0;
}
.filter-col-cont-srch-container input:focus {
  margin-left: -12px;
}

.filter-col-cont-srch-container input::placeholder {
  font-weight: 500;
  font-size: 14px;
  color: #949ca6;
}

.filter-col-cont-srch-container .autocomplet-drop-shadow {
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.12), 0px 3px 6px rgba(0, 0, 0, 0.09);
}

.filter-col-cont-srch-container .form-input-suggestions {
  text-align: left;
  border-radius: 5px;
  font-size: 0.875rem;
  color: #757575;
  box-sizing: border-box;
  background: #fff;
}
.filter-col-cont-srch-container .form-input-suggestions > * {
  padding: 8px 12px;
  box-sizing: border-box;
}
.filter-col-cont-srch-container .form-input-suggestions > *:hover {
  color: #34a8fe;
  background: #fff;
}
.filter-col-cont-srch-container .form-input-suggestions > *:first-child {
  padding-top: 16px;

  border-radius: 5px 5px 0 0;
}
.filter-col-cont-srch-container .form-input-suggestions > *:last-child {
  padding-bottom: 16px;
  border-radius: 0 0 5px 5px;
}

.filter-col-loc-close {
  padding: 4px;
  border-radius: 5px;
  transition: 0.1s ease;
  cursor: pointer;
}
.filter-col-loc-close:hover,
.filter-col-loc-close:hover svg path {
  background: rgba(148, 156, 166, 0.1);
  fill: #34a8fe;
}
