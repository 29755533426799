.auth-page-wrapper {
  width: 100%;
  height: 100%;
  min-height: 80vh;
}
.signin-container {
  background: #28323c;
  box-sizing: border-box;
  max-width: 280px;
  width: Calc(100% - 32px);
  margin: 16px auto;
  color: #8093a2;
  border-radius: 10px;
  padding: 16px 24px;
}
.pricing-pans-continer {
  max-width: 420px;
}

.signin-up-wrap {
  color: #fff;
  text-transform: uppercase;
  border-bottom: 0.5px solid #8093a2;
}
.signin-up-button {
  width: 150%;
  height: 36px;
  justify-content: center;
}
.signin-up-hilight {
  height: 3px;
  background: #34a8fe;
  width: 50%;
  margin-left: 50%;
  transition: 0.2s ease-in-out;
  border-radius: 0 0 5px 5px;
  padding: 0 8px;
}

.auth-button-wrap {
}

.third-party-wrap {
  font-size: 0.75rem;
  text-align: left;
  box-sizing: border-box;
  padding: 16px 0 0;
}
.third-party-button-wrap {
  margin: 6px 0 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 8px;
}
.third-party-button-wrap button {
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.usernam-button-sub:disabled:hover {
  cursor: default;
  background: rgba(255, 255, 255, 0.1);
}

.signin-divider {
  height: 44px;
  flex-direction: column;
  justify-content: center;
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.5);
}
.signin-dividerline {
  margin: 10px 0 -10px;
  width: 100%;
  border: 0.5px solid rgba(255, 255, 255, 0.1);
}
.signin-divider-or {
  background: #28323c;
}

.signin-container input {
  width: 100%;
  height: 36px;
  margin-bottom: 0px;

  padding: 0 12px;
  font-size: 0.875rem;
  font-weight: 400;
  color: #fff;

  display: flex;
  align-items: center;

  background: rgba(255, 255, 255, 0.1);
  border: none;
  outline: none;
  box-sizing: border-box;
  border-radius: 5px;

  transition: 0.1s ease;
}
.signin-container input:hover {
  background: rgba(255, 255, 255, 0.05);
}
.signin-container input:focus {
  background: rgba(255, 255, 255, 0.2);
}

.signin-container input:invalid {
  /* background: rgba(255, 255, 255, 0.1); */
  border: 1px solid #ff4e71;
  color: #fff;
}

.signin-container input::placeholder {
  color: #919498;
}

.password-feedbask-container {
  height: 0;
  width: 100%;
  overflow: hidden;
  transition: 0.15s ease-out;
  font-size: 0.875rem;
  font-weight: 300;

  color: #fff;
  /* display: grid;
  grid-template-columns: repeat(3, 1fr); */
}

.pricing-plans-learn-more {
  height: 20px;
  display: flex;
  align-items: flex-end;
  text-align: right;
  font-size: 0.875rem;
  font-weight: 200;
  color: rgba(255, 255, 255, 0.5);
  transition: 0.1s ease;

  text-transform: none;
}

.pricing-plans-learn-more:hover {
  color: #fff;
}

#password-signup:focus + .password-feedbask-container {
  height: 42px;
  border-radius: 0 0 5px 5px;
  margin: -5px 0 4px;
  padding: 5px 12px 0;
  box-sizing: border-box;
  text-align: left;

  border: 1px solid rgba(255, 255, 255, 0.1);
  border-top: none;
  /* border: 1px solid red; */
}

.signup-img-cont {
  position: relative;
  margin: 32px auto 18px;
}
.signup-img-wrap {
  border-radius: 10px;
  overflow: hidden;
  width: 64px;
  height: 64px;
  margin: auto;
  box-sizing: content-box;
  border: 4px solid rgba(255, 255, 255, 0.1);
}
.signup-img-wrap img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.signup-imgChng-wrap {
  position: absolute;
  right: -12px;
  bottom: -12px;
}

.signup-imgChng-button {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background: #34a8fe;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
  position: absolute;
  cursor: pointer;
}

/* .signup-imgChng-button:hover {
  opacity: 0.5;
} */

#avatar {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  opacity: 0;
  border: 1px solid red;
  cursor: pointer;
  /* pointer-events: none; */
  box-sizing: border-box;
  /* padding-left: 32px; */
}

.username-input {
  margin-bottom: 24px;
}
.errorMessage-wrap {
  font-size: 0.75rem;
  /* height:  */
  text-align: left;
  margin: 0 0 4px;
}

.signup-disclaimer {
  font-size: 0.625rem;
  margin-bottom: 16px;
}
.form-error {
  color: #ff4e71;
}
