.pricing-table-wrap-free {
  max-width: 650px;
  margin: 0 auto;
  padding: 0 0 32px;
  text-align: center;
}
.pricing-table-wrap-free h3 {
  font-size: 2rem;
  font-weight: 600;
  line-height: 54px;
  margin: 32px 0 8px;
}
.pricing-table-wrap-free p {
  font-size: 0.875rem;
  color: #757575;
  margin-bottom: 24px;
}
.pricing-table-wrap-free a {
  color: rgb(52, 168, 254);
}


@media only screen and (min-width: 768px) {
  .pricing-table-wrap-free p {
    font-size: 1rem;
    line-height: 28px;
  }
}

@media only screen and (min-width: 960px) {

}

@media only screen and (min-width: 1200px) {
  .pricing-table-wrap-free h3 {
    font-size: 2.75rem;
    /* font-weight: 500; */
    margin-bottom: 16px;
  }
}
