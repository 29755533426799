.form-card-container section {
  max-width: 940px;
}

.pricing-table-wrap-free {
  width: 100%;
}
.question-wrapper {
  text-align: left;
  margin: auto;
  /* max-width: 300px; */
  padding-bottom: 32px;
}
.question-wrapper p {
  color: inherit;
  margin-bottom: 8px;
  font-size: 1rem;
}

.question-answer-wrapper {
  margin: 8px 0 8px 8px;
}

.input-wrapper {
  margin-bottom: 32px;
  display: grid;
  grid-gap: 24px;
  grid-template-columns: 1fr;
}

.input-text {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  font-size: 0.75rem;
  color: #323a42;
}

.input-text input,
.input-text textarea,
.input-text select {
  box-sizing: border-box;
  margin-top: 4px;
  outline: none;
  border: 1px solid #cfd8dc;
  border-radius: 5px;
  width: 100%;
}
.input-text input,
.input-text select {
  height: 36px;
}
.input-text select,
#emailinput {
  padding: 6px 12px;
}
.input-text textarea {
  height: 200px;
  width: 100%;
  font-family: inherit;
  padding: 6px 12px;
}

.question-answer-wrapper input,
.question-answer-wrapper textarea,
.question-answer-wrapper select {
  margin: 8px;
}

.autocomplet-drop-shadow {
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
}

.form-input-suggestions {
  text-align: left;
  border-radius: 5px;

  /* padding: 12px; */
  font-size: 0.875rem;
  color: #757575;
  box-sizing: border-box;
}
.form-input-suggestions > * {
  padding: 8px 12px;
  box-sizing: border-box;
  margin: 2px 0;
}
.form-input-suggestions > *:hover {
  color: #34a8fe;
  background: #f4f6f8;
}
.form-input-suggestions > *:first-child {
  padding-top: 16px;

  border-radius: 5px 5px 0 0;
}
.form-input-suggestions > *:last-child {
  padding-bottom: 16px;
  border-radius: 0 0 5px 5px;
}

.question-answer-wrapper label {
  margin-left: 8px;
  text-align: left;
  color: #757575;
  display: inline-block;
  font-size: 0.875rem;
}

.suportMsg {
  align-items: flex-start;
}
.suportMsg > * {
  min-width: 24px;
}
.suportMsg > *:first-child {
  margin-right: 8px;
}
.beta-form-legal,
.suportMsg p {
  text-align: left;
  font-size: 0.875rem;
  color: #757575;
  line-height: 22px;
}

@media only screen and (min-width: 768px) {
  .input-wrapper {
    grid-template-columns: 1fr 1fr;
  }
}
@media only screen and (min-width: 1200px) {
  .pricing-table-wrap {
    margin: 72px 56px;
  }
}
