.pricing-mobile-section {
  border-radius: 5px;
  border: 1px solid #eceff1;
  margin-bottom: 24px;
}

.pricing-mobile-section-foot,
.pricing-mobile-section-head {
  padding: 16px;
  text-align: center;
}

.pricing-mobile-section-head {
  border-radius: 5px 5px 0 0;
  color: #fff;
}
.pricing-mobile-section-head > * {
  margin-bottom: 8px;
  font-size: 0.875rem;
  font-weight: 400;
  margin-bottom: 12px;
}
.pricing-mobile-section-head > *:first-child {
  font-size: 1.5rem;
  font-weight: 700;
  margin: 8px 0;
}
.pricing-mobile-section-head > *:last-child {
  font-size: 1.5rem;
  font-weight: 500;
}
.pricing-mobile-section-body {
  padding: 16px 0;
}
.pricing-mobile-section-foot {
  border-radius: 0 0 5px 5px;
}
.pricing-mobile-section-foot button {
  padding: 11.5px 24px;
  min-width: 50%;
}
