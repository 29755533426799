.No-content {
  margin: auto;
  display: flex;
  flex-direction: column;

  font-weight: 600;
  font-size: 1.5rem;
  line-height: 29px;

  text-align: center;

  color: #323a42;
  justify-content: center;
}
.No-content svg {
  width: 100%;
  max-width: 417px;
  margin: 0 auto -40px;
}
.No-content p {
  font-size: 16px;
  line-height: 24px;
  margin-top: 12px;
  color: #959a9f;
}
.Forum404 {
  margin-top: 24px;
  font-size: 3rem;
  color: #c7d1d9;
}
