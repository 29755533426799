/* TOPIC INPUT-------------------------------------------------------- */
.topic-wrapper {
  min-height: 40px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #cfd8dc;
  padding: 0 0 0 8px;
  /* overflow: hidden; */

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  /* flex-wrap: wrap; */
}
.fex-wrap {
  flex-wrap: wrap;
}
.topic-input-wrapper {
  position: relative;
  width: 100%;

  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.topic-wrapper input {
  border: none;
  background: none;
  margin: 0;
  padding: 0 12px 0 4px;
  width: 100%;
}

.topic-tag-wrapper {
  max-width: 100%;
  /* flex-wrap: wrap; */
}

.topic-tag {
  margin: 8px 8px 8px 0;
  height: 24px;
  border-radius: 5px;
  background: rgba(52, 168, 254, 0.1);
  color: #039be5;
  padding: 0 12px;
  font-weight: 600;
  cursor: pointer;
  transition: 0.1s ease;
  white-space: nowrap;
}

.topic-tag:hover {
  opacity: 0.5;
}
.topic-tag > *:first-child {
  margin-right: 4px;
}
.topic-tag svg {
  fill: #039be5;
}
.topic-tag svg path {
  fill: #039be5;
}

.tag-input-chrecterCount {
  font-size: 0.875rem;
  font-weight: 400;
  color: #b5c0c5;
}

.tag-input-warning {
  color: rgb(255, 203, 43);
}
.tag-input-err {
  color: rgb(249, 41, 83);
}
/* input counter--------------------------- */
.tag-input-chrecterCount svg {
  margin: 0;
  width: 40px;
  height: 40px;
}
.tag-input-chrecterCount svg circle {
  fill: none;
  stroke-width: 3;
  transition: 0.1s ease-in;
}
.tag-input-chrecterCount svg #gray {
  stroke: #f4f6f8;
  /* stroke: #b5c0c5; */
}

/* input dropdown--------------------------- */

.autocomplet-drop-shadow {
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
}
.topic-input-suggestions {
  visibility: hidden;
  position: absolute;
  z-index: 80;
  top: 100%;
  left: 0;
  background: #fff;
  text-align: left;
  border-radius: 5px;
  font-size: 0.875rem;
  box-sizing: border-box;
  cursor: pointer;
}
.topic-wrapper input:focus ~ .topic-input-suggestions {
  visibility: visible;
}
.topic-sugection-wrap {
  flex-wrap: nowrap;
}
.topic-input-suggestions > * {
  padding: 8px 12px;
  box-sizing: border-box;
  margin: 2px 0;
  transition: 0.1s ease;
}
.topic-sugection {
  font-weight: 500;
  color: #323a42;
}
.topic-sugection-follows {
  font-weight: 400;
  color: #b5c0c5;
  margin-left: 12px;
}
.topic-input-suggestions > *:hover,
.topic-input-suggestions > *:hover .topic-sugection {
  color: #34a8fe;
  background: #f9fafb;
}
.topic-input-suggestions > *:first-child {
  padding-top: 16px;
  border-radius: 5px 5px 0 0;
}
.topic-input-suggestions > *:last-child {
  padding-bottom: 16px;
  border-radius: 0 0 5px 5px;
}
