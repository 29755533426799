.button {
  background: none;
  color: inherit;
  border: none;
  border-radius: 5px;
  padding: 0;
  font: inherit;
  font-weight: 500;
  font-size: 0.875rem;

  outline: inherit;
  cursor: pointer;
  padding: 6px 16px;
  min-height: 36px;
  box-sizing: border-box;
  white-space: nowrap;

  transition: 0.15s ease-out;
}

.button-lg {
  height: 52px;
}
.button p,
.button > * {
  margin: 0;
}

.button-link-wrap {
  padding: 0;
  margin: 0;
}
.button-link-wrap > * {
  display: block;
  margin: 0;
}

.badge-button {
  padding: 0;
}
.button-blue {
  /* transition: 0.25s ease; */

  background: #039be5;
  color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.1);
}
.button-blue:hover {
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.12), 0px 3px 6px rgba(0, 0, 0, 0.09);
  /* box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.09); */
}

.button-white {
  background: #fff;
  color: #34a8fe;
}

.button-white:hover,
.button-white:hover svg path {
  background: rgba(52, 168, 254, 0.1);
  fill: #34a8fe;
}

.button-silver {
  background: rgba(255, 255, 255, 0.1);
}
.button-silver:hover {
  background: rgba(255, 255, 255, 0.05);
  color: #fff;
}
.button-silver-text {
  /* background: rgba(255, 255, 255, 0.1); */
  color: rgba(255, 255, 255, 0.5);
}
.button-silver-text:hover {
  background: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 1);
}

.button-blue-outline {
  color: #34a8fe;
  background: none;
  border: 1px solid #34a8fe;
}

.button-submit {
  height: 32px;

  background: #34a8fe;
  color: white;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.svg-button-20 {
  padding: 6px;
}
.svg-button {
  padding: 4px;
}

.svg-text-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 16px;
}
.svg-text-button > * {
  margin-right: 4px;
}

.svg-button:hover,
.badge-button:hover {
  opacity: 0.5;
}

.suppor-button {
  height: 36px;
  padding: 6px 16px;
}

.Join-button {
  height: 40px;
}

.button-profile-skinny {
  min-height: 30px;
  height: 27px;

  border: 1px solid #eceff1;
  box-sizing: border-box;
  /* box-shadow: 0px 4px 12px rgba(17, 51, 83, 0.02); */
  border-radius: 20px;
  color: #2f3d4d;
  font-size: 0.75rem;
  transition: 0.1s ease-in-out;
}

.button-signout-skinny {
  border-radius: 5px;
  margin: 0 auto;
}

.button-signout-skinny:hover,
.button-profile-skinny:hover {
  background: #eceff1;
  opacity: 0.75;
}

.post-botton,
.post-botton-sml {
  width: 100%;
  display: flex;
  justify-content: center;
}

.post-botton .button-text {
  margin-left: 12px;
  font-size: 1rem;
  /* transition: 0.2s ease-in-out; */
  display: block;
}

@media only screen and (max-width: 768px) {
  .post-botton {
    height: 56px;
    width: 152px;
    border-radius: 28px;
    position: fixed;
    right: 24px;
    bottom: 32px;
    display: flex;
    /* z-index: 80; */
    box-shadow: 0px 20px 35px rgba(0, 0, 0, 0.4);
  }

  .post-botton-sml {
    width: 56px;
  }

  .post-botton .button-text {
    display: none;
  }
  .post-botton .button-mobile-adj {
    display: block;
  }
  .suppor-button {
    padding: 6px;
  }
}
