.splash-pop-container {
  margin-bottom: -16px;
}
.splash-pop-wrap {
  padding: 8px 16px;
  width: 220px;
  background: #fff;
  box-shadow: 0px 4px 24px rgba(52, 168, 254, 0.25);
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-weight: 400;
  border-radius: 10px;
}

.anim-up-splash {
  opacity: 0;
  animation: reveal-up-splash 0.75s cubic-bezier(0, 1, 0.1, 1) normal forwards,
    slide-top-splash 1.5s ease-in-out 0.75s infinite alternate both;
}
.hover-splash {
}

@keyframes reveal-up-splash {
  0% {
    opacity: 0;
    transform: translateY(50%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes slide-top-splash {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-4px);
  }
}

.anim-grow-splash {
  opacity: 0;
  animation: grow-splash 0.75s cubic-bezier(0, 1, 0.1, 1) 1 normal forwards,
    grow-top-splash 1.5s ease-in-out 0.75s infinite alternate both;
}

@keyframes grow-splash {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes grow-top-splash {
  0% {
    opacity: 0.8;
    transform: scale(0.8);
  }
  100% {
    opacity: 0.5;
    transform: scale(0.6);
  }
}

.splash-pop-wrap > * {
  width: 100%;
}

.splash-pop-wrap img {
  border-radius: 5px;
  display: block;
  margin: 4px 0;
  width: 100%;
}
.splash-pop-header {
  height: 24px;
  font-size: 0.875rem;
  font-weight: 600;
}
.splash-pop-dots > * {
  height: 12px;
  width: 12px;
  border-radius: 100%;
  border: 1px solid #323a42;
}
.splash-pop-dots :not(:first-child) {
  margin-left: 8px;
}
.splash-pop-dots > * {
  height: 12px;
  width: 12px;
  border-radius: 100%;
  margin-left: 8px;
  border: 1px solid #323a42;
}
.splash-pop-dots .splash-pop-dots-filled {
  background: #323a42;
}
.slash-pop-line {
  width: 50%;
  border-top: 1px solid #cfd8dc;
  margin: 2px 0 2px 50%;
}

.splash-pop-triangle {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 10px solid #fff;
  margin: auto;
}
.splash-pop-shadow {
  width: 100%;
  height: 37px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(86, 83, 83, 0.39) 0%,
    rgba(68, 64, 64, 0) 100%
  );
}
/* ------------------------------------------------------------ */
.map-icon-container {
  display: none;
}

@media only screen and (min-width: 960px) {
  .splash-pop-wrap {
    padding: 16px;
    width: 240px;
    font-size: 0.875rem;
    line-height: 1.5rem;
  }
  .splash-pop-header {
    font-size: 1rem;
  }
  .splash-pop-wrap img {
    margin: 8px 0;
  }
  /* ------------------------------------------------------------ */
  .map-icon-container {
    opacity: 0;
    display: flex;
    position: absolute;
    /* border: 1px solid red; */
  }
  @keyframes map-icon-rev {
    0% {
      opacity: 0;
      transform: translateY(-500px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .map-icon-dots-wrap {
    margin-bottom: 4px;
    /* opacity: 0; */
  }
  .map-icon-dots-wrap > * {
    opacity: 0;
  }
  @keyframes map-icon-dots-rev {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .map-icon-dots-wrap div {
    height: 8px;
    width: 8px;
    border-radius: 100%;
    border: 1px solid #323a42;
  }
  .map-icon-dots-wrap :not(:first-child) {
    margin-left: 2px;
  }
  .map-icon-dots-active {
    background: #323a42;
  }

  .map-icon-container .splash-pop-shadow {
    height: 16px;
    opacity: 0.4;
  }
}
