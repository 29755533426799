.content-section-container {
  position: relative;
}
.content-section-background {
  position: absolute;
  z-index: -1;
  left: 50%;
  width: 100%;
  max-width: 1320px;
  margin: auto;
}
.content-section-background-flipped {
  display: flex;
  justify-content: flex-end;
  /* border: 1px solid red; */
}
.content-section-background svg {
  position: relative;
  left: -50%;
}

.content-section-cont-wrap {
  padding: 4rem 1rem;
  display: inline-flex;
  flex-direction: column;
  /* border: 1px solid red; */
}
.content-section-cont-wrap-rev {
  flex-direction: column-reverse;
}

.content-section-cont-wrap-adg {
  padding: 6rem 0;
}

.content-section-image-wrap {
  margin: 0 auto 2rem;
  position: relative;
  width: 90%;
}
.content-section-image-wrap img {
  width: 80%;

  border-radius: 7px;
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.12),
    0px 5px 10px rgba(0, 0, 0, 0.09);
}

/* .content-section-image-container {
  border: 1px solid red;
  position: relative;
  width: 100%;
  height: 100%;
} */

.content-section-image-wrap-1 {
  /* margin: 0 4rem 0 0 */
}

.content-section-image-wrap-2 {
  margin: 0 auto 13%;
  width: 80%;
  /* border: 1px solid red; */
}

/*.content-section-image-wrap-3 {
  margin: 0 4rem 2rem 0;
} */

/* middel image animation------------------------------------ */
.content-section-image-middle {
  box-shadow: 0 2.74416px 2.74416px rgba(0, 0, 0, 0.0274351),
    0 5.48831px 5.48831px rgba(0, 0, 0, 0.0400741),
    0 13.7208px 10.9766px rgba(0, 0, 0, 0.0499982),
    0 20.5812px 20.5812px rgba(0, 0, 0, 0.0596004),
    0 41.1623px 41.1623px rgba(0, 0, 0, 0.0709366),
    0 96.0454px 89.1851px rgba(0, 0, 0, 0.09);

  position: absolute;
  left: 0;
  transition-delay: 0.05s;
  transform: scale(0.92);

  border: 1px solid #eceef2;
  transition: opacity 0.5s cubic-bezier(0.34, 1.56, 0.64, 1),
    transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
}
.content-section-image-middle-bottom {
  box-shadow: 0 2.74416px 2.74416px rgba(0, 0, 0, 0.0274351),
    0 5.48831px 5.48831px rgba(0, 0, 0, 0.0400741),
    0 13.7208px 10.9766px rgba(0, 0, 0, 0.0499982),
    0 20.5812px 20.5812px rgba(0, 0, 0, 0.0596004),
    0 41.1623px 41.1623px rgba(0, 0, 0, 0.0709366),
    0 96.0454px 89.1851px rgba(0, 0, 0, 0.09);

  transform: translateX(-40px);

  border: 1px solid #eceef2;
  transition: opacity 0.5s cubic-bezier(0.34, 1.56, 0.64, 1),
    transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.content-section-image-dot {
  position: absolute;
  left: calc(2px + 1%);
  max-width: 12%;
}
.content-section-image-dot img {
  box-shadow: 0 2.74416px 2.74416px rgba(0, 0, 0, 0.0274351),
    0 5.48831px 5.48831px rgba(0, 0, 0, 0.0400741),
    0 13.7208px 10.9766px rgba(0, 0, 0, 0.0499982),
    0 20.5812px 20.5812px rgba(0, 0, 0, 0.0596004),
    0 41.1623px 41.1623px rgba(0, 0, 0, 0.0709366),
    0 96.0454px 89.1851px rgba(0, 0, 0, 0.09);
  display: block;
  opacity: 0;
  width: 100%;
  border-radius: 100%;
  overflow: hidden;
  background: none;
  transition: opacity 0.5s cubic-bezier(0.34, 1.56, 0.64, 1),
    transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
  transform: translateY(15px);
}

.content-section-image-dot1 {
  top: 8%;
  animation: float-alt-c 7s ease-in-out infinite;
}

.content-section-image-dot2 {
  top: 31%;
  animation: float-alt-c 7s ease-in-out infinite;
  animation-duration: 8s;
  animation-delay: -2s;
}
.content-section-image-dot2 img {
  transition-delay: 0.15s;
}
.content-section-image-dot3 {
  top: 57%;
  animation: float-alt-c 7s ease-in-out infinite;
  animation-duration: 9s;
  animation-delay: -6s;
}
.content-section-image-dot3 img {
  transition-delay: 0.3s;
}
.content-section-image-dot4 {
  top: 83%;
  animation: float-alt-c 7s ease-in-out infinite;
  animation-duration: 8s;
  animation-delay: -2s;
}
.content-section-image-dot4 img {
  transition-delay: 0.45s;
}

/* ------------------------------------------------------------- */
.content-section-image-pop-wrap {
  position: absolute;
  /* top: 40%; */
  top: 32%;
  right: 10%;
  width: 50%;
}
.content-section-image-pop-wrap > * {
  margin-bottom: 1%;
  animation: float-translate 8s ease-in-out infinite;
  animation-delay: -1s;
  opacity: 0;
  transition: opacity 0.45s cubic-bezier(0.34, 1.56, 0.64, 1),
    transform 0.45s cubic-bezier(0.34, 1.56, 0.64, 1);
  transform: translateY(10px);
}
.content-section-image-pop-wrap > *:first-child {
  transition-delay: 0.15s;
}
.content-section-image-pop-wrap > *:nth-child(2) {
  transition-delay: 0.23s;
}
.content-section-image-pop-wrap > *:nth-child(3) {
  transition-delay: 0.3s;
}
.content-section-image-wrap-phoButton {
  position: absolute;
  top: 17.9%;
  left: 48.9%;
  height: 6%;
  width: 11%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 1.4em; */
  margin: 0;
  border: 1px solid #039be5;
  /* border: 1px solid #9cc3f8; */
  /* padding: 6px 12px; */
  /* background: #E5F1FA; */
  color: #fff;
  font-weight: 500;
  font-size: 0.8rem;
  border-radius: 5px;
  cursor: ;
}
.content-section-image-wrap-phoButton svg {
  height: 54%;
  margin-top: 1%;
}

.content-section-image-pop-wrap-circle {
  top: -8%;
  right: 14%;
  /* width: 48px;
  height: 48px; */
  width: 14.8%;
  height: 19.2%;
  background: #039be5;
  animation: float-alt-c 8s ease-in-out infinite;
  animation-delay: -1s;
  transition-delay: 0.6s;
  position: absolute;
  border-radius: 100%;
  box-shadow: 0 2.74416px 2.74416px rgba(0, 0, 0, 0.0274351),
    0 5.48831px 5.48831px rgba(0, 0, 0, 0.0400741),
    0 13.7208px 10.9766px rgba(0, 0, 0, 0.0499982),
    0 20.5812px 20.5812px rgba(0, 0, 0, 0.0596004),
    0 41.1623px 41.1623px rgba(0, 0, 0, 0.0709366),
    0 96.0454px 89.1851px rgba(0, 0, 0, 0.09);
}
.content-section-image-pop-wrap-circle svg {
  height: 70%;
}

.content-section-anim-active .content-section-image-middle,
.content-section-anim-active .content-section-image-dot img,
.content-section-anim-active .content-section-image-middle-bottom,
.content-section-anim-active .content-section-image-pop-wrap > * {
  transform: scale(1) translateX(0);
  opacity: 1;
}

@keyframes float-alt-c {
  0% {
    /* box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6); */
    transform: translatey(0px) rotate(-8deg);
  }
  50% {
    /* box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2); */
    transform: translatey(-8px) rotate(8deg);
  }

  100% {
    /* box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6); */
    transform: translatey(0px) rotate(-8deg);
  }
}
.content-sec-fade-anim {
  opacity: 0;
  transition: opacity 0.5s cubic-bezier(0.34, 1.56, 0.64, 1),
    transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
  transform: translateY(15px);
}
.content-sec-fade-anim-pop {
  opacity: 0;
  transition: opacity 0.5s cubic-bezier(0.34, 1.56, 0.64, 1) 0.2s,
    transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1) 0.2s;
  transform: translateY(15px);
}
.content-section-text-wrap {
  width: 100%;
  text-align: center;
}
.content-section-text-wrap h2 {
  font-size: 2rem;
  font-weight: 700;
  margin: 0 auto 16px;
}
.content-section-text-wrap p {
  font-size: 0.875rem;
  color: #757575;

  line-height: 1.71429;
  font-weight: 400;
  max-width: 600px;
  margin-bottom: 32px;
}
.content-section-text-wrap a {
  padding: 10.5px 16px;
  box-sizing: border-box;
}

.content-section-anim-active .content-sec-fade-anim,
.content-section-anim-active .content-sec-fade-anim-pop {
  opacity: 1;
  transform: translateY(0);
}

@media only screen and (min-width: 768px) {
  .content-section-cont-wrap {
    padding: 2rem 0;
    flex-direction: row;
  }
  .content-section-image-wrap {
    width: 50%;
    margin: 0 32px 0 0;
  }
  .content-section-text-wrap {
    width: 50%;
    text-align: left;
  }
  .content-section-image-wrap-2 {
    margin: 0 0 0 2rem;
  }
}
@media only screen and (min-width: 960px) {
  .content-section-text-wrap h2 {
    font-size: 2.75rem;
  }
  .content-section-text-wrap p {
    font-size: 1rem;
  }
  .content-section-image-wrap-2 {
    margin: 0 0 0 4rem;
  }

  .content-section-image-wrap-3 {
    margin: 0 4rem 0 0;
    /* height: 120%; */
  }
}
@media only screen and (min-width: 1200px) {
  .content-section-image-wrap {
    width: 60%;
  }
  .content-section-image-wrap-2 {
    width: 50%;
  }
  .content-section-text-wrap {
    width: 40%;
  }
}
