.banner-tabs-left {
  position: relative;
  height: 48px;
  display: flex;
  align-items: flex-start;
  width: Calc(100% + 16px);
  margin-left: -16px;

  overflow: hidden;
}

.banner-tabs-wrap {
  overflow-x: auto;
}

.banner-tab {
  height: 48px;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1rem;
  color: rgba(255, 255, 255, 0.6);
  padding: 8px 16px;
  box-sizing: border-box;
  transition: 0.15s ease-out;
}
.banner-tabs a:hover {
  color: rgba(255, 255, 255, 1);
}
.active-banner-tab {
  color: #fff;
}

.tab-seleciton-lin {
  position: absolute;
  bottom: 0;
  height: 3px;
  border-radius: 3px 3px 0 0;
  background: #fff;

  margin: 0 16px;
  width: 20px;
  transition: 0.2s ease-in-out;
}

/* @media only screen and (min-width: 768px) {
  .banner-tabs-left {
    margin-left: -16px;
  }
} */
