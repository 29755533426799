.nav-wrap {
  display: flex;
  align-items: center;
  /* padding: 8px 0; */
  height: 48px;
  box-sizing: border-box;
  /* padding: 0 16px; */
  /* position: relative; */
}

.nav-wrap a {
  font-weight: 400;
  font-size: 0.875rem;
  transition: 0.15s ease-in-out;
}
.nav-wrap a:hover {
  opacity: 0.7;
}

.nav-wrap-left a {
  color: #323a42;
  /* color: #757575; */
}
.nav-menu {
  padding: 8px;
  /* margin-right: 12px; */
  margin: 0 12px 0 8px;
}

.pho-link {
  background: none;
  border: none;

  padding: 6px 18px;

  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.nav-wrap-right a,
.pho-link p {
  color: #34a8fe;
  font-weight: 500;
  margin: 0;
}

.nav-spacer {
  width: 0px;
  height: 32px;
  box-sizing: border-box;
  border: 0.5px solid #eceff1;
  /* border: 0.5px solid #8093A2; */
}
.nav-Logo {
  width: auto;
  height: 22px;
  margin-left: -18px;
}

.nav-wrap a {
  height: 20px;
  padding: 6px 18px;
  font-size: 0.875rem;
  text-decoration: none;
  cursor: pointer;
}

.profile-spinner-wrap {
  width: 32px;
  height: 32px;
  margin: 0 12px 0 0;
  box-sizing: content-box;
  border: 4px solid transparent;
}
.profile-nav-button {
  background: none;
  border: none;
  outline: none;
  width: 32px;
  height: 32px;
  overflow: hidden;
  border-radius: 100%;
  cursor: pointer;
  padding: 0;
  box-sizing: content-box;
  margin: 0 12px 0 0;
  transition: 0.07s ease-in;
  border: 4px solid transparent;
}
.profile-nav-button:hover {
  border: 4px solid #e8eaed;
}
.profile-nav-button img {
  width: 32px;
  height: 32px;
  object-fit: cover;
}

@media only screen and (min-width: 768px) {
  .nav-wrap {
    padding: 0;
  }
  .profile-nav-button {
    margin: 0 0 0 8px;
  }
}
