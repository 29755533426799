.pricing-free-card {
  margin-bottom: 24px;
  border-radius: 5px;
  background: #fff;
  text-align: center;
  padding: 32px 16px;
  position: relative;
  overflow: hidden;
}
.pricing-beta-card {
  display: flex;
  flex-direction: column-reverse;
}
.pricing-free-card h3,
.pricing-free-card p,
.pricing-free-card button {
  position: relative;
  z-index: 6;
}
.pricing-free-card h3 {
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 36px;
  margin-bottom: 16px;
}
.pricing-free-card p {
  font-size: 0.75rem;
  line-height: 18px;
  color: #757575;
  margin: 0 auto 24px;
  max-width: Calc(100% - 32px);
}
.pricing-free-card a {
  color: rgb(52, 168, 254);
}
/* .betacard-svg-container {
  height: 80px;
  width: 80px;
  border: 1px solid red;
} */
.pricing-free-card svg {
  display: block;
  margin: 0 auto 16px;
  width: 60%;
  /* max-height: 10%; */
}

@media only screen and (min-width: 768px) {
  .pricing-free-card {
    margin-bottom: 48px;
  }

  .pricing-free-card,
  .pricing-beta-card {
    text-align: left;
  }
  .pricing-free-card {
    padding: 24px;
  }
  .pricing-free-card h3 {
    font-size: 2rem;
    line-height: 46px;
    margin-bottom: 8px;
  }
  .pricing-free-card p {
    font-size: 1rem;
    line-height: 28px;
    margin: 0 0 24px;
    max-width: 350px;
  }
  .pricing-free-card svg {
    height: auto;
    width: 42%;
    right: -7%;
    top: 7%;
    display: block;
    position: absolute;
    z-index: 4;
  }
}

@media only screen and (min-width: 960px) {
  .pricing-free-card {
    padding: 36px;
  }
  .pricing-free-card p {
    max-width: 500px;
  }
  .pricing-free-card svg {
    width: 40%;
    right: -7%;
    top: -8%;
  }
}
@media only screen and (min-width: 1200px) {
  .pricing-free-card {
    padding: 72px;
  }
  .pricing-free-card p {
    max-width: 600px;
  }
  .pricing-free-card svg {
    width: 40%;
    right: -5%;
    top: -25%;
  }
}
