.allpage-header {
  margin: auto;
  padding: 32px 0;
  text-align: center;
  /* min-width: 312px; */
  box-sizing: border-box;
  align-items: flex-start;
}
.allpage-header-image {
  flex-direction: column;
  align-items: center;
}

.allpage-heater-partition-rit,
.allpage-heater-partition-lft {
  width: 80%;
}

.allpage-heater-image {
  padding-top: 32px;
}

.allpage-header h5 {
  font-size: 0.875rem;
  line-height: 1.75rem;
  margin: 0;
  /* font-weight: 600; */
  /* color: #939eab; */
  /* color: #757575; */
  /* color: #b5c0c5 */
  color: #34a8fe;
}
.allpage-header h1 {
  /* 28px */
  font-size: 1.75rem;
  line-height: 2rem;
  margin: 8px 0;
  font-weight: 600;
  color: #fff;
}

@media only screen and (min-width: 768px) {
  .allpage-header {
    margin: 0;
    padding: 56px 24px 64px;
    text-align: left;
    /* max-width: 50% */
  }
  .allpage-header-image {
    flex-direction: row;
    align-items: flex-start;
  }
  .allpage-heater-partition-rit {
    width: 40%;
  }
  .allpage-heater-partition-lft {
    width: 60%;
  }

  .allpage-header h5 {
    /* font-size: 1.5rem;
    line-height: 1.75rem;
    margin: 8px 0;
    font-weight: 400; */
    font-size: 1.375rem;
    line-height: 1.75rem;
    margin: 8px 0;
    font-weight: 400;
  }
  .allpage-header h1 {
    font-size: 1.75rem;
    line-height: 2rem;
    margin: 0 0 24px;
  }
}

@media only screen and (min-width: 960px) {
  .allpage-header {
    padding: 56px 40px 64px;
  }
  /* .allpage-header h1 {
    font-size: 3rem;
    line-height: 3.5rem;
    font-weight: 600;
  } */
  .allpage-header h1 {
    font-size: 2.75rem;
    line-height: 3.375rem;
    font-weight: 600;
  }
}

@media only screen and (min-width: 1200px) {
  .allpage-header {
    padding: 56px 72px 56px;
  }
}
