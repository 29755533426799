.navbar-alert-cont {
  width: 100%;
  height: 0;
  overflow: hidden;
  background: #34a8fe;
  color: #fff;
  transition: 0.2s ease-in-out;
}
.navbar-alert-cont p {
  margin: 0;
}

.active-nav-alert {
  height: 24px;
}

.nav-alert-close-button {
  cursor: pointer;
  transition: 0.15s ease;
  padding: 0 8px;
  /* margin-right: -8px; */
}
.nav-alert-close-button:hover {
  opacity: 0.5;
}

.Beta-link-wrap {
  padding-right: 24px;
  cursor: pointer;
}

@media only screen and (max-width: 370px) {
  .Beta-link-wrap > *:last-child {
    display: none;
  }
}
