.searchbar-wrap {
  width: 32px;

  display: flex;
  align-items: center;
}

.serachbar-icon-wrap {
  position: relative;
  height: 24px;
  width: 0;
  opacity: 1;
  transition: 0.5s ease-in-out;
  pointer-events: none;
}
.serachbar-icon-wrap svg {
  height: 24px;
  width: 24px;
  position: absolute;
  right: -28px;
  pointer-events: none;
}

.searchbar-wrap input {
  width: 32px;
  margin: 0;
  box-sizing: border-box;
  height: 32px;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 400;
  z-index: 85;

  color: #fff;
  outline: none;
  border: none;
  transition: 0.2s ease-in-out;
  background: none;
  padding-left: 20px;
}
.searchbar-wrap input:hover {
  background: rgba(255, 255, 255, 0.1);
}

.searchbar-wrap input::placeholder {
  font-size: 0.875rem;
  font-weight: 500;
  color: #9ea3a7;
}

.searchbar-wrap input:focus {
  position: absolute;
  width: Calc(100% - 32px);
  right: 16px;
  top: 8px;
  height: 32px;
  border: none;
  outline: none;
  color: #323a42;
  background: #fff;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.6),
    0 1px 3px 1px rgba(60, 64, 67, 0.3);
  padding: 0 16px;
}

.searchbar-wrap input:focus ~ .clear-icon-wrap {
  display: flex;
}
.searchbar-wrap input:focus ! .serachbar-icon-wrap {
  opacity: 0;
}

.clear-icon-wrap {
  z-index: 88;
  position: absolute;
  right: 16px;
  border-radius: 5px;
  padding: 4px 8px;
  display: none;
  cursor: pointer;
}
.clear-icon-svg {
  fill: #9ea3a7;
  transition: 0.15s ease-in-out;
}
.clear-icon-wrap:hover .clear-icon-svg {
  fill: #28323c;
}

@media only screen and (min-width: 768px) {
  .searchbar-wrap {
    width: 100%;
  }
  .serachbar-icon-wrap svg {
    right: -32px;
  }

  .searchbar-wrap input {
    width: 100%;
    padding-left: 40px;
    cursor: text;
    background: rgba(255, 255, 255, 0.1);
  }

  .searchbar-wrap input:focus {
    width: 100%;
    right: 0;
  }

  .clear-icon-wrap {
    right: 0;
  }
}
