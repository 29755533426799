.forum-content {
  min-height: 100%;
  position: relative;
  overflow: hidden;
}

.post-grid-container {
  display: grid;
  grid-template-columns: 1fr;
  padding: 16px 8px 80px;
  margin: 0 auto;
  box-sizing: border-box;
  width: 100%;
  min-height: 100%;
}

/* //------------------------------------------------------------------------ */
.post-sidebar-container {
  display: none;
  /* animation: slidein-trending 0.75s cubic-bezier(0.25, 1, 0.5, 1) normal
    forwards;
  opacity: 0; */
}
.post-sidebar-wrap {
  animation: slidein-trending 0.75s cubic-bezier(0.25, 1, 0.5, 1) normal
    forwards;
  opacity: 0;
}

@keyframes slidein-trending {
  0% {
    opacity: 0;
    transform: translateX(50%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/* //------------------------------------------------------------------------ */
.post-wrapper {
  height: 100%;
  position: relative;
  box-sizing: border-box;
  background: #fff;
  /* width: Calc(100% - 16px); */
  max-width: 100%;

  padding: 32px 16px;
  border-radius: 10px;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
}

.post-back {
  position: fixed;
  top: 0;
  width: 100%;
  /* height: 500px; */
  height: 33vh;
  background: #f4f6f8;
}

.post-page {
  max-width: 50em;
  margin: auto;
}

.post-page h1 {
  font-size: 1.375rem;
  margin: 20px 0 8px;
}

.post-page h2 {
  font-size: 1.25rem;
  font-weight: 500;
  margin: 0 0 2rem;
}
.post-page h3 {
  margin: 0;
  font-size: 0.75rem;
  line-height: 1.5rem;
  color: #b1b4c7;
  font-style: italic;
}

.post-page h6 {
  margin: 0;
  font-size: 0.75rem;
  color: #b1b4c7;
  font-weight: 400;
}
.post-page p {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 300;
}
.post-page img {
  border-radius: 5px;
  width: 100%;
}
.post-page a {
  color: #039be5;
}
.post-page-post-sub {
  margin: 2px 0 16px;
}
.post-page-post-sub > *:not(:first-child) {
  margin: 0 0 0 8px;
}

.post-page-more-section {
  margin-top: 24px;
}

.post-page-author {
  color: #34a8fe;
}
.post-page-author img {
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 5px;
  overflow: hidden;
  margin-right: 8px;
  display: block;
}

.post-location-tag {
  font-size: 0.875rem;
  color: #b1b4c7;
  font-weight: 400;
}
.post-location-tag > *:first-child {
  margin-right: 8px;
}

/* COMMENT CREATE-------------------------------------------------------- */
.post-page-comment-sectoin {
  max-width: 100%;
}
.post-page-comments-sectoin-coments {
  max-width: 100%;
  /* border: 1px solid red; */
}

/* COMMENTS----------------------------------------------------------------- */
.post-page-comment-wrap > *:first-child {
  display: inline-flex;
  width: 100%;
}
.comment-vote-wrap {
  text-align: center;
  align-self: flex-start;
}
.post-page-comment-wrap .comment-vote-wrap {
  margin: 0;
  font-weight: 400;
  text-align: center;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: #b1b4c7;
}

.commment-buttons-wrap {
  display: inline-flex;
  align-items: center;
}
/* .commment-buttons-wrap > *:not(:first-child) {
  margin-left: 12px;
} */

.post-page-header button,
.comment-vote-wrap button {
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 6px;
  margin: -6px;
  border-radius: 5px;
  box-sizing: border-box;
  transition: 0.1s ease;
}
.comment-vote-wrap button:hover,
.comment-vote-wrap button:hover svg path {
  /* background: rgba(52, 168, 254, 0.1); */
  fill: #34a8fe;
}

.comment-cont-wrap {
  width: 100%;
  padding-left: 16px;
  display: flex;
  flex-direction: column;
}
.comment-cont-wrap > *:first-child {
  height: 100%;
}

.comment-user-profile-wrap {
  color: #34a8fe;
  font-weight: 400;
}

.comment-user-profile-wrap img:hover {
  background: rgba(52, 168, 254, 0.1);
}

.comment-user-profile-wrap img {
  height: 24px;
  width: 24px;
  object-fit: cover;
  border-radius: 5px;
  overflow: hidden;
  margin: 0 8px 0 0;
  display: block;
}

.comment-comment-wrap {
  margin: 12px 0 12px 12px;
  border-left: 1px solid #eceff1;
  padding-left: 12px;
  font-weight: 300;
}

@media only screen and (min-width: 768px) {
  .post-grid-container {
    padding: 24px 0;
  }
  .post-wrapper {
    width: 100%;
    padding: 48px 32px;
  }
  .post-page h1 {
    font-size: 2rem;
    margin: 24px 0 8px;
  }
  .post-page h3 {
    margin: 0;
    font-size: 0.75rem;
    color: #b1b4c7;
    font-style: italic;
  }

  .post-page h6 {
    margin: 0;
    font-size: 0.875rem;
    color: #b1b4c7;
    font-weight: 400;
  }
  .post-page p {
    font-size: 1rem;
    line-height: 1.75rem;
    font-weight: 300;
  }
  .post-page-post-sub {
    margin: 0 0 24px;
  }
}

@media only screen and (min-width: 960px) {
  .post-grid-container {
    margin: 24px 0 80px;
    padding: 0;
    grid-template-columns: 1fr 256px;
    grid-gap: 24px;
  }
  .post-grid-container {
  }

  /* POST--------------------------------------------------------- */
  .post-wrapper {
    grid-column: 1;
  }
  /* MORE--------------------------------------------------------- */
  .post-sidebar-container {
    display: block;
    grid-column: 2;
    flex-direction: column;
    position: fixed;
    width: 256px;
    z-index: 99;
  }
  .post-sidebar-container p {
    margin: 0;
    color: #b1b4c7;
    font-size: 0.75rem;
  }

  .post-sidebar-wrap {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    flex-direction: column;
    margin-bottom: 16px;
  }
  .post-sidebar-wrap > *:nth-child(2) {
    margin-top: 16px;
  }
  .post-sidebar-wrap > *:nth-last-child(2) {
    margin-bottom: 12px;
  }
  .post-sidebar-wrap h4 {
    width: 100%;
    font-size: 0.875rem;
    font-weight: 600;
    /* background: rgba(148, 156, 166, 0.1); */

    border-bottom: 1px solid #eceff1;
    box-sizing: border-box;
    padding: 10px 16px;
  }
  /* TRENDINGPOSTS----------------------------------- */
  .post-trending-wrap,
  .trendig-group-cont {
    margin: 8px 16px;
    transition: 0.1s ease-in-out;
    border-radius: 5px;
    cursor: pointer;
    color: #28323c;
  }
  .post-trending-wrap:hover,
  .trendig-group-cont:hover {
    /* background: #f9fafb; */
    color: #34a8fe;
  }

  .post-sidebar-wrap h3 {
    font-size: 0.875rem;
    font-weight: 500;
  }
  .more-container .more-container-wrap p {
    margin: 0;
    color: #b1b4c7;
    font-size: 0.75rem;
  }
  .more-container-wrap img {
    object-fit: cover;
  }
  .post-trending-wrap img {
    width: 54px;
    min-width: 54px;
    height: 54px;
    border-radius: 5px;
    margin-left: 16px;
    object-fit: cover;
  }
  .trending-post-wrap {
    border-top: 1px solid #eceff1;
    padding: 9px 16px;
    font-size: 0.75rem;
    color: #b1b4c7;
    transition: 0.1s ease;
    /* background: #f9fafb; */
    /* border-radius: 0 0 5px 5px; */
  }
  .trending-post-wrap:hover {
    color: #34a8fe;
  }
}
