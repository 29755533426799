.side-nav-back {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 60;
  background: rgba(0, 0, 0, 0);
  transition: 0.15s ease;
  pointer-events: none;
}

.side-nav {
  position: relative;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  width: 0;
  left: 0;
  height: 100vh;
  background: #fff;
  transition: 0.25s cubic-bezier(0, 1, 0.1, 1);
}
.active-sideNav-back {
  pointer-events: auto;
  background: rgba(0, 0, 0, 0.5);
}

.active-sideNav {
  width: 268px;
  box-shadow: 0px 20px 35px rgba(33, 56, 69, 0.2);
}

.nav-wrap .side-nav a {
  width: 100%;
  height: 48px;
  padding: 12px 24px;
  box-sizing: border-box;
  font-weight: 700;
  border-top: 0.5px solid #e8eaed;
}

.nav-wrap .side-nav a:hover {
  background: #fafbfc;
}

.sidenav-subnav {
  display: inherit;
  flex-direction: column;
  padding: 0 0 12px;
}

.nav-wrap .side-nav .sidenav-subnav a {
  padding: 8px 24px 8px 40px;
  height: 32px;
  font-weight: 400;
  border-top: none;
}

.sidenav-header {
  display: inherit;
  flex-direction: row;
  align-items: center;
  height: 48px;
}

.sidenav-header .nav-Logo {
  margin-left: 0;
}

.sidenav-selected {
  color: #34a8fe;
}

.sidenav-legal {
  color: #919498;
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #fff;
  box-sizing: border-box;
  padding: 8px 16px;
  margin: 0;
  font-weight: 300;
  white-space: nowrap;
  font-size: 0.75rem;
}

/* @keyframes reveal-nav {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
} */
