.global-loading-spinner {
  margin: auto;
  box-sizing: border-box;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-name: rotation;
  animation-timing-function: linear;
  border-radius: 50%;
  /* border: 5px solid #e8eaed;
  border-right-color: transparent; */
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
