.textiput-container {
  border-radius: 5px;
  border: 1px solid #cfd8dc;
  padding: 12.5px 12.5px 20px;
  /* min-height: 200px; */
  position: relative;
  box-sizing: border-box;
  z-index: 5;
  width: 100%;
}

/* IMPUT AREA----------------------------------- */

.DraftEditor-root {
  /* min-height: 200px; */
  height: 100%;
  width: 100%;
  overflow-y: auto;
  font-size: 0.875rem;
  font-family: inherit;
  cursor: text;
  /* background: #fff; */
}
.DraftEditor-editorContainer {
  height: 100%;
}

.public-DraftEditorPlaceholder-inner {
  position: absolute;
  pointer-events: none;
  font-size: 0.875rem;
  font-weight: 400;
  color: #b5c0c5;
}
.public-DraftStyleDefault-block {
  margin-bottom: 1em;
}

.textiput-toolbar button,
.textiput-toolbar .toolbar-div-cont {
  width: 40px;
  height: 40px;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  border-radius: 5px;
}

.textiput-toolbar {
  height: 40px;
  overflow: hidden;
  align-items: flex-start;
  position: relative;
}
.toolbar-tools {
  overflow-x: auto;
  white-space: nowrap;
}
/* .toolbar-charcnt {
  position: absolute;
  width: 24px;
  height: 40px;
  top: 0;
  right: 40px;
  background: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
} */

.toolbar-divider {
  /* border-right: 2px solid #f4f6f8; */

  border-right: 1px solid #cfd8dc;
  height: 24px;
}
.textiput-toolbar {
  margin: -12px 0 8px -12px;
  width: Calc(100% + 24px);
  max-width: Calc(100% + 24px);
  border: 1px solid red;
}
.textiput-toolbar button {
  outline: none;
  background: #fff;
  transition: 0.1s ease;
  border: none;
  cursor: pointer;
}
.textiput-toolbar button svg {
  pointer-events: none;
}
.textiput-toolbar button:hover,
.textiput-toolbar button:hover svg path {
  fill: #039be5;
  background: rgba(52, 168, 254, 0.1);
  opacity: 1;
}
.active-toolbar-button svg path {
  fill: #039be5;
}

.input-dragbar {
  width: 100%;
  cursor: n-resize;
  padding: 4px;
}
.input-dragbar svg path {
  transition: 0.15s ease;
  fill: #cfd8dc;
}
.input-dragbar:hover svg path {
  fill: #039be5;
}

/* STYLING--------------------------------- */
.textiput-container h2 {
  font-size: 1.25rem;
  font-weight: 500;
  margin: 0 0 2rem;
}
.textiput-container h3 {
  margin: 0;
  font-size: 0.75rem;
  color: #b1b4c7;
  font-weight: 400;
  font-style: italic;
}

.textiput-container ul,
.textiput-container ol {
  box-sizing: border-box;
  margin: 0;
  padding: 15px 0 15px 40px;
}

.input-editor-wra {
  position: relative;
}

.input-editor-wrap img {
  width: 100%;
  max-width: 200px;
  border-radius: 5px;
}

.input-editor-wrap a {
  color: #039be5;
}

/* MENU--------------------------------------------- */
.active-toolbar-text {
  color: #039be5;
}
.toolbar-menu-wrap {
  position: relative;
}
.toolbar-menu-active {
  height: 40px;
  width: 60px;
  font-size: 0.875rem;
  color: #cfd8dc;
  padding: 0 6px 0 12px;
  border-radius: 5px;
  transition: 0.1s ease;
  cursor: pointer;
  text-transform: capitalize;
}
.toolbar-menu-active:hover,
.toolbar-menu-active:hover svg path {
  color: #039be5;
  fill: #039be5;
  background: rgba(52, 168, 254, 0.1);
}
.toolbar-menu-active > * div {
  margin-left: 8px;
}

.toolbar-menu-active svg path {
  fill: #cfd8dc;
}

.toolbar-menu-drop {
  z-index: 12;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  word-wrap: 100%;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.12), 0px 3px 6px rgba(0, 0, 0, 0.09);
}
.toolbar-menu-drop > *:first-child {
  margin-top: 8px;
}
.toolbar-menu-drop > *:last-child {
  margin-bottom: 8px;
}
.toolbar-menu-drop button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: none;
  outline: none;
  border: none;
  padding: 4px 12px;
  cursor: pointer;
  color: #cfd8dc;
  font-size: 0.875rem;
  width: 100%;
}

.toolbar-menu-drop button:hover,
.toolbar-menu-drop button:hover svg path {
  color: #039be5;
  fill: #039be5;
  background: rgba(52, 168, 254, 0.1);
}
.toolbar-menu-drop button svg {
  margin-right: 8px;
}
@media only screen and (min-width: 768px) {
  .toolbar-menu-active {
    width: 155px;
  }
  .toolbar-menu-drop {
    width: 155px;
  }
}

/* LINK PRIEVIEW----------------------------- */
.preview-container {
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 8px;
  margin: 24px 0 -8px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #cfd8dc;
  cursor: pointer;
  transition: 0.15s ease-in;
}

.preview-container:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.1);
}

.text-container .preview-container img {
  margin: 0 0 8px 0;
  width: 90px;
  height: 60px;
  border-radius: 5px;
  overflow: hidden;
  background: #f4f6f8;
  object-fit: cover;
}

.content-pop-content .text-container .preview-container h4 {
  font-size: 0.875rem;
}

.text-container .preview-container p {
  margin: 0;
  font-size: 0.75rem;
  color: #757575;
}

.text-container .preview-container button {
  position: absolute;
  top: 0;
  right: 0;
  margin: 8px;
}
.preview-container button svg path {
  transition: 0.15s ease-out;
}
.preview-container button:hover,
.preview-container button:hover svg path {
  fill: #039be5;
  background: rgba(52, 168, 254, 0.1);
  opacity: 1;
}

@media only screen and (min-width: 768px) {
  .preview-container {
    flex-direction: row;
    align-items: center;
  }
  .content-pop-content .text-container .preview-container h4 {
    font-size: 1rem;
  }
  .content-pop-content .text-container .preview-container p {
    margin: 0;
    font-size: 0.875rem;
    color: #757575;
  }
  .text-container .preview-container img {
    margin: 0 16px 0 0;
    width: 180px;
    height: 120px;
  }
}

/* IMAGEINPUT================================ */
.image-input-wrap {
  position: fixed;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 5px;
  background: rgba(50, 58, 66, 0.1);
  z-index: 100;
}
.image-input-crd {
  position: fixed;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  flex-direction: column;
  top: 48px;
  padding: 16px;
  margin: 16px 0;
  box-sizing: border-box;
  width: Calc(100% - 32px);
  height: 267px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.12),
    0px 5px 10px rgba(0, 0, 0, 0.09);
}

.image-input-crd-hed {
  border-radius: 5px;
  border: 1px solid #cfd8dc;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 5px;
  width: 100%;
}
.image-input-wrap .image-input-crd .image-input-crd-hed svg {
  position: relative;
  top: 0;
  left: 0;
  min-width: 24px;
}
.image-input-wrap .image-input-crd .image-input-crd-hed svg:last-of-type {
  padding: 4px;
  border-radius: 5px;
  background: #fff;
  cursor: pointer;
  transition: 0.1s ease;
}
.image-input-wrap .image-input-crd .image-input-crd-hed svg:last-of-type:hover,
.image-input-wrap
  .image-input-crd
  .image-input-crd-hed
  svg:last-of-type:hover
  path {
  background: rgba(52, 168, 254, 0.1);
  fill: #039be5;
}
.image-input-wrap .image-input-crd .image-input-crd-hed svg path {
  fill: #cfd8dc;
}

.image-input-wrap
  .image-input-crd
  .image-input-crd-hed
  .autocomplete-dropdown-container {
  position: absolute;
  width: Calc(100% - 32px);
  left: 16px;
  background: #fff;
}

.image-input-wrap .image-input-crd input {
  background: none;
  outline: none;
  border: none;
  height: 40px;
  box-sizing: border-box;
  width: 100%;
  padding: 0 8px;
}
.image-input-wrap .image-input-crdinput:hover .image-input-crd-hed {
  border: 1px solid #039be5;
}
.image-pop-back {
  text-align: center;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: -1;
  font-size: 0.75rem;
  font-weight: 400;
  color: #bcc2c7;
  box-sizing: border-box;
  border: 2px dashed #cfd8dc;
  margin-top: 8px;
  border-radius: 5px;
  transition: 0.15s ease-in-out;
}
.image-pop-back-active {
  background: rgba(52, 168, 254, 0.1);
  border: 2px dashed #039be5;
}
.image-pop-back svg:first-of-type {
  position: absolute;
  bottom: 0;
  left: 0;
}
.image-pop-back svg:last-of-type {
  position: absolute;
  bottom: 0;
  right: 0;
}

.image-pop-back-text {
  max-width: 230px;
  font-size: 1.25rem;
  font-weight: 600;
  color: #323a42;
  margin-bottom: 8px;
}
.image-pop-back-text span {
  cursor: pointer;
}

.image-input-wrap .image-laoding-wrap {
  width: auto;
  height: auto;
  /* margin: auto; */
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.image-laoding-wrap img {
  max-width: 120px;
  max-height: 80px;
  border-radius: 5px;
  margin-right: 16px;
}
.image-input-upload-txt {
  font-size: 1.25rem;
  font-weight: 600;
  color: #323a42;
  margin: 0;
}
.image-laoding-wrap .progress {
  width: 180px;
  height: 4px;
  box-sizing: border-box;
  border-radius: 5px;
  background: #f9fafb;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 12px;
}
.image-laoding-wrap .progress-value {
  animation: loader 8s ease-in-out forwards;
  height: 100%;
  background: #039be5;
}
.image-imput-error-msg {
  color: #ff4e71;
  width: 100%;
  text-align: center;
  font-size: 0.75rem;
  height: 32px;
  display: flex;
  align-items: flex-end;
}

@keyframes loader {
  0% {
    width: 0;
  }

  20% {
    width: 10%;
  }

  25% {
    width: 24%;
  }

  43% {
    width: 41%;
  }

  56% {
    width: 50%;
  }

  66% {
    width: 52%;
  }

  71% {
    width: 60%;
  }

  75% {
    width: 76%;
  }

  100% {
    width: 86%;
  }
}

/* LINK-------------------------------------------------------------- */
.toolbar-link-input {
  z-index: 12;
  overflow: hidden;
  position: absolute;
  top: 40px;
  right: 0;
  width: auto;
  border-radius: 5px;
  padding: 8px;
  word-wrap: 100%;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.12), 0px 3px 6px rgba(0, 0, 0, 0.09);
  width: 230px;
  overflow: hidden;
}
.toolbar-link-input form {
  position: relative;
}
.toolbar-link-input input {
  height: 36px;
  border: none;
  outline: none;
  width: 100%;
}

.toolbar-link-input svg {
  padding: 4px;
  border-radius: 5px;
  margin-right: 8px;
  cursor: pointer;
}
.toolbar-link-input svg path {
  fill: #cfd8dc;
}
.toolbar-link-input svg:hover,
.toolbar-link-input svg:hover path {
  background: rgba(52, 168, 254, 0.1);
  fill: #039be5;
}
/* .toolbar-link-input button {
  position: absolute;
  right: 0;
} */

@media only screen and (min-width: 768px) {
  /* ------------------------------------------------------------- */

  .image-input-crd {
    position: relative;
    width: 400px;
  }
  .image-input-wrap .image-laoding-wrap {
    height: 112px;
  }
}
