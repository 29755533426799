.card {
  padding: 16px;
  text-align: center;
  background: #ffffff;
  border-radius: 10px;
  margin-bottom: 12px;
  width: 100%;
  box-sizing: border-box;
}

/* .card :hover {
  box-shadow: 0px 2px 4px rgba(60, 64, 67, 0.3),
    0px 0px 4px rgba(60, 64, 67, 0.3);
} */

.card-shdow-sml {
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
}
.card-shdow-lg {
  box-shadow: 0px 20px 35px rgba(33, 56, 69, 0.2);
}

.forum-content-card {
  transition: 0.1s ease-in-out;
}
.forum-content-card:hover {
  box-shadow: 0px 6px 8px rgba(33, 56, 69, 0.2);
  /* background: rgba(255, 255, 255, 0.2); */
  /* transform: scale(1.005); */
}

.post-grid {
  text-align: left;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas:
    "cat cat"
    "tit tit"
    "img img"
    "cont cont"
    "user vote";
}
.post-grid p {
  margin-bottom: 0;
}
.c-cat {
  grid-area: cat;
  height: 24px;
  color: #939eab;
  text-transform: uppercase;
}

.forum-best-answer-badge {
  font-size: 0.75rem;
  text-transform: capitalize;
  font-weight: 500;
  color: #fff;
  border-radius: 13px;
  background: #02bfa6;
  /* height: 24px; */
  padding: 4px;
  margin-right: 8px;
}
.forum-best-answer-badge div {
  display: none;
}

.c-tit {
  grid-area: tit;
  min-height: 32px;
  padding: 4px 0;
  box-sizing: border-box;
}
.c-cont {
  grid-area: cont;
  margin-bottom: 16px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.c-cont p {
  color: #757575;
}

.c-user {
  grid-area: user;
  height: 24px;
  max-width: 100%;
  overflow: hidden;
  color: #939eab;
}

.c-user p,
.c-user svg {
  margin-left: 8px;
}

.c-user img {
  display: none;
  height: 24px;
  width: 24px;
  object-fit: cover;
  border-radius: 5px;
  margin: 0;
}

.c-vote {
  grid-area: vote;
  display: flex;
  align-items: center;
  text-align: center;
  max-width: 90px;
}

.post-img-wrap {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  overflow: hidden;
  border-radius: 5px;
  margin-bottom: 16px;
}
.post-img-wrap img {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: auto;
}

@media only screen and (min-width: 410px) {
  .c-user img {
    display: block;
  }
  .forum-best-answer-badge {
    padding: 4px 12px 4px 8px;
  }
  .forum-best-answer-badge svg {
    margin-right: 8px;
  }
  .forum-best-answer-badge div {
    display: block;
  }
}

@media only screen and (min-width: 960px) {
  .post-grid {
    padding: 24px;
    grid-template-columns: 24px auto 1fr;
    grid-template-areas:
      "vote img cat"
      "vote img tit"
      "vote img cont"
      "vote img user";
  }
  .c-cat,
  .c-tit,
  .c-img,
  .c-cont,
  .c-user {
    margin-left: 24px;
  }
  .c-cont {
    flex-direction: row;
  }
  .post-img-wrap {
    padding-top: 0;
    width: 180px;
    min-width: 180px;
    height: 120px;
    margin: 0 16px 0 0;
  }

  .c-vote {
    justify-content: flex-start;
    flex-direction: column;
  }
}

/* Desktop */
@media only screen and (min-width: 1200px) {
  .post-img-wrap {
    width: 108px;
    min-width: 108px;
    height: 72px;
    margin: 0 16px 0 0;
  }
}
