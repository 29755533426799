.header-banner-container {
  z-index: 98;
  background: #28323c;
}

.banner-tabs {
  height: 48px;
  position: relative;
  padding: 0 16px;
}

.sticky-section {
}

@media only screen and (min-width: 768px) {
  .banner-tabs {
    padding: 0;
  }
  .banenr-searchbar-container {
    width: 180px;
    margin-left: 8px;
  }
}

@media only screen and (min-width: 960px) {
  .banenr-searchbar-container {
    width: 256px;
  }
}
