.prodbox-container {
  text-align: left;
  list-style-type: none;
  position: relative;
  padding-bottom: 44px;
}

.prodbox-container .product-page-anchor {
  position: relative;
  top: -100px;
}

.prodbox-icon-wrap {
  height: 60px;
  width: 60px;
  border: 8px solid #f6fcfe;
  border-radius: 100%;
  margin-bottom: 8px;
  background: #34a8fe;
}

.prodbox-container h2 {
  height: 24px;
  font-size: 1rem;
  font-weight: 500;
  padding: 4px 0 12px;
}
.prodbox-plans-wrap {
  height: 24px;
  margin-bottom: 16px;
  color: #b5c0c5;
  font-weight: 400;
}
.prodbox-blan-box {
  font-size: 0.625rem;
  border: 1px solid #b5c0c5;
  padding: 4px 6px;
  margin-right: 8px;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.1s ease-in-out;
}
.prodbox-blan-box:hover {
  border: 1px solid #34a8fe;
  color: #34a8fe;
}

.prodbox-container p {
  font-size: 0.75rem;
  color: #757575;
  font-weight: 400;
  margin-right: 12px;
}
.prodbox-link-wrap {
  margin: 24px 0 0;
  height: 36px;
}

.prodbox-link-wrap a {
  padding: 6px 8px;
  color: #34a8fe;
  margin: 0 16px 0 -8px;
}
.prodbox-link-pricing a {
  color: #757575;
}
.prodbox-link-pricing a div {
  margin-right: 8px;
}

.prodbox-divLine {
  width: 100%;
  padding: 0 0 44px;
  position: absolute;
  bottom: 0;
}
.prodbox-blan-box {
  font-size: 0.625rem;
  border: 1px solid #b5c0c5;
  padding: 4px 6px;
  margin-right: 8px;
  border-radius: px;
}

@media only screen and (min-width: 768px) {
  .prodbox-container h2 {
    font-size: 1.25rem;
  }

  .prodbox-plans-wrap {
    font-size: 0.875rem;
  }
  .prodbox-link-wrap {
    margin: 24px 0 40px;
  }
  .prodbox-divLine {
    width: 100%;
    padding: 40px 0 44px;
  }
  .prodbox-container p {
    font-size: 0.875rem;
    margin-right: 24px;
  }
  .prodbox-divLine > * {
    box-sizing: border-box;
    border-top: 1px solid #eceff1;
  }
}
