.home-background {
  transition: 1s ease;
  width: 100%;
  overflow: hidden;
}
.home-background-grey {
  background: #f4f6f8;
}

/* ---------------------------------------------------------------- */

.splash {
  width: 100%;
  height: Calc(100vh - 48px);
  max-height: Calc(100vw * 1.778);
  text-align: center;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}

.splash-cont-wrap {
  position: relative;
  z-index: 5;
  display: grid;
  grid-template-rows: auto 1fr;
  padding: 16px 0;

  width: 100%;
  height: 100%;
}
.splash-white-fade {
  position: absolute;
  z-index: 6;
  background: linear-gradient(
    179.08deg,
    #ffffff 52.12%,
    rgba(255, 255, 255, 0) 72.67%
  );
  height: 60%;
  width: 100%;
}
.splash-popupWrap,
.splash-textWrap {
  position: relative;
  z-index: 7;
}
.splash-popupWrap {
  height: 100%;
}

.splash-cont-wrap h1 {
  font-size: 2rem;
  margin: 0 16px 8px;
}
.splash-cont-wrap h2 {
  font-size: 0.875rem;
  line-height: 1.71429;
  font-weight: 400;
  margin: 0 16px 16px;
  max-width: 550px;
  color: #757575;
}
.splash-cont-wrap .button {
  height: 36px;
  width: 120px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.splash-cont-wrap .button:first-child {
  margin-right: 16px;
}
.splash-cont-wrap .button div {
  margin-top: -4px;
}
.Flipit-splash {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  min-width: 100%;
  z-index: 2;
}
.splash span > *:first-child {
  margin-right: 24px;
}
.splash-map-icon-wrap {
  position: absolute;
  z-index: -1;
  top: 0;

  width: 100%;
  height: 100%;
  /* border: 1px solid blue; */
}

/* ---------------------------------------------------------------- */

.info-card {
  position: relative;
  z-index: 6;

  margin: -32px 16px 0;
  width: auto;
  padding: 24px 16px;
  /* min-height:256px; */
}
.info-card-header {
  display: flex;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 32px;
  white-space: nowrap;
  font-weight: 500;
}
.info-card-header div {
  color: #757575;
  border-bottom: 1px solid #cfd8dc;
  width: 100%;
  height: 32px;
}

.info-card-content {
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(1, 1fr);
}
/* .info-card-content > *:first-child {
  transition: transform 0.45s cubic-bezier(0.25, 1, 0.5, 1),
    opacity 0.45s cubic-bezier(0.25, 1, 0.5, 1);
  transform: translateY(50px) scale(0);
}
.info-card-content > *:first-child {
  transition: transform 0.45s cubic-bezier(0.25, 1, 0.5, 1),
    opacity 0.45s cubic-bezier(0.25, 1, 0.5, 1);
  transform: translateY(50px) scale(0);
}
.info-card-content > *:first-child {
  transition: transform 0.45s cubic-bezier(0.25, 1, 0.5, 1),
    opacity 0.45s cubic-bezier(0.25, 1, 0.5, 1);
  transform: translateY(50px) scale(0);
} */

.info-card .centered {
  margin-bottom: 16px;
  justify-content: center;
}
.info-card-icon-wrap {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background: #d0eefd;
  /* background: #34a8fe; */
}
.info-card h2 {
  font-weight: 600;
  margin-left: 16px;
}
.info-card p {
  color: #757575;
  max-width: 400px;
  margin: 0 auto 16px;
}
.info-card-head {
}

/* .home-content-wrapper {
  overflow-x: hidden;
} */

.home-PriceCards-header {
  text-align: center;
  max-width: 600px;
  margin-bottom: 76px;
}

.home-PriceCard-container {
  display: grid;
}

.home-price-link {
  /* background: rgba(236, 239, 241, 0.6); */
  background: #f4f6f8;
  border: 1px solid #34a8fe;
  box-sizing: border-box;
  border-radius: 5px;
  /* height: 64px; */
  width: Calc(100% - 32px);
  max-width: 750px;
  margin: 60px auto 0;
  padding: 20px 24px;

  font-size: 1.5rem;
  font-weight: 600;
  text-decoration: none;
  color: #323a42;
}
.home-price-link p {
  margin: 0;
  font-weight: 400;
}
.price-link-text-wrap {
  margin: 0 24px;
}

.mobile-app-link-conatiner {
  flex-wrap: wrap;
}
.mobile-app-link-conatiner > * {
  margin-bottom: 16px;
  transition: 0.1s ease-in-out;
}
.mobile-app-link-conatiner > *:hover {
  opacity: 0.7;
}
.mobile-app-link-conatiner > *:first-child {
  margin-right: 16px;
}

@media only screen and (max-width: 768px) {
  .home-Beta-card {
    width: Calc(100% - 32px);
  }
}

@media only screen and (min-width: 768px) {
  .splash {
    height: Calc(100vh - 48px);
    max-height: Calc(100vw * 0.667);
    /* max-height: Calc((100vw * .667) - 48px);  */
  }
  .splash-cont-wrap {
    padding: 32px 0;
  }
  .splash-cont-wrap h1 {
    margin: 32px 0 16px;
  }
  .splash-cont-wrap h2 {
    font-size: 1rem;
    line-height: 1.75rem;
    margin: 0 auto 32px;
  }
  .info-card {
    margin: -32px 0 0;
    width: 100%;
    text-align: left;
    padding: 32px 32px 48px;
  }
  .info-card-content {
    grid-template-columns: repeat(3, 1fr);
  }

  .info-card .centered {
    justify-content: flex-start;
  }

  .home-PriceCard-container {
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    grid-gap: 24px;
  }
  .home-price-link {
    width: 100%;
    max-width: 750px;
    margin: 60px auto 0;
    padding: 20px 24px;
  }
}

/* Small Desktop */
@media only screen and (min-width: 960px) {
  .info-card {
    padding: 48px 48px 56px;
  }
  .info-card-content {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 32px;
  }
  .splash-cont-wrap h1 {
    font-size: 2.75rem;
    margin: 32px 0 16px;
  }
  .splash-cont-wrap h2 {
    font-size: 1rem;
    line-height: 1.75rem;
  }
}

/* Desktop */
@media only screen and (min-width: 1200px) {
  .info-card {
    align-items: center;
    padding: 48px 56px 56px;
    min-height: 256px;
  }
  .info-card-content {
    grid-gap: 56px;
  }
}
