.prof-badg-cont {
  position: absolute;
  bottom: -4px;
  right: -4px;
  width: 30px;
  height: 30px;
  border-radius: 100%;

  z-index: 85;
}
.prof-badg-cont .prof-badg-wrap a {
  /* all: unset; */
  width: 24px;
  height: 24px;
  padding: 0;
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 100%;
  transition: 0.15s ease-in-out;
  cursor: pointer;
}

.h-pop-wrap .prof-badg-cont a:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.24), 0px 0px 4px rgba(0, 0, 0, 0.12);
  transform: scale(1.1);
  opacity: 1;
}
