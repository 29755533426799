.plans-widget-container {
  padding: 16px 0;
}

.plan-item-container {
  margin: 12px auto;
  height: 72px;
  box-sizing: border-box;
  padding: 0 16px;
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: 0.1s ease-out;
  cursor: pointer;
}

.plan-item-container:hover {
  border: 1px solid #34a8fe;
  /* background: rgba(52, 168, 254, 0.1); */
}

.plan-item-diabled {
  cursor: default;
  pointer-events: none;
}
.plan-item-diabled:hover {
  border: 1px solid rgba(255, 255, 255, 0.1);
}
.active-plan,
.active-plan:hover {
  border: 1px solid #34a8fe;
  background: rgba(52, 168, 254, 0.1);
}

.plan-select-button-icon-wrap {
  width: 36px;
  height: 36px;
  border-radius: 100%;
  background: rgba(255, 255, 255, 0.1);
  transition: 0.1s ease-out;
}

.active-plan .plan-select-button-icon-wrap {
  background: #34a8fe;
}

.plan-select-desc {
  text-align: left;
  margin-left: 16px;
}
.plan-select-pricing {
  text-align: right;
}
.plan-select-desc > *,
.plan-select-pricing > * {
  margin: 0;
}
.plan-select-desc h3,
.plan-select-pricing h3 {
  font-size: 1.09375rem;
  font-weight: 500;
  color: #fff;
}

.plan-item-diabled h3,
.plan-item-diabled p,
.plan-item-diabled svg path {
  color: rgba(255, 255, 255, 0.1);
  fill: #28323c;
}
.plan-select-desc p,
.plan-select-pricing p {
  font-size: 0.75rem;
}
