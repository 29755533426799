html,
body {
  margin: 0;
  position: relative;
  z-index: 10;
  font-family: "Inter";
  font-weight: 400;
  font-size: 1rem;
  color: #323a42;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  /* overflow-x: hidden; */
}
.god-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.content-wrapper {
  position: relative;
  flex: 1;
}
.page-link-top {
  position: absolute;
  top: -48px;
}
.page-item-link {
  position: relative;
  top: -74px;
}
input {
  font-family: "Inter";
}
div {
  box-sizing: border-box;
}

svg {
  display: block;
}
a {
  color: inherit;
  text-decoration: none;
}
/* Globals */
.outskrts {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.outskrts-vert {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.centered {
  display: flex;
  align-items: center;
}

.centered-col {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

/* Layout */
.sizing {
  width: 100%;
}
section {
  /* min-height: 90vh; */

  min-height: auto;
  box-sizing: border-box;
  position: relative;
}
.small-section {
  min-height: 200px;
  box-sizing: border-box;
  position: relative;
  z-index: 6;
}
.spacer {
  height: 10vh;
  max-height: 120px;
}

/* Page breaks */
.mobile-large-only {
  display: none;
}
.desktop-only {
  display: none;
}

/* //animations */
.anim-drpdwn {
  opacity: 0;
  animation: reveal-rev 0.75s cubic-bezier(0, 1, 0.1, 1) 1 normal forwards;
}
@keyframes reveal-rev {
  0% {
    opacity: 0;
    transform: translateY(-20%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.anim-left {
  opacity: 0;
  animation: reveal-rev-rit 1s cubic-bezier(0, 1, 0.1, 1) 1 normal forwards;
}
@keyframes reveal-rev-rit {
  0% {
    opacity: 0;
    transform: translateX(20%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.anim-up {
  opacity: 0;
  animation: reveal-up 0.75s cubic-bezier(0, 1, 0.1, 1) 1 normal forwards;
}
@keyframes reveal-up {
  0% {
    opacity: 0;
    transform: translateY(50%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.noContent-anim {
  opacity: 0;
  animation: reveal-noContent 1s cubic-bezier(0, 1, 0.1, 1) 1 normal forwards;
}
.noContent-anim .nocont-text {
  opacity: 0;
  animation: reveal-noContent 0.5s ease-in-out 0.25s normal forwards;
}
.noContent-anim p {
  opacity: 0;
  animation: reveal-noContent 0.75s ease-in-out 0.75s normal forwards;
}
@keyframes reveal-noContent {
  0% {
    opacity: 0;
    transform: translateY(50%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Inputs */
input[type="text"] {
  height: 32px;
  outline: none;
  border-radius: 5px;
  padding: 0 12px;
  font-family: inherit;
}
input[type="text"] ::placeholder {
  font-family: inherit;
}

/* large phone */
@media only screen and (min-width: 410px) {
  .mobile-large-only {
    display: flex;
  }
}

/* Tablet */
@media only screen and (min-width: 768px) {
  .sizing {
    width: 720px;
    margin: auto;
  }
  section {
    min-height: 480px;
    /* margin: 0 auto 10vh; */
    margin: 0 auto 0;
  }
  .mobile-only {
    display: none;
  }
  .desktop-only {
    display: flex;
  }
}

/* Small Desktop */
@media only screen and (min-width: 960px) {
  .sizing {
    width: 940px;
  }
  section {
    min-height: 627px;
  }
}

/* Desktop */
@media only screen and (min-width: 1200px) {
  .sizing {
    width: 1140px;
  }
  section {
    /* min-height: 760px; */
  }
}
