/* User Banner -------------------------------------------------------*/
.banner-header-user {
  padding: 16px 16px;
  box-sizing: border-box;
  flex-wrap: wrap;
  flex-direction: column;
}

.banner-profile-pic-container {
  position: relative;
}

.banner-profile-pic-wrap {
  border: 2px solid white;
  border-radius: 5px;
  box-sizing: border-box;
  width: 64px;
  height: 64px;
  position: relative;
  background: rgba(255, 255, 255, 0.1);
  overflow: hidden;
}
.banner-profile-pic-wrap img {
  width: 100%;
  margin: 0;
  display: block;
  /* position: absolute;
  top: 0;
  left: 0; */
  z-index: 82;
  object-fit: cover;
}
.banner-profile-pic-wrap svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 80;
}

.prof-badg-cont-adj {
  bottom: -8px;
  right: -8px;
}

.banner-user-info {
  color: #fff;
  margin-left: 16px;
}
.banner-user-info p {
  /* font-size: 0.75rem; */
  /* font-weight: 300; */
  margin: 0;
  opacity: 0.5;
}

.banner-profile-button-wrap {
  justify-content: center;
  display: flex;
  width: 100%;
}

.banner-tabs-userbuttons {
  padding: 0 16px;
}

.banner-profile-button-wrap > *:first-child {
  margin-right: 16px;
}

.banner-profile-button-wrap-noessential {
  display: none;
}

.banner-numbs-container {
  padding: 16px 24px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.1);
  margin-top: 16px;
}
.banner-numbs-wrap {
  color: #ffffff;
  display: flex;
  align-items: center;
}
.banner-numbs-wrap > *:last-child {
  opacity: 0.5;
  font-size: 0.75rem;
  font-weight: 300;
  margin-left: 8px;
}
.banner-numbs-div {
  width: 0px;
  height: 24px;
  border: 0.5px solid #8093a2;
  margin: 0 16px;
}

@media only screen and (min-width: 560px) {
  .banner-header-user {
    /* padding: 16px 0; */
    flex-direction: row;
  }
  .banner-numbs-container {
    margin-top: 0;
  }
  .banner-numbs-wrap {
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .banner-numbs-wrap > *:last-child {
    opacity: 0.5;
    font-size: 0.75rem;
    font-weight: 300;
    margin-left: 0;
  }
  .banner-numbs-div {
    width: 0px;
    height: 33px;
    border: 0.5px solid #8093a2;
    margin: 0 16px;
  }
  .banner-profile-button-wrap {
    justify-content: flex-start;
  }
  .banner-profile-button-wrap-noessential {
    display: flex;
  }
}

@media only screen and (min-width: 768px) {
  .banner-header-user {
    padding: 16px 0;
  }
  .banner-tabs-userbuttons {
    padding: 0;
  }
}
