@import url(https://rsms.me/inter/inter.css);
html,
body {
  margin: 0;
  position: relative;
  z-index: 10;
  font-family: "Inter";
  font-weight: 400;
  font-size: 1rem;
  color: #323a42;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  /* overflow-x: hidden; */
}
.god-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.content-wrapper {
  position: relative;
  flex: 1 1;
}
.page-link-top {
  position: absolute;
  top: -48px;
}
.page-item-link {
  position: relative;
  top: -74px;
}
input {
  font-family: "Inter";
}
div {
  box-sizing: border-box;
}

svg {
  display: block;
}
a {
  color: inherit;
  text-decoration: none;
}
/* Globals */
.outskrts {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.outskrts-vert {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.centered {
  display: flex;
  align-items: center;
}

.centered-col {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

/* Layout */
.sizing {
  width: 100%;
}
section {
  /* min-height: 90vh; */

  min-height: auto;
  box-sizing: border-box;
  position: relative;
}
.small-section {
  min-height: 200px;
  box-sizing: border-box;
  position: relative;
  z-index: 6;
}
.spacer {
  height: 10vh;
  max-height: 120px;
}

/* Page breaks */
.mobile-large-only {
  display: none;
}
.desktop-only {
  display: none;
}

/* //animations */
.anim-drpdwn {
  opacity: 0;
  -webkit-animation: reveal-rev 0.75s cubic-bezier(0, 1, 0.1, 1) 1 normal forwards;
          animation: reveal-rev 0.75s cubic-bezier(0, 1, 0.1, 1) 1 normal forwards;
}
@-webkit-keyframes reveal-rev {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20%);
            transform: translateY(-20%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes reveal-rev {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20%);
            transform: translateY(-20%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.anim-left {
  opacity: 0;
  -webkit-animation: reveal-rev-rit 1s cubic-bezier(0, 1, 0.1, 1) 1 normal forwards;
          animation: reveal-rev-rit 1s cubic-bezier(0, 1, 0.1, 1) 1 normal forwards;
}
@-webkit-keyframes reveal-rev-rit {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20%);
            transform: translateX(20%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes reveal-rev-rit {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20%);
            transform: translateX(20%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.anim-up {
  opacity: 0;
  -webkit-animation: reveal-up 0.75s cubic-bezier(0, 1, 0.1, 1) 1 normal forwards;
          animation: reveal-up 0.75s cubic-bezier(0, 1, 0.1, 1) 1 normal forwards;
}
@-webkit-keyframes reveal-up {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes reveal-up {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.noContent-anim {
  opacity: 0;
  -webkit-animation: reveal-noContent 1s cubic-bezier(0, 1, 0.1, 1) 1 normal forwards;
          animation: reveal-noContent 1s cubic-bezier(0, 1, 0.1, 1) 1 normal forwards;
}
.noContent-anim .nocont-text {
  opacity: 0;
  -webkit-animation: reveal-noContent 0.5s ease-in-out 0.25s normal forwards;
          animation: reveal-noContent 0.5s ease-in-out 0.25s normal forwards;
}
.noContent-anim p {
  opacity: 0;
  -webkit-animation: reveal-noContent 0.75s ease-in-out 0.75s normal forwards;
          animation: reveal-noContent 0.75s ease-in-out 0.75s normal forwards;
}
@-webkit-keyframes reveal-noContent {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes reveal-noContent {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

/* Inputs */
input[type="text"] {
  height: 32px;
  outline: none;
  border-radius: 5px;
  padding: 0 12px;
  font-family: inherit;
}
input[type="text"] ::-webkit-input-placeholder {
  font-family: inherit;
}
input[type="text"] :-ms-input-placeholder {
  font-family: inherit;
}
input[type="text"] ::-ms-input-placeholder {
  font-family: inherit;
}
input[type="text"] ::placeholder {
  font-family: inherit;
}

/* large phone */
@media only screen and (min-width: 410px) {
  .mobile-large-only {
    display: flex;
  }
}

/* Tablet */
@media only screen and (min-width: 768px) {
  .sizing {
    width: 720px;
    margin: auto;
  }
  section {
    min-height: 480px;
    /* margin: 0 auto 10vh; */
    margin: 0 auto 0;
  }
  .mobile-only {
    display: none;
  }
  .desktop-only {
    display: flex;
  }
}

/* Small Desktop */
@media only screen and (min-width: 960px) {
  .sizing {
    width: 940px;
  }
  section {
    min-height: 627px;
  }
}

/* Desktop */
@media only screen and (min-width: 1200px) {
  .sizing {
    width: 1140px;
  }
  section {
    /* min-height: 760px; */
  }
}

html {
  font-family: "Inter", sans-serif;
}
@supports (font-variation-settings: normal) {
  html {
    font-family: "Inter var", sans-serif;
  }
}
h1 {
}
h2 {
  font-size: 1.75;
  /* font-size: 2.25rem; */
  font-weight: 500;
  line-height: 1.3;
  margin: 0;
  /* margin: 0 0 1em; */
}
h3 {
  font-size: 1.3125rem;
  font-weight: 500;
  line-height: 1.3;
  margin: 0;
  /* margin: 0 0 1em; */
}
h4 {
  font-size: 1.09375rem;
  font-weight: 500;
  line-height: 1.3;
  margin: 0;
  /* margin: 0 0 1em; */
}
h5 {
  font-size: 1.75;
  font-weight: 500;
  line-height: 1.3;
  margin: 0;
}
p {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.71429;
  margin: 0 0 1em;
}

.head-container {
  width: 100%;
  z-index: 100;
  position: -webkit-sticky;
  position: sticky;
  /* position: fixed; */

  top: 0;
  background: #ffffff;
  -webkit-transition: 0.15s ease-in-out;
  transition: 0.15s ease-in-out;
  /* overflow-y: hidden; */
}
.head-container.active {
  /* box-shadow: 0px 1px 2px rgba(60, 64, 67, 0.3),
    0px 2px 6px rgba(60, 64, 67, 0.15); */

  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
}

.modal {
  background: #ffffff;
  box-shadow: 0px 20px 35px rgba(33, 56, 69, 0.2),
    0px 4px 12px rgba(17, 51, 83, 0.02);
  border-radius: 5px;

  font-size: 0.875rem;
}

.h-pop-wrap {
  position: absolute;
  top: 64px;
  left: 0;
  display: flex;
  justify-content: flex-end;
  max-width: 100%;
  box-sizing: border-box;
  pointer-events: none;
  z-index: 110;
}
.h-pop {
  width: 280px;
  margin-right: 16px;
  pointer-events: all;
}

.h-pop-sml,
.h-pop-sml p {
  font-size: 0.75rem;
  color: #919498;
  white-space: nowrap;
}
.pop-b-adj {
  width: Calc(50% - 6px);
}

.pop-sec {
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
  border-bottom: 1px solid #e8eaed;
}
.pop-sec-txt {
  margin: 12px 0;
}
.pop-sec-txt {
  color: #5f6368;
}
.pop-alert-empt {
  color: #dadce0;
}
.pop-alert-empt div {
  height: 32px;
  width: 32px;
  border-radius: 100%;
  margin-right: 12px;
  background: #dadce0;
}
.pop-foot {
  padding: 12px;
  border-bottom: none;
  justify-content: center;
}
/* .pop-foot {
  color: #919498;
} */

.pop-or-lin {
  margin: 10px 0 -10px;
  width: 100%;
  border: 0.5px solid #e8eaed;
}
.h-pop input {
  width: 100%;
  height: 32px;
  /* margin-bottom: 12px; */

  padding: 0 12px;
  font-size: 0.875rem;
  font-weight: 400;
  color: #323a42;

  display: flex;
  align-items: center;

  background: #ffffff;
  border: 1px solid #34a8fe;
  box-shadow: 0 0 1px 0px white inset, 0 0 1px 0px white;
  outline: none;
  box-sizing: border-box;
  border-radius: 5px;
}

/* .auth-pass {

} */

.h-pop input::-webkit-input-placeholder {
  color: #919498;
}

.h-pop input:-ms-input-placeholder {
  color: #919498;
}

.h-pop input::-ms-input-placeholder {
  color: #919498;
}

.h-pop input::placeholder {
  color: #919498;
}
.pop-sinup {
  cursor: pointer;
  justify-content: center;
  -webkit-transition: 0.15s;
  transition: 0.15s;

  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  color: #919498;
}
.pop-sinup:hover,
.pop-foot a:hover {
  color: #34a8fe;
}

.prof {
  position: relative;
}
.prof .userProPic {
  width: 64px;
  height: 64px;
  border-radius: 100%;
  overflow: hidden;
}
.prof .userProPic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.pop-sec-txt {
  margin: 8px auto 12px;
}
.user-email {
  color: rgba(47, 61, 77, 0.4);
}

.user-username {
  font-size: 1rem;
  font-weight: 500;
  color: #2f3d4d;
  /* height: 18px; */
}
.pop-alert-emp {
  color: rgba(47, 61, 77, 0.3);
  font-weight: 700;
}
.pop-alert-emp div {
  border-radius: 100%;
  margin-right: 16px;
  width: 32px;
  height: 32px;
  background: rgba(47, 61, 77, 0.2);
}

@media only screen and (min-width: 768px) {
  .h-pop {
    top: 64px;
    right: 16px;
  }
}

/* Small Desktop */
@media only screen and (min-width: 960px) {
}

/* Desktop */
@media only screen and (min-width: 1200px) {
}

.global-loading-spinner {
  margin: auto;
  box-sizing: border-box;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: rotation;
          animation-name: rotation;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  border-radius: 50%;
  /* border: 5px solid #e8eaed;
  border-right-color: transparent; */
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.prof-badg-cont {
  position: absolute;
  bottom: -4px;
  right: -4px;
  width: 30px;
  height: 30px;
  border-radius: 100%;

  z-index: 85;
}
.prof-badg-cont .prof-badg-wrap a {
  /* all: unset; */
  width: 24px;
  height: 24px;
  padding: 0;
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 100%;
  -webkit-transition: 0.15s ease-in-out;
  transition: 0.15s ease-in-out;
  cursor: pointer;
}

.h-pop-wrap .prof-badg-cont a:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.24), 0px 0px 4px rgba(0, 0, 0, 0.12);
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  opacity: 1;
}

.button {
  background: none;
  color: inherit;
  border: none;
  border-radius: 5px;
  padding: 0;
  font: inherit;
  font-weight: 500;
  font-size: 0.875rem;

  outline: inherit;
  cursor: pointer;
  padding: 6px 16px;
  min-height: 36px;
  box-sizing: border-box;
  white-space: nowrap;

  -webkit-transition: 0.15s ease-out;

  transition: 0.15s ease-out;
}

.button-lg {
  height: 52px;
}
.button p,
.button > * {
  margin: 0;
}

.button-link-wrap {
  padding: 0;
  margin: 0;
}
.button-link-wrap > * {
  display: block;
  margin: 0;
}

.badge-button {
  padding: 0;
}
.button-blue {
  /* transition: 0.25s ease; */

  background: #039be5;
  color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.1);
}
.button-blue:hover {
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.12), 0px 3px 6px rgba(0, 0, 0, 0.09);
  /* box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.09); */
}

.button-white {
  background: #fff;
  color: #34a8fe;
}

.button-white:hover,
.button-white:hover svg path {
  background: rgba(52, 168, 254, 0.1);
  fill: #34a8fe;
}

.button-silver {
  background: rgba(255, 255, 255, 0.1);
}
.button-silver:hover {
  background: rgba(255, 255, 255, 0.05);
  color: #fff;
}
.button-silver-text {
  /* background: rgba(255, 255, 255, 0.1); */
  color: rgba(255, 255, 255, 0.5);
}
.button-silver-text:hover {
  background: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 1);
}

.button-blue-outline {
  color: #34a8fe;
  background: none;
  border: 1px solid #34a8fe;
}

.button-submit {
  height: 32px;

  background: #34a8fe;
  color: white;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.svg-button-20 {
  padding: 6px;
}
.svg-button {
  padding: 4px;
}

.svg-text-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 16px;
}
.svg-text-button > * {
  margin-right: 4px;
}

.svg-button:hover,
.badge-button:hover {
  opacity: 0.5;
}

.suppor-button {
  height: 36px;
  padding: 6px 16px;
}

.Join-button {
  height: 40px;
}

.button-profile-skinny {
  min-height: 30px;
  height: 27px;

  border: 1px solid #eceff1;
  box-sizing: border-box;
  /* box-shadow: 0px 4px 12px rgba(17, 51, 83, 0.02); */
  border-radius: 20px;
  color: #2f3d4d;
  font-size: 0.75rem;
  -webkit-transition: 0.1s ease-in-out;
  transition: 0.1s ease-in-out;
}

.button-signout-skinny {
  border-radius: 5px;
  margin: 0 auto;
}

.button-signout-skinny:hover,
.button-profile-skinny:hover {
  background: #eceff1;
  opacity: 0.75;
}

.post-botton,
.post-botton-sml {
  width: 100%;
  display: flex;
  justify-content: center;
}

.post-botton .button-text {
  margin-left: 12px;
  font-size: 1rem;
  /* transition: 0.2s ease-in-out; */
  display: block;
}

@media only screen and (max-width: 768px) {
  .post-botton {
    height: 56px;
    width: 152px;
    border-radius: 28px;
    position: fixed;
    right: 24px;
    bottom: 32px;
    display: flex;
    /* z-index: 80; */
    box-shadow: 0px 20px 35px rgba(0, 0, 0, 0.4);
  }

  .post-botton-sml {
    width: 56px;
  }

  .post-botton .button-text {
    display: none;
  }
  .post-botton .button-mobile-adj {
    display: block;
  }
  .suppor-button {
    padding: 6px;
  }
}

.side-nav-back {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 60;
  background: rgba(0, 0, 0, 0);
  -webkit-transition: 0.15s ease;
  transition: 0.15s ease;
  pointer-events: none;
}

.side-nav {
  position: relative;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  width: 0;
  left: 0;
  height: 100vh;
  background: #fff;
  -webkit-transition: 0.25s cubic-bezier(0, 1, 0.1, 1);
  transition: 0.25s cubic-bezier(0, 1, 0.1, 1);
}
.active-sideNav-back {
  pointer-events: auto;
  background: rgba(0, 0, 0, 0.5);
}

.active-sideNav {
  width: 268px;
  box-shadow: 0px 20px 35px rgba(33, 56, 69, 0.2);
}

.nav-wrap .side-nav a {
  width: 100%;
  height: 48px;
  padding: 12px 24px;
  box-sizing: border-box;
  font-weight: 700;
  border-top: 0.5px solid #e8eaed;
}

.nav-wrap .side-nav a:hover {
  background: #fafbfc;
}

.sidenav-subnav {
  display: inherit;
  flex-direction: column;
  padding: 0 0 12px;
}

.nav-wrap .side-nav .sidenav-subnav a {
  padding: 8px 24px 8px 40px;
  height: 32px;
  font-weight: 400;
  border-top: none;
}

.sidenav-header {
  display: inherit;
  flex-direction: row;
  align-items: center;
  height: 48px;
}

.sidenav-header .nav-Logo {
  margin-left: 0;
}

.sidenav-selected {
  color: #34a8fe;
}

.sidenav-legal {
  color: #919498;
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #fff;
  box-sizing: border-box;
  padding: 8px 16px;
  margin: 0;
  font-weight: 300;
  white-space: nowrap;
  font-size: 0.75rem;
}

/* @keyframes reveal-nav {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
} */

.nav-wrap {
  display: flex;
  align-items: center;
  /* padding: 8px 0; */
  height: 48px;
  box-sizing: border-box;
  /* padding: 0 16px; */
  /* position: relative; */
}

.nav-wrap a {
  font-weight: 400;
  font-size: 0.875rem;
  -webkit-transition: 0.15s ease-in-out;
  transition: 0.15s ease-in-out;
}
.nav-wrap a:hover {
  opacity: 0.7;
}

.nav-wrap-left a {
  color: #323a42;
  /* color: #757575; */
}
.nav-menu {
  padding: 8px;
  /* margin-right: 12px; */
  margin: 0 12px 0 8px;
}

.pho-link {
  background: none;
  border: none;

  padding: 6px 18px;

  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.nav-wrap-right a,
.pho-link p {
  color: #34a8fe;
  font-weight: 500;
  margin: 0;
}

.nav-spacer {
  width: 0px;
  height: 32px;
  box-sizing: border-box;
  border: 0.5px solid #eceff1;
  /* border: 0.5px solid #8093A2; */
}
.nav-Logo {
  width: auto;
  height: 22px;
  margin-left: -18px;
}

.nav-wrap a {
  height: 20px;
  padding: 6px 18px;
  font-size: 0.875rem;
  text-decoration: none;
  cursor: pointer;
}

.profile-spinner-wrap {
  width: 32px;
  height: 32px;
  margin: 0 12px 0 0;
  box-sizing: content-box;
  border: 4px solid transparent;
}
.profile-nav-button {
  background: none;
  border: none;
  outline: none;
  width: 32px;
  height: 32px;
  overflow: hidden;
  border-radius: 100%;
  cursor: pointer;
  padding: 0;
  box-sizing: content-box;
  margin: 0 12px 0 0;
  -webkit-transition: 0.07s ease-in;
  transition: 0.07s ease-in;
  border: 4px solid transparent;
}
.profile-nav-button:hover {
  border: 4px solid #e8eaed;
}
.profile-nav-button img {
  width: 32px;
  height: 32px;
  object-fit: cover;
}

@media only screen and (min-width: 768px) {
  .nav-wrap {
    padding: 0;
  }
  .profile-nav-button {
    margin: 0 0 0 8px;
  }
}

.navbar-alert-cont {
  width: 100%;
  height: 0;
  overflow: hidden;
  background: #34a8fe;
  color: #fff;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
.navbar-alert-cont p {
  margin: 0;
}

.active-nav-alert {
  height: 24px;
}

.nav-alert-close-button {
  cursor: pointer;
  -webkit-transition: 0.15s ease;
  transition: 0.15s ease;
  padding: 0 8px;
  /* margin-right: -8px; */
}
.nav-alert-close-button:hover {
  opacity: 0.5;
}

.Beta-link-wrap {
  padding-right: 24px;
  cursor: pointer;
}

@media only screen and (max-width: 370px) {
  .Beta-link-wrap > *:last-child {
    display: none;
  }
}

.footer-container {
  text-align: center;
  background: #28323c;
  box-sizing: content-box;
  /* border: 1px solid red;
  position: sticky;
  bottom: 0; */
  /* width: 100%;
  position: absolute;
  bottom: 0; */
}
.footer-wrap {
  display: grid;
  padding: 32px 16px;
  color: white;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 32px;
}

.footer-wrap ul {
  list-style: none;
  padding: 0;
  margin: 0 0 6px;
}
.footer-wrap ul > *:last-child {
  margin-bottom: 0;
}

.footer-wrap li {
  width: 100%;
  margin-bottom: 10px;
}
.footer-wrap li > *:first-child {
  margin-right: 14px;
}
.footer-wrap li p {
  margin: 0;
}
.footer-wrap li a p {
  -webkit-transition: 0.1s ease-in-out;
  transition: 0.1s ease-in-out;
  cursor: pointer;
}
.footer-wrap li a:hover p {
  opacity: 1;
  color: #34a8fe;
}

.footer-wrap p {
  font-size: 12px;
  font-weight: 300;
  opacity: 0.6;
}

.footer-wrap h4 {
  line-height: 24px;
  margin-bottom: 16px;
}

.footer-logo {
  height: 22px;
  margin: 0 auto 18px;
}

.footer-wrap li,
.footer-subscribe form,
.footer-app-badges {
  justify-content: center;
}

.footer-app-badges {
  flex-wrap: wrap;
}
.footer-app-badges > * {
  margin-top: 12px;
}

.footer-social-icons > *:not(:last-child),
.footer-app-badges > *:first-child {
  margin-right: 12px;
}

.footer-subscribe .sub-form button {
  border: none;
  border-radius: 0 5px 5px 0;
  padding: 4px;
  background: #34a8fe;
}

.footer-subscribe input {
  background: #323a42;
  border: none;
  color: #fff;
  border-radius: 5px 0 0 5px;
  width: Calc(100% - 32px);
  max-width: 160px;
  height: 32px;
  font-family: inherit;
  box-sizing: border-box;
  padding: 6px 0 6px 12px;
}
.footer-subscribe input ::-webkit-input-placeholder {
  color: #8093a2;
}
.footer-subscribe input :-ms-input-placeholder {
  color: #8093a2;
}
.footer-subscribe input ::-ms-input-placeholder {
  color: #8093a2;
}
.footer-subscribe input ::placeholder {
  color: #8093a2;
}

.footer-legal {
  height: 24px;
  background: #1c252e;
  text-align: center;
}
.footer-legal p {
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  margin: 0;

  color: #8093a2;
}
.futter-sub-button-adj {
  margin: auto;
  padding: 0 16px;
}

/* large phone */
@media only screen and (min-width: 420px) {
  .footer-wrap {
    grid-template-columns: repeat(2, 1fr);
    text-align: left;
    grid-gap: 24px;
  }
  .footer-wrap > * {
    position: relative;
  }
  .footer-wrap li,
  .footer-subscribe form,
  .footer-app-badges {
    justify-content: flex-start;
  }
  .footer-logo {
    margin: 0 0 18px;
  }
  .footer-social-icons > *:first-child {
    margin-left: -6px;
  }
  .futter-sub-button-adj {
    margin: 0;
  }
}

@media only screen and (min-width: 768px) {
  .footer-wrap {
    grid-template-columns: repeat(4, 1fr);

    padding: 32px 0;
  }
  .posts-container {
    grid-column: 1 / 6;
  }
}

.pricing-free-card {
  margin-bottom: 24px;
  border-radius: 5px;
  background: #fff;
  text-align: center;
  padding: 32px 16px;
  position: relative;
  overflow: hidden;
}
.pricing-beta-card {
  display: flex;
  flex-direction: column-reverse;
}
.pricing-free-card h3,
.pricing-free-card p,
.pricing-free-card button {
  position: relative;
  z-index: 6;
}
.pricing-free-card h3 {
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 36px;
  margin-bottom: 16px;
}
.pricing-free-card p {
  font-size: 0.75rem;
  line-height: 18px;
  color: #757575;
  margin: 0 auto 24px;
  max-width: Calc(100% - 32px);
}
.pricing-free-card a {
  color: rgb(52, 168, 254);
}
/* .betacard-svg-container {
  height: 80px;
  width: 80px;
  border: 1px solid red;
} */
.pricing-free-card svg {
  display: block;
  margin: 0 auto 16px;
  width: 60%;
  /* max-height: 10%; */
}

@media only screen and (min-width: 768px) {
  .pricing-free-card {
    margin-bottom: 48px;
  }

  .pricing-free-card,
  .pricing-beta-card {
    text-align: left;
  }
  .pricing-free-card {
    padding: 24px;
  }
  .pricing-free-card h3 {
    font-size: 2rem;
    line-height: 46px;
    margin-bottom: 8px;
  }
  .pricing-free-card p {
    font-size: 1rem;
    line-height: 28px;
    margin: 0 0 24px;
    max-width: 350px;
  }
  .pricing-free-card svg {
    height: auto;
    width: 42%;
    right: -7%;
    top: 7%;
    display: block;
    position: absolute;
    z-index: 4;
  }
}

@media only screen and (min-width: 960px) {
  .pricing-free-card {
    padding: 36px;
  }
  .pricing-free-card p {
    max-width: 500px;
  }
  .pricing-free-card svg {
    width: 40%;
    right: -7%;
    top: -8%;
  }
}
@media only screen and (min-width: 1200px) {
  .pricing-free-card {
    padding: 72px;
  }
  .pricing-free-card p {
    max-width: 600px;
  }
  .pricing-free-card svg {
    width: 40%;
    right: -5%;
    top: -25%;
  }
}

.card {
  padding: 16px;
  text-align: center;
  background: #ffffff;
  border-radius: 10px;
  margin-bottom: 12px;
  width: 100%;
  box-sizing: border-box;
}

/* .card :hover {
  box-shadow: 0px 2px 4px rgba(60, 64, 67, 0.3),
    0px 0px 4px rgba(60, 64, 67, 0.3);
} */

.card-shdow-sml {
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
}
.card-shdow-lg {
  box-shadow: 0px 20px 35px rgba(33, 56, 69, 0.2);
}

.forum-content-card {
  -webkit-transition: 0.1s ease-in-out;
  transition: 0.1s ease-in-out;
}
.forum-content-card:hover {
  box-shadow: 0px 6px 8px rgba(33, 56, 69, 0.2);
  /* background: rgba(255, 255, 255, 0.2); */
  /* transform: scale(1.005); */
}

.post-grid {
  text-align: left;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas:
    "cat cat"
    "tit tit"
    "img img"
    "cont cont"
    "user vote";
}
.post-grid p {
  margin-bottom: 0;
}
.c-cat {
  grid-area: cat;
  height: 24px;
  color: #939eab;
  text-transform: uppercase;
}

.forum-best-answer-badge {
  font-size: 0.75rem;
  text-transform: capitalize;
  font-weight: 500;
  color: #fff;
  border-radius: 13px;
  background: #02bfa6;
  /* height: 24px; */
  padding: 4px;
  margin-right: 8px;
}
.forum-best-answer-badge div {
  display: none;
}

.c-tit {
  grid-area: tit;
  min-height: 32px;
  padding: 4px 0;
  box-sizing: border-box;
}
.c-cont {
  grid-area: cont;
  margin-bottom: 16px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.c-cont p {
  color: #757575;
}

.c-user {
  grid-area: user;
  height: 24px;
  max-width: 100%;
  overflow: hidden;
  color: #939eab;
}

.c-user p,
.c-user svg {
  margin-left: 8px;
}

.c-user img {
  display: none;
  height: 24px;
  width: 24px;
  object-fit: cover;
  border-radius: 5px;
  margin: 0;
}

.c-vote {
  grid-area: vote;
  display: flex;
  align-items: center;
  text-align: center;
  max-width: 90px;
}

.post-img-wrap {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  overflow: hidden;
  border-radius: 5px;
  margin-bottom: 16px;
}
.post-img-wrap img {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: auto;
}

@media only screen and (min-width: 410px) {
  .c-user img {
    display: block;
  }
  .forum-best-answer-badge {
    padding: 4px 12px 4px 8px;
  }
  .forum-best-answer-badge svg {
    margin-right: 8px;
  }
  .forum-best-answer-badge div {
    display: block;
  }
}

@media only screen and (min-width: 960px) {
  .post-grid {
    padding: 24px;
    grid-template-columns: 24px auto 1fr;
    grid-template-areas:
      "vote img cat"
      "vote img tit"
      "vote img cont"
      "vote img user";
  }
  .c-cat,
  .c-tit,
  .c-img,
  .c-cont,
  .c-user {
    margin-left: 24px;
  }
  .c-cont {
    flex-direction: row;
  }
  .post-img-wrap {
    padding-top: 0;
    width: 180px;
    min-width: 180px;
    height: 120px;
    margin: 0 16px 0 0;
  }

  .c-vote {
    justify-content: flex-start;
    flex-direction: column;
  }
}

/* Desktop */
@media only screen and (min-width: 1200px) {
  .post-img-wrap {
    width: 108px;
    min-width: 108px;
    height: 72px;
    margin: 0 16px 0 0;
  }
}

.Price-Card-wrapper {
  background: rgba(255, 0, 0, 0);
  max-width: 320px;
  margin: auto;
  border-radius: 10px;
  -webkit-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.active-card-back {
  background: #fff;
}

.priceCard-plan {
  margin: 24px auto 16px;
  font-size: 1.25rem;
  font-weight: 600;
}

.priceCard-price {
  font-weight: 600;
  /* font-size: 4rem; */
  font-size: 3.375rem;
  -webkit-transition: 0.15s ease-in-out;
  transition: 0.15s ease-in-out;

  /* line-height: 77px; */
}
.plan-timeferame {
  font-size: 2.75rem;
}

.card-shdow-lg .priceCard-price {
  color: #34a8fe;
}
.priceCard-timeframe {
  /* text-transform: uppercase; */
  margin: 9px auto 16px;
  font-size: 0.875rem;
  /* font-size: 0.75rem; */
  /* font-weight: 600; */
  color: #adb7be;
}

.priceCard-text {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 24px;
  text-align: center;
  width: 100%;
  /* max-width: 175px; */
  margin: 0 auto 32px;
  color: #757575;
  font-weight: 600;
}
.price-includes-container {
  margin: auto;
  align-items: flex-start;
}
.price-includes-container > * {
  /* height: 24px; */
  margin: 2px;
}
.price-includes-container svg {
  margin-right: 8px;
}
.price-includes-container svg path {
  fill: #757575;
  -webkit-transition: 0.15s ease-in-out;
  transition: 0.15s ease-in-out;
}
.card-shdow-lg .price-includes-container svg path {
  fill: #34a8fe;
}
.price-includes-container a {
  width: auto;
  padding: 8px 16px;
  -webkit-transition: 0.15s ease-in-out;
  transition: 0.15s ease-in-out;
  border-radius: 5px;
}
.price-includes-container a:hover {
  /* background: #d0eefd; */
  background: rgb(244, 246, 248);
}

.priceCard-button {
  height: 40px;
  padding: 6px 24px;
  margin-bottom: 24px;
}
/*
.price-icon-wrap {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background: #adb7be;
  margin: 8px;
}

.card-shdow-lg .price-icon-wrap {
  background: #34a8fe;
} */

.card-shdow-lg .price-includes-container {
  color: #34a8fe;
}

/* ----------------------------------------------------s */

.home-console-container {
  position: relative;
  margin: 2rem 0;
}
.home-console-container-background {
  position: absolute;
  z-index: -1;
  left: 50%;
  width: 100%;
  height: 100%;
  max-width: 1320px;
}
.home-console-container-background > * {
  position: absolute;
}
.home-console-container-background > *:first-child {
  left: -50%;
  top: 0;
}
.home-console-container-background > *:last-child {
  right: 50%;
  bottom: -10%;
}

/* ---------------------------------------------------------- */
.home-console {
  position: -webkit-sticky;
  position: sticky;
  text-align: center;
  font-size: 1.3125rem;
  line-height: 1.71429;
  font-weight: 300;
  margin-bottom: 1rem;
  padding: 0 16px;
}
.home-console > * a {
  margin: 32px auto 0;
}
/* .home-console > * a svg {
  margin-left: 8px;
} */

.home-console img {
  max-width: 100%;
}
.home-console-header {
  margin: auto;
  max-width: 750px;
}
.home-console-header h2 {
  font-size: 2rem;
  font-weight: 700;
  margin: 0 auto 16px;
}
.home-console-header p {
  font-size: 0.875rem;
  color: #757575;
  font-weight: 400;
  max-width: 600px;
  margin: 0 auto 32px;
}

.console-product-button-wrap {
  /* border: 1px solid red; */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
/* ----------------------------------------------------s */

@media only screen and (min-width: 768px) {
  .home-console-header h2 {
    font-size: 2.75rem;
  }
  .home-console-header p {
    font-size: 1rem;
    margin: 0 auto 56px;
  }
}
@media only screen and (min-width: 960px) {
}
@media only screen and (min-width: 1200px) {
}

/* ----------------------------------------------------s */

.home-console-new-container {
  position: relative;
  padding: 16px 16px 80px;
  box-sizing: border-box;
  /* border: 1px solid red; */
  /* width: 100%; */
  /* display: block; */
}
.home-console-new-container > * {
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
.home-console-new-container > *:hover {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
}

.console-slider-container {
  width: 50%;
  display: flex;
  align-items: center;
  position: relative;
  background: none;
}
.console-slider-left {
  justify-content: flex-start;
}
.console-slider-right {
  justify-content: flex-end;
}

.lrg-console-img {
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.32),
    0px 15px 25px rgba(0, 0, 0, 0.12), 0px 5px 10px rgba(0, 0, 0, 0.09);
  width: 50%;
  position: absolute;
  margin: 0 Calc(25% - 16px);
  z-index: 12;
  border-radius: 7px;
}
.lrg-console-img img {
  width: 100%;
  margin: 0;
  display: block;
}

.sml-console-img {
  box-shadow: 0px 15px 32px rgba(0, 0, 0, 0.24),
    0px 5px 10px rgba(0, 0, 0, 0.09);
  width: 66.66667%;
}
.sml-console-img-left {
  -webkit-transform: translateX(40px);
          transform: translateX(40px);
}
.sml-console-img-right {
  -webkit-transform: translateX(-40px);
          transform: translateX(-40px);
}

.console-img {
  border-radius: 7px;
  overflow: hidden;
  -webkit-transition: -webkit-transform 0.45s cubic-bezier(0.25, 1, 0.5, 1);
  transition: -webkit-transform 0.45s cubic-bezier(0.25, 1, 0.5, 1);
  transition: transform 0.45s cubic-bezier(0.25, 1, 0.5, 1);
  transition: transform 0.45s cubic-bezier(0.25, 1, 0.5, 1), -webkit-transform 0.45s cubic-bezier(0.25, 1, 0.5, 1);
}
.console-image-active {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.console-img-badge-constainer {
  position: absolute;
  bottom: -40px;
  -webkit-transition: opacity 0.45s cubic-bezier(0.25, 1, 0.5, 1),
    -webkit-transform 0.45s cubic-bezier(0.25, 1, 0.5, 1);
  transition: opacity 0.45s cubic-bezier(0.25, 1, 0.5, 1),
    -webkit-transform 0.45s cubic-bezier(0.25, 1, 0.5, 1);
  transition: transform 0.45s cubic-bezier(0.25, 1, 0.5, 1),
    opacity 0.45s cubic-bezier(0.25, 1, 0.5, 1);
  transition: transform 0.45s cubic-bezier(0.25, 1, 0.5, 1),
    opacity 0.45s cubic-bezier(0.25, 1, 0.5, 1),
    -webkit-transform 0.45s cubic-bezier(0.25, 1, 0.5, 1);
  -webkit-transition-delay: 0.3s;
          transition-delay: 0.3s;
  -webkit-transform: translateY(50px) scale(0);
          transform: translateY(50px) scale(0);
}
.console-img-badge-constainer-lg {
  left: -24px;
}

.console-img-badge-wrap {
  width: 64px;
  height: 64px;

  border-radius: 100%;
  background: rgba(52, 168, 254, 0.3);
}

.console-img-badge-wrap-sml {
  width: 48px;
  height: 48px;
  bottom: -24px;
  box-shadow: 0px 20px 35px rgba(33, 56, 69, 0.2),
    0px 4px 12px rgba(17, 51, 83, 0.02);
}

.console-img-badge-lft {
  -webkit-transform: translateY(50px) scale(0);
          transform: translateY(50px) scale(0);
  left: 0;
  margin-left: -20px;
  -webkit-transition-delay: 0.15s;
          transition-delay: 0.15s;
}
.console-img-badge-rit {
  -webkit-transform: translateY(50px) scale(0);
          transform: translateY(50px) scale(0);
  right: -20px;
  -webkit-transition-delay: 0.45s;
          transition-delay: 0.45s;
}

.animate-badge1,
.animate-badge2,
.animate-badge3 {
  -webkit-animation: float-alt-b 12s ease-in-out infinite;
          animation: float-alt-b 12s ease-in-out infinite;
}
.animate-badge1 {
  -webkit-animation-delay: -2s;
          animation-delay: -2s;
}

.animate-badge3 {
  -webkit-animation-delay: -4s;
          animation-delay: -4s;
}

.console-img-badge-active {
  -webkit-transform: translateY(0) scale(1);
          transform: translateY(0) scale(1);
}

.console-img-badge-wrap div {
  width: 48px;
  height: 48px;
  border-radius: 100%;
  background: #34a8fe;
}
.console-img-badge-wrap-sml div {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background: #34a8fe;
}

.badge-ilnk-text {
  opacity: 0;
  color: #fff;
  font-weight: 700;
  position: absolute;
  line-height: 30px;
  top: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  padding: 12px;
  border-radius: 5px;
  box-sizing: border-box;
  -webkit-transition: opacity 0.45s cubic-bezier(0.25, 1, 0.5, 1);
  transition: opacity 0.45s cubic-bezier(0.25, 1, 0.5, 1);
}
.console-tab-wrap-lft .badge-ilnk-text {
  left: 0;
  width: 66.66667%;
}
.console-tab-wrap-cent .badge-ilnk-text {
  left: 0;
  width: 100%;
}
.console-tab-wrap-rit .badge-ilnk-text {
  right: 0;
  width: 66.66667%;
}
.console-tab-wrap-lft:hover .badge-ilnk-text,
.console-tab-wrap-cent:hover .badge-ilnk-text,
.console-tab-wrap-rit:hover .badge-ilnk-text {
  opacity: 1;
}

@-webkit-keyframes float-alt-b {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    -webkit-transform: translatey(0px) rotate(-8deg);
            transform: translatey(0px) rotate(-8deg);
  }
  50% {
    box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    -webkit-transform: translatey(-16px) rotate(8deg);
            transform: translatey(-16px) rotate(8deg);
  }
  100% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    -webkit-transform: translatey(0px) rotate(-8deg);
            transform: translatey(0px) rotate(-8deg);
  }
}

@keyframes float-alt-b {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    -webkit-transform: translatey(0px) rotate(-8deg);
            transform: translatey(0px) rotate(-8deg);
  }
  50% {
    box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    -webkit-transform: translatey(-16px) rotate(8deg);
            transform: translatey(-16px) rotate(8deg);
  }
  100% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    -webkit-transform: translatey(0px) rotate(-8deg);
            transform: translatey(0px) rotate(-8deg);
  }
}

@media only screen and (min-width: 768px) {
  .home-console-new-container {
    margin-left: -16px;
    padding: 24px 0 120px;
  }
  .console-img-badge-wrap {
    width: 96px;
    height: 96px;
  }
  .console-img-badge-wrap-sml {
    width: 64px;
    height: 64px;
  }
  .console-img-badge-wrap div {
    width: 80px;
    height: 80px;
  }
  .console-img-badge-wrap-sml div {
    width: 52px;
    height: 52px;
  }
  .lrg-console-img {
    margin: 0 25%;
  }
}

.content-section-container {
  position: relative;
}
.content-section-background {
  position: absolute;
  z-index: -1;
  left: 50%;
  width: 100%;
  max-width: 1320px;
  margin: auto;
}
.content-section-background-flipped {
  display: flex;
  justify-content: flex-end;
  /* border: 1px solid red; */
}
.content-section-background svg {
  position: relative;
  left: -50%;
}

.content-section-cont-wrap {
  padding: 4rem 1rem;
  display: inline-flex;
  flex-direction: column;
  /* border: 1px solid red; */
}
.content-section-cont-wrap-rev {
  flex-direction: column-reverse;
}

.content-section-cont-wrap-adg {
  padding: 6rem 0;
}

.content-section-image-wrap {
  margin: 0 auto 2rem;
  position: relative;
  width: 90%;
}
.content-section-image-wrap img {
  width: 80%;

  border-radius: 7px;
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.12),
    0px 5px 10px rgba(0, 0, 0, 0.09);
}

/* .content-section-image-container {
  border: 1px solid red;
  position: relative;
  width: 100%;
  height: 100%;
} */

.content-section-image-wrap-1 {
  /* margin: 0 4rem 0 0 */
}

.content-section-image-wrap-2 {
  margin: 0 auto 13%;
  width: 80%;
  /* border: 1px solid red; */
}

/*.content-section-image-wrap-3 {
  margin: 0 4rem 2rem 0;
} */

/* middel image animation------------------------------------ */
.content-section-image-middle {
  box-shadow: 0 2.74416px 2.74416px rgba(0, 0, 0, 0.0274351),
    0 5.48831px 5.48831px rgba(0, 0, 0, 0.0400741),
    0 13.7208px 10.9766px rgba(0, 0, 0, 0.0499982),
    0 20.5812px 20.5812px rgba(0, 0, 0, 0.0596004),
    0 41.1623px 41.1623px rgba(0, 0, 0, 0.0709366),
    0 96.0454px 89.1851px rgba(0, 0, 0, 0.09);

  position: absolute;
  left: 0;
  -webkit-transition-delay: 0.05s;
          transition-delay: 0.05s;
  -webkit-transform: scale(0.92);
          transform: scale(0.92);

  border: 1px solid #eceef2;
  -webkit-transition: opacity 0.5s cubic-bezier(0.34, 1.56, 0.64, 1),
    -webkit-transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
  transition: opacity 0.5s cubic-bezier(0.34, 1.56, 0.64, 1),
    -webkit-transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
  transition: opacity 0.5s cubic-bezier(0.34, 1.56, 0.64, 1),
    transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
  transition: opacity 0.5s cubic-bezier(0.34, 1.56, 0.64, 1),
    transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1),
    -webkit-transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
}
.content-section-image-middle-bottom {
  box-shadow: 0 2.74416px 2.74416px rgba(0, 0, 0, 0.0274351),
    0 5.48831px 5.48831px rgba(0, 0, 0, 0.0400741),
    0 13.7208px 10.9766px rgba(0, 0, 0, 0.0499982),
    0 20.5812px 20.5812px rgba(0, 0, 0, 0.0596004),
    0 41.1623px 41.1623px rgba(0, 0, 0, 0.0709366),
    0 96.0454px 89.1851px rgba(0, 0, 0, 0.09);

  -webkit-transform: translateX(-40px);

          transform: translateX(-40px);

  border: 1px solid #eceef2;
  -webkit-transition: opacity 0.5s cubic-bezier(0.34, 1.56, 0.64, 1),
    -webkit-transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
  transition: opacity 0.5s cubic-bezier(0.34, 1.56, 0.64, 1),
    -webkit-transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
  transition: opacity 0.5s cubic-bezier(0.34, 1.56, 0.64, 1),
    transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
  transition: opacity 0.5s cubic-bezier(0.34, 1.56, 0.64, 1),
    transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1),
    -webkit-transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.content-section-image-dot {
  position: absolute;
  left: calc(2px + 1%);
  max-width: 12%;
}
.content-section-image-dot img {
  box-shadow: 0 2.74416px 2.74416px rgba(0, 0, 0, 0.0274351),
    0 5.48831px 5.48831px rgba(0, 0, 0, 0.0400741),
    0 13.7208px 10.9766px rgba(0, 0, 0, 0.0499982),
    0 20.5812px 20.5812px rgba(0, 0, 0, 0.0596004),
    0 41.1623px 41.1623px rgba(0, 0, 0, 0.0709366),
    0 96.0454px 89.1851px rgba(0, 0, 0, 0.09);
  display: block;
  opacity: 0;
  width: 100%;
  border-radius: 100%;
  overflow: hidden;
  background: none;
  -webkit-transition: opacity 0.5s cubic-bezier(0.34, 1.56, 0.64, 1),
    -webkit-transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
  transition: opacity 0.5s cubic-bezier(0.34, 1.56, 0.64, 1),
    -webkit-transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
  transition: opacity 0.5s cubic-bezier(0.34, 1.56, 0.64, 1),
    transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
  transition: opacity 0.5s cubic-bezier(0.34, 1.56, 0.64, 1),
    transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1),
    -webkit-transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
  -webkit-transform: translateY(15px);
          transform: translateY(15px);
}

.content-section-image-dot1 {
  top: 8%;
  -webkit-animation: float-alt-c 7s ease-in-out infinite;
          animation: float-alt-c 7s ease-in-out infinite;
}

.content-section-image-dot2 {
  top: 31%;
  -webkit-animation: float-alt-c 7s ease-in-out infinite;
          animation: float-alt-c 7s ease-in-out infinite;
  -webkit-animation-duration: 8s;
          animation-duration: 8s;
  -webkit-animation-delay: -2s;
          animation-delay: -2s;
}
.content-section-image-dot2 img {
  -webkit-transition-delay: 0.15s;
          transition-delay: 0.15s;
}
.content-section-image-dot3 {
  top: 57%;
  -webkit-animation: float-alt-c 7s ease-in-out infinite;
          animation: float-alt-c 7s ease-in-out infinite;
  -webkit-animation-duration: 9s;
          animation-duration: 9s;
  -webkit-animation-delay: -6s;
          animation-delay: -6s;
}
.content-section-image-dot3 img {
  -webkit-transition-delay: 0.3s;
          transition-delay: 0.3s;
}
.content-section-image-dot4 {
  top: 83%;
  -webkit-animation: float-alt-c 7s ease-in-out infinite;
          animation: float-alt-c 7s ease-in-out infinite;
  -webkit-animation-duration: 8s;
          animation-duration: 8s;
  -webkit-animation-delay: -2s;
          animation-delay: -2s;
}
.content-section-image-dot4 img {
  -webkit-transition-delay: 0.45s;
          transition-delay: 0.45s;
}

/* ------------------------------------------------------------- */
.content-section-image-pop-wrap {
  position: absolute;
  /* top: 40%; */
  top: 32%;
  right: 10%;
  width: 50%;
}
.content-section-image-pop-wrap > * {
  margin-bottom: 1%;
  -webkit-animation: float-translate 8s ease-in-out infinite;
          animation: float-translate 8s ease-in-out infinite;
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
  opacity: 0;
  -webkit-transition: opacity 0.45s cubic-bezier(0.34, 1.56, 0.64, 1),
    -webkit-transform 0.45s cubic-bezier(0.34, 1.56, 0.64, 1);
  transition: opacity 0.45s cubic-bezier(0.34, 1.56, 0.64, 1),
    -webkit-transform 0.45s cubic-bezier(0.34, 1.56, 0.64, 1);
  transition: opacity 0.45s cubic-bezier(0.34, 1.56, 0.64, 1),
    transform 0.45s cubic-bezier(0.34, 1.56, 0.64, 1);
  transition: opacity 0.45s cubic-bezier(0.34, 1.56, 0.64, 1),
    transform 0.45s cubic-bezier(0.34, 1.56, 0.64, 1),
    -webkit-transform 0.45s cubic-bezier(0.34, 1.56, 0.64, 1);
  -webkit-transform: translateY(10px);
          transform: translateY(10px);
}
.content-section-image-pop-wrap > *:first-child {
  -webkit-transition-delay: 0.15s;
          transition-delay: 0.15s;
}
.content-section-image-pop-wrap > *:nth-child(2) {
  -webkit-transition-delay: 0.23s;
          transition-delay: 0.23s;
}
.content-section-image-pop-wrap > *:nth-child(3) {
  -webkit-transition-delay: 0.3s;
          transition-delay: 0.3s;
}
.content-section-image-wrap-phoButton {
  position: absolute;
  top: 17.9%;
  left: 48.9%;
  height: 6%;
  width: 11%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 1.4em; */
  margin: 0;
  border: 1px solid #039be5;
  /* border: 1px solid #9cc3f8; */
  /* padding: 6px 12px; */
  /* background: #E5F1FA; */
  color: #fff;
  font-weight: 500;
  font-size: 0.8rem;
  border-radius: 5px;
  cursor: ;
}
.content-section-image-wrap-phoButton svg {
  height: 54%;
  margin-top: 1%;
}

.content-section-image-pop-wrap-circle {
  top: -8%;
  right: 14%;
  /* width: 48px;
  height: 48px; */
  width: 14.8%;
  height: 19.2%;
  background: #039be5;
  -webkit-animation: float-alt-c 8s ease-in-out infinite;
          animation: float-alt-c 8s ease-in-out infinite;
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
  -webkit-transition-delay: 0.6s;
          transition-delay: 0.6s;
  position: absolute;
  border-radius: 100%;
  box-shadow: 0 2.74416px 2.74416px rgba(0, 0, 0, 0.0274351),
    0 5.48831px 5.48831px rgba(0, 0, 0, 0.0400741),
    0 13.7208px 10.9766px rgba(0, 0, 0, 0.0499982),
    0 20.5812px 20.5812px rgba(0, 0, 0, 0.0596004),
    0 41.1623px 41.1623px rgba(0, 0, 0, 0.0709366),
    0 96.0454px 89.1851px rgba(0, 0, 0, 0.09);
}
.content-section-image-pop-wrap-circle svg {
  height: 70%;
}

.content-section-anim-active .content-section-image-middle,
.content-section-anim-active .content-section-image-dot img,
.content-section-anim-active .content-section-image-middle-bottom,
.content-section-anim-active .content-section-image-pop-wrap > * {
  -webkit-transform: scale(1) translateX(0);
          transform: scale(1) translateX(0);
  opacity: 1;
}

@-webkit-keyframes float-alt-c {
  0% {
    /* box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6); */
    -webkit-transform: translatey(0px) rotate(-8deg);
            transform: translatey(0px) rotate(-8deg);
  }
  50% {
    /* box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2); */
    -webkit-transform: translatey(-8px) rotate(8deg);
            transform: translatey(-8px) rotate(8deg);
  }

  100% {
    /* box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6); */
    -webkit-transform: translatey(0px) rotate(-8deg);
            transform: translatey(0px) rotate(-8deg);
  }
}

@keyframes float-alt-c {
  0% {
    /* box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6); */
    -webkit-transform: translatey(0px) rotate(-8deg);
            transform: translatey(0px) rotate(-8deg);
  }
  50% {
    /* box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2); */
    -webkit-transform: translatey(-8px) rotate(8deg);
            transform: translatey(-8px) rotate(8deg);
  }

  100% {
    /* box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6); */
    -webkit-transform: translatey(0px) rotate(-8deg);
            transform: translatey(0px) rotate(-8deg);
  }
}
.content-sec-fade-anim {
  opacity: 0;
  -webkit-transition: opacity 0.5s cubic-bezier(0.34, 1.56, 0.64, 1),
    -webkit-transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
  transition: opacity 0.5s cubic-bezier(0.34, 1.56, 0.64, 1),
    -webkit-transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
  transition: opacity 0.5s cubic-bezier(0.34, 1.56, 0.64, 1),
    transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
  transition: opacity 0.5s cubic-bezier(0.34, 1.56, 0.64, 1),
    transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1),
    -webkit-transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
  -webkit-transform: translateY(15px);
          transform: translateY(15px);
}
.content-sec-fade-anim-pop {
  opacity: 0;
  -webkit-transition: opacity 0.5s cubic-bezier(0.34, 1.56, 0.64, 1) 0.2s,
    -webkit-transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1) 0.2s;
  transition: opacity 0.5s cubic-bezier(0.34, 1.56, 0.64, 1) 0.2s,
    -webkit-transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1) 0.2s;
  transition: opacity 0.5s cubic-bezier(0.34, 1.56, 0.64, 1) 0.2s,
    transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1) 0.2s;
  transition: opacity 0.5s cubic-bezier(0.34, 1.56, 0.64, 1) 0.2s,
    transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1) 0.2s,
    -webkit-transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1) 0.2s;
  -webkit-transform: translateY(15px);
          transform: translateY(15px);
}
.content-section-text-wrap {
  width: 100%;
  text-align: center;
}
.content-section-text-wrap h2 {
  font-size: 2rem;
  font-weight: 700;
  margin: 0 auto 16px;
}
.content-section-text-wrap p {
  font-size: 0.875rem;
  color: #757575;

  line-height: 1.71429;
  font-weight: 400;
  max-width: 600px;
  margin-bottom: 32px;
}
.content-section-text-wrap a {
  padding: 10.5px 16px;
  box-sizing: border-box;
}

.content-section-anim-active .content-sec-fade-anim,
.content-section-anim-active .content-sec-fade-anim-pop {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

@media only screen and (min-width: 768px) {
  .content-section-cont-wrap {
    padding: 2rem 0;
    flex-direction: row;
  }
  .content-section-image-wrap {
    width: 50%;
    margin: 0 32px 0 0;
  }
  .content-section-text-wrap {
    width: 50%;
    text-align: left;
  }
  .content-section-image-wrap-2 {
    margin: 0 0 0 2rem;
  }
}
@media only screen and (min-width: 960px) {
  .content-section-text-wrap h2 {
    font-size: 2.75rem;
  }
  .content-section-text-wrap p {
    font-size: 1rem;
  }
  .content-section-image-wrap-2 {
    margin: 0 0 0 4rem;
  }

  .content-section-image-wrap-3 {
    margin: 0 4rem 0 0;
    /* height: 120%; */
  }
}
@media only screen and (min-width: 1200px) {
  .content-section-image-wrap {
    width: 60%;
  }
  .content-section-image-wrap-2 {
    width: 50%;
  }
  .content-section-text-wrap {
    width: 40%;
  }
}

.Back-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  z-index: -1;
  overflow: hidden;
}
.Back-wrap {
  position: relative;
  min-height: 100%;
}

.Back-wrap .back-blob-1 {
  position: absolute;
  top: -3640px;
  left: -650px;
}

.back-blob-2 {
  position: absolute;
  bottom: -200px;
  height: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.Back-wrap .back-blob-3 {
  position: absolute;
  top: 3500px;
  -webkit-transform: translateX(-40%) rotate(-5deg) scaleX(-1);
          transform: translateX(-40%) rotate(-5deg) scaleX(-1);
}

.Back-wrap .back-blob-4 {
  position: absolute;
  /* top: -3140px;
  left: -1050px; */
  top: -3150px;
  left: -1000px;
  -webkit-transform: rotate(-10deg);
          transform: rotate(-10deg);
}
/* }
.back-blob-2 {
  position: absolute;
  bottom: -200px;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
} */

.back-blob-forum {
  position: absolute;
  background: #f4f6f8;
  height: 100%;
  width: 100%;
}

.back-blob-auth {
  position: absolute;
  top: -50%;
  left: 50%;
  -webkit-transform: rotate(-20deg) translateX(-40%);
          transform: rotate(-20deg) translateX(-40%);

  /* bottom: auto;
  top: -90%;
  height: 50%;
  transform: translateX(-60%) rotate(5deg); */
}
.back-blob-auth path {
  fill: #f4f6f8;
}

@media only screen and (min-width: 768px) {
  .Back-wrap .back-blob-1 {
    top: -3578px;
    left: -400px;
  }
}

@media only screen and (min-width: 1200px) {
  .Back-wrap .back-blob-1 {
    top: -3520px;
    left: -650px;
    -webkit-transform: rotate(3deg);
            transform: rotate(3deg);
  }
}

/* .Back-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  z-index: -1;
  overflow: hidden;
}
.Back-wrap {
  position: relative;
  min-height: 100%;
}

.Back-wrap .back-blob-1 {
  position: absolute;
  top: -3640px;
  left: -650px;
}

.Back-wrap .back-blob-2 {
  position: absolute;
  top: -200px;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
}
.back-blob-forum {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100%;
  width: 100%;
  background: #f4f6f8;
}

@media only screen and (min-width: 768px) {
  .Back-wrap .back-blob-1 {
    top: -3578px;
    left: -400px;
  }
}

@media only screen and (min-width: 1200px) {
  .Back-wrap .back-blob-1 {
    top: -3520px;
    left: -650px;
    transform: rotate(3deg);
  }
} */

.splash-pop-container {
  margin-bottom: -16px;
}
.splash-pop-wrap {
  padding: 8px 16px;
  width: 220px;
  background: #fff;
  box-shadow: 0px 4px 24px rgba(52, 168, 254, 0.25);
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-weight: 400;
  border-radius: 10px;
}

.anim-up-splash {
  opacity: 0;
  -webkit-animation: reveal-up-splash 0.75s cubic-bezier(0, 1, 0.1, 1) normal forwards,
    slide-top-splash 1.5s ease-in-out 0.75s infinite alternate both;
          animation: reveal-up-splash 0.75s cubic-bezier(0, 1, 0.1, 1) normal forwards,
    slide-top-splash 1.5s ease-in-out 0.75s infinite alternate both;
}
.hover-splash {
}

@-webkit-keyframes reveal-up-splash {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes reveal-up-splash {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@-webkit-keyframes slide-top-splash {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-4px);
            transform: translateY(-4px);
  }
}
@keyframes slide-top-splash {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-4px);
            transform: translateY(-4px);
  }
}

.anim-grow-splash {
  opacity: 0;
  -webkit-animation: grow-splash 0.75s cubic-bezier(0, 1, 0.1, 1) 1 normal forwards,
    grow-top-splash 1.5s ease-in-out 0.75s infinite alternate both;
          animation: grow-splash 0.75s cubic-bezier(0, 1, 0.1, 1) 1 normal forwards,
    grow-top-splash 1.5s ease-in-out 0.75s infinite alternate both;
}

@-webkit-keyframes grow-splash {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes grow-splash {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes grow-top-splash {
  0% {
    opacity: 0.8;
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  100% {
    opacity: 0.5;
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
  }
}
@keyframes grow-top-splash {
  0% {
    opacity: 0.8;
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  100% {
    opacity: 0.5;
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
  }
}

.splash-pop-wrap > * {
  width: 100%;
}

.splash-pop-wrap img {
  border-radius: 5px;
  display: block;
  margin: 4px 0;
  width: 100%;
}
.splash-pop-header {
  height: 24px;
  font-size: 0.875rem;
  font-weight: 600;
}
.splash-pop-dots > * {
  height: 12px;
  width: 12px;
  border-radius: 100%;
  border: 1px solid #323a42;
}
.splash-pop-dots :not(:first-child) {
  margin-left: 8px;
}
.splash-pop-dots > * {
  height: 12px;
  width: 12px;
  border-radius: 100%;
  margin-left: 8px;
  border: 1px solid #323a42;
}
.splash-pop-dots .splash-pop-dots-filled {
  background: #323a42;
}
.slash-pop-line {
  width: 50%;
  border-top: 1px solid #cfd8dc;
  margin: 2px 0 2px 50%;
}

.splash-pop-triangle {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 10px solid #fff;
  margin: auto;
}
.splash-pop-shadow {
  width: 100%;
  height: 37px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(86, 83, 83, 0.39) 0%,
    rgba(68, 64, 64, 0) 100%
  );
}
/* ------------------------------------------------------------ */
.map-icon-container {
  display: none;
}

@media only screen and (min-width: 960px) {
  .splash-pop-wrap {
    padding: 16px;
    width: 240px;
    font-size: 0.875rem;
    line-height: 1.5rem;
  }
  .splash-pop-header {
    font-size: 1rem;
  }
  .splash-pop-wrap img {
    margin: 8px 0;
  }
  /* ------------------------------------------------------------ */
  .map-icon-container {
    opacity: 0;
    display: flex;
    position: absolute;
    /* border: 1px solid red; */
  }
  @-webkit-keyframes map-icon-rev {
    0% {
      opacity: 0;
      -webkit-transform: translateY(-500px);
              transform: translateY(-500px);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }
  @keyframes map-icon-rev {
    0% {
      opacity: 0;
      -webkit-transform: translateY(-500px);
              transform: translateY(-500px);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  .map-icon-dots-wrap {
    margin-bottom: 4px;
    /* opacity: 0; */
  }
  .map-icon-dots-wrap > * {
    opacity: 0;
  }
  @-webkit-keyframes map-icon-dots-rev {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes map-icon-dots-rev {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .map-icon-dots-wrap div {
    height: 8px;
    width: 8px;
    border-radius: 100%;
    border: 1px solid #323a42;
  }
  .map-icon-dots-wrap :not(:first-child) {
    margin-left: 2px;
  }
  .map-icon-dots-active {
    background: #323a42;
  }

  .map-icon-container .splash-pop-shadow {
    height: 16px;
    opacity: 0.4;
  }
}

.home-background {
  -webkit-transition: 1s ease;
  transition: 1s ease;
  width: 100%;
  overflow: hidden;
}
.home-background-grey {
  background: #f4f6f8;
}

/* ---------------------------------------------------------------- */

.splash {
  width: 100%;
  height: Calc(100vh - 48px);
  max-height: Calc(100vw * 1.778);
  text-align: center;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}

.splash-cont-wrap {
  position: relative;
  z-index: 5;
  display: grid;
  grid-template-rows: auto 1fr;
  padding: 16px 0;

  width: 100%;
  height: 100%;
}
.splash-white-fade {
  position: absolute;
  z-index: 6;
  background: linear-gradient(
    179.08deg,
    #ffffff 52.12%,
    rgba(255, 255, 255, 0) 72.67%
  );
  height: 60%;
  width: 100%;
}
.splash-popupWrap,
.splash-textWrap {
  position: relative;
  z-index: 7;
}
.splash-popupWrap {
  height: 100%;
}

.splash-cont-wrap h1 {
  font-size: 2rem;
  margin: 0 16px 8px;
}
.splash-cont-wrap h2 {
  font-size: 0.875rem;
  line-height: 1.71429;
  font-weight: 400;
  margin: 0 16px 16px;
  max-width: 550px;
  color: #757575;
}
.splash-cont-wrap .button {
  height: 36px;
  width: 120px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.splash-cont-wrap .button:first-child {
  margin-right: 16px;
}
.splash-cont-wrap .button div {
  margin-top: -4px;
}
.Flipit-splash {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  min-width: 100%;
  z-index: 2;
}
.splash span > *:first-child {
  margin-right: 24px;
}
.splash-map-icon-wrap {
  position: absolute;
  z-index: -1;
  top: 0;

  width: 100%;
  height: 100%;
  /* border: 1px solid blue; */
}

/* ---------------------------------------------------------------- */

.info-card {
  position: relative;
  z-index: 6;

  margin: -32px 16px 0;
  width: auto;
  padding: 24px 16px;
  /* min-height:256px; */
}
.info-card-header {
  display: flex;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 32px;
  white-space: nowrap;
  font-weight: 500;
}
.info-card-header div {
  color: #757575;
  border-bottom: 1px solid #cfd8dc;
  width: 100%;
  height: 32px;
}

.info-card-content {
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(1, 1fr);
}
/* .info-card-content > *:first-child {
  transition: transform 0.45s cubic-bezier(0.25, 1, 0.5, 1),
    opacity 0.45s cubic-bezier(0.25, 1, 0.5, 1);
  transform: translateY(50px) scale(0);
}
.info-card-content > *:first-child {
  transition: transform 0.45s cubic-bezier(0.25, 1, 0.5, 1),
    opacity 0.45s cubic-bezier(0.25, 1, 0.5, 1);
  transform: translateY(50px) scale(0);
}
.info-card-content > *:first-child {
  transition: transform 0.45s cubic-bezier(0.25, 1, 0.5, 1),
    opacity 0.45s cubic-bezier(0.25, 1, 0.5, 1);
  transform: translateY(50px) scale(0);
} */

.info-card .centered {
  margin-bottom: 16px;
  justify-content: center;
}
.info-card-icon-wrap {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background: #d0eefd;
  /* background: #34a8fe; */
}
.info-card h2 {
  font-weight: 600;
  margin-left: 16px;
}
.info-card p {
  color: #757575;
  max-width: 400px;
  margin: 0 auto 16px;
}
.info-card-head {
}

/* .home-content-wrapper {
  overflow-x: hidden;
} */

.home-PriceCards-header {
  text-align: center;
  max-width: 600px;
  margin-bottom: 76px;
}

.home-PriceCard-container {
  display: grid;
}

.home-price-link {
  /* background: rgba(236, 239, 241, 0.6); */
  background: #f4f6f8;
  border: 1px solid #34a8fe;
  box-sizing: border-box;
  border-radius: 5px;
  /* height: 64px; */
  width: Calc(100% - 32px);
  max-width: 750px;
  margin: 60px auto 0;
  padding: 20px 24px;

  font-size: 1.5rem;
  font-weight: 600;
  text-decoration: none;
  color: #323a42;
}
.home-price-link p {
  margin: 0;
  font-weight: 400;
}
.price-link-text-wrap {
  margin: 0 24px;
}

.mobile-app-link-conatiner {
  flex-wrap: wrap;
}
.mobile-app-link-conatiner > * {
  margin-bottom: 16px;
  -webkit-transition: 0.1s ease-in-out;
  transition: 0.1s ease-in-out;
}
.mobile-app-link-conatiner > *:hover {
  opacity: 0.7;
}
.mobile-app-link-conatiner > *:first-child {
  margin-right: 16px;
}

@media only screen and (max-width: 768px) {
  .home-Beta-card {
    width: Calc(100% - 32px);
  }
}

@media only screen and (min-width: 768px) {
  .splash {
    height: Calc(100vh - 48px);
    max-height: Calc(100vw * 0.667);
    /* max-height: Calc((100vw * .667) - 48px);  */
  }
  .splash-cont-wrap {
    padding: 32px 0;
  }
  .splash-cont-wrap h1 {
    margin: 32px 0 16px;
  }
  .splash-cont-wrap h2 {
    font-size: 1rem;
    line-height: 1.75rem;
    margin: 0 auto 32px;
  }
  .info-card {
    margin: -32px 0 0;
    width: 100%;
    text-align: left;
    padding: 32px 32px 48px;
  }
  .info-card-content {
    grid-template-columns: repeat(3, 1fr);
  }

  .info-card .centered {
    justify-content: flex-start;
  }

  .home-PriceCard-container {
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    grid-gap: 24px;
  }
  .home-price-link {
    width: 100%;
    max-width: 750px;
    margin: 60px auto 0;
    padding: 20px 24px;
  }
}

/* Small Desktop */
@media only screen and (min-width: 960px) {
  .info-card {
    padding: 48px 48px 56px;
  }
  .info-card-content {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 32px;
  }
  .splash-cont-wrap h1 {
    font-size: 2.75rem;
    margin: 32px 0 16px;
  }
  .splash-cont-wrap h2 {
    font-size: 1rem;
    line-height: 1.75rem;
  }
}

/* Desktop */
@media only screen and (min-width: 1200px) {
  .info-card {
    align-items: center;
    padding: 48px 56px 56px;
    min-height: 256px;
  }
  .info-card-content {
    grid-gap: 56px;
  }
}

/* .forum-content {
  min-height: 100%;
}
.forum-wrap {
  padding: 16px 16px 80px;
  margin: 0 auto;
  box-sizing: border-box;
}
.forum-grid {
  display: grid;
  grid-template-columns: 1fr;
}
.forum-more-legal {
  margin: -20px auto;
  font-size: 0.75rem;
  color: rgba(96, 103, 110, 0.5);
  justify-content: center;
}
.forum-more-legal a {
  margin: 8px;
  cursor: pointer;
  text-align: center;
}
.forum-more-legal a:hover {
  color: #34a8fe;
}

@media only screen and (min-width: 768px) {
  .forum-wrap {
    padding: 0 0 80px;
    margin-top: 24px;
  }
  .forum-grid {
    grid-template-columns: 1fr 180px;
    grid-gap: 24px;
  }
  .more-container {
    grid-column: 2;
    flex-direction: column;
  }
  .more-container-wrap {
    position: fixed;
    width: 180px;
    z-index: 101;
  }

  .more-container-wrap > * {
    margin-bottom: 24px;
  }
  .more-container-wrap h2 {
    font-size: 0.875rem;
    text-transform: uppercase;
    margin-bottom: 8px;
  }
  .more-subcat {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 20px;
    color: rgba(96, 103, 110, 0.5);
    padding-left: 16px;
    text-transform: capitalize;
  }
  .forum-more-container {
    background: #ffffff;
    border: 1px solid #eceff1;
    border-radius: 5px;
    flex-direction: column;
    padding: 8px;
  }
  .forum-more-container h2 {
    width: 100%;
    border-bottom: 1px solid #eceff1;
    padding: 8px;
    box-sizing: border-box;
  }
  .forum-more-container > * :last-child {
    margin-bottom: 8px;
  }
}

@media only screen and (min-width: 960px) {
  .forum-grid {
    grid-template-columns: 1fr 256px;
  }
  .more-container-wrap {
    width: 256px;
  }
} */

.post-card-container {
  background: #fff !important;
  border-radius: 10px;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  margin: 0 0 8px -8px;
  width: Calc(100% + 16px);

  -webkit-transition: 0.25s ease;

  transition: 0.25s ease;
  cursor: pointer;
  -webkit-animation: ;
          animation: ;
}
.post-card-container:hover {
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.09);
}

.post-delete-animation {
  -webkit-animation: delete-card 2s cubic-bezier(0, 1, 0.1, 1) forwards;
          animation: delete-card 2s cubic-bezier(0, 1, 0.1, 1) forwards;
}

@-webkit-keyframes delete-card {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
}

@keyframes delete-card {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
}

.post-card-header,
.post-card-footer,
.post-card-content {
  min-height: 48px;
  box-sizing: border-box;
  padding: 12px;
}

.post-card-header > *:first-child {
  flex-wrap: wrap;
}

.post-card-header-tag {
  height: 24px;
  padding: 0 6px;
  margin-right: 4px;
  box-sizing: border-box;
  border-radius: 5px;
  color: #34a8fe;
  font-size: 0.75rem;

  cursor: pointer;
  -webkit-transition: 0.15s ease;
  transition: 0.15s ease;
}

.post-doc-type {
  font-weight: 600;
  background: rgba(52, 168, 254, 0.1);
}
.post-doc-type:hover {
  background: rgba(52, 168, 254, 0.3);
}
.post-top-type {
  border: 1px solid #34a8fe;
}
.post-top-type:hover {
  background: rgba(52, 168, 254, 0.1);
}

/* CONTENT----------------------------------------------- */
.post-card-content-image {
  width: Calc(100% + 24px);
  margin: 0 0 -12px -12px;
  object-fit: cover;
  overflow: hidden;
  max-height: 440px;
  box-sizing: border-box;
  display: block;
}

.post-card-content h3 {
  font-weight: 600;
  font-size: 18px;
  display: flex;
  align-items: center;
  color: #323a42;
}
.post-card-content h6 {
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  letter-spacing: 0.75px;
  color: #b1b4c7;
  margin: 6px 0 12px;
}
.post-card-content p {
  color: #757575;
}

/* PREVIEW----------------------------------------------- */

.forum-card-link-prev-wrap {
  width: 100%;
  padding: 12px;
  margin: 0 0 -0;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #cfd8dc;
  cursor: pointer;
  -webkit-transition: 0.15s ease-in;
  transition: 0.15s ease-in;
}
.LinkPreview-image-container {
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  max-width: 120px;
  min-width: 80px;
  height: 88px;
  margin-right: 16px;
  border-radius: 5px;
}

.forum-card-link-prev-text-wrap {
  width: 100%;
  text-align: left;
}
.forum-card-link-prev-wrap h4 {
  font-size: 0.75rem;
  font-weight: 500;
  word-break: break-all;
}
.forum-card-link-prev-wrap p {
  font-size: 0.75rem;
  margin: 0;
  word-break: break-all;
}

.forum-card-link-prev-wrap:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.1);
}

.forum-card-link-prev-wrap img {
  min-width: 100%;
  height: 100%;
  background: #f4f6f8;
  object-fit: cover;
}
.LinkPreview-url-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  background: rgba(0, 0, 0, 0.65);
}

/* DROPDOWN----------------------------------------------- */
.more-dots-drop {
  position: relative;
  color: #939eab;
  /* font-size: 0.875rem; */
  font-weight: 400;
}
.more-dots-drop-dropdown {
  cursor: default;
  position: relative;
  z-index: 6;
}
.more-dots-drop-item {
  padding: 4px 24px;
  -webkit-transition: 0.15s ease;
  transition: 0.15s ease;
  cursor: pointer;
}

.more-dots-drop-item svg {
  margin-right: 16px;
}
.more-dots-drop .more-dots-drop-item svg path {
  fill: #939eab;
}

.more-dots-drop-item:hover,
.more-dots-drop-item:hover svg path {
  /* background: #f4f6f8; */

  background: rgba(52, 168, 254, 0.1);
  color: #34a8fe;
  fill: #34a8fe;
}

.more-dots-drop-dropdown {
  position: absolute;
  top: 32px;
  right: -4px;
  padding: 8px 0 12px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.12), 0px 3px 6px rgba(0, 0, 0, 0.09);
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.1); */
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.1); */
}

/* OPTIONSMODAL---------------------------------------- */

.options-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 120;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  cursor: auto;
}

.popup-container {
  padding: 24px;
  margin: 24px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.14);
}
.pop-container-text {
  margin: 0 0 24px;
  max-width: 264px;
  text-align: center;
  font-size: 1rem;
  line-height: 1.6rem;
  font-weight: 500;
}

.popup-container button {
  min-width: 120px;
}

/* SAVEPOPUP--------------------------------------------- */
.pop-save-wrap {
  width: 100%;
  max-width: 540px;
  position: relative;
  box-sizing: border-box;
}

.pop-save-wrap-divlin {
  border-bottom: 1px solid #cfd8dc;
  margin: 16px -24px;
}

.pop-save-wrap input {
  border: 1px solid #cfd8dc;
  border-radius: 5px;
  box-shadow: none;
  background: none;
  width: 100%;
  margin-top: 16px;
  box-sizing: border-box;
  height: 40px;
}

.save-pop-collection-wrap {
  margin: 16px 0 16px;
  border: 1px solid #cfd8dc;
  border-radius: 5px;
  width: 100%;
  height: 200px;
}

/* FOOTER--------------------------------------------- */
.post-card-footer {
  background: #f9fafb;
}
.post-card-footer-like-wrap button,
.post-card-footer-profile-wrap,
.post-card-footer-comment-wrap,
.pop-close-button,
.more-dots-button {
  padding: 6px;
  margin: -6px;
  border-radius: 5px;
  box-sizing: border-box;
  -webkit-transition: 0.1s ease;
  transition: 0.1s ease;
  cursor: pointer;
}
.post-card-footer-like-wrap button:hover,
.post-card-footer-profile-wrap:hover,
.post-card-footer-comment-wrap:hover,
.pop-close-button:hover,
.more-dots-button:hover {
  background: rgba(52, 168, 254, 0.1);
}

.post-card-footer-profile-wrap img {
  height: 24px;
  width: 24px;
  object-fit: cover;
  border-radius: 5px;
  overflow: hidden;
}
.post-card-footer-profile-wrap p {
  margin: 0 0 0 8px;
}

.post-card-footer-comment-wrap svg {
  margin-right: 12px;
}

.post-card-footer-like-wrap button {
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
}

.post-card-footer-comment-wrap svg path,
.post-card-footer-like-wrap button svg path,
.pop-close-button:hover svg path {
  -webkit-transition: fill 0.1s ease;
  transition: fill 0.1s ease;
}
.post-card-footer-like-wrap button:hover svg path,
.post-card-footer-comment-wrap:hover p,
.post-card-footer-comment-wrap:hover svg path,
.more-dots-button:hover .more-dots-icon path,
.pop-close-button:hover svg path {
  color: #34a8fe;
  fill: #34a8fe;
}
.post-card-footer-like-wrap p,
.post-card-footer-comment-wrap p {
  margin: 0 12px;
  font-weight: 500;
  color: #b1b4c7;
}

.active-like-button svg path,
.post-card-footer-like-wrap .active-like-text p {
  fill: #039be5;
  color: #039be5;
}

@media only screen and (min-width: 768px) {
  .post-card-container {
    margin: 0 0 8px 0;
    width: 100%;
  }
  .forum-card-link-prev-wrap h4 {
    font-size: 1rem;
  }
  .forum-card-link-prev-wrap p {
    font-size: 0.875rem;
  }
  .LinkPreview-image-container {
    width: 180px;
    min-width: 180px;
    height: 120px;
  }
  .post-card-header-tag {
    padding: 0 12px;
    margin-right: 8px;
  }
}

.header-banner-container {
  z-index: 98;
  background: #28323c;
}

.banner-tabs {
  height: 48px;
  position: relative;
  padding: 0 16px;
}

.sticky-section {
}

@media only screen and (min-width: 768px) {
  .banner-tabs {
    padding: 0;
  }
  .banenr-searchbar-container {
    width: 180px;
    margin-left: 8px;
  }
}

@media only screen and (min-width: 960px) {
  .banenr-searchbar-container {
    width: 256px;
  }
}

.banner-header {
  margin: 0 16px;
}
.banner-header a {
  color: #fff;
  font-size: 1.25rem;
  font-weight: 400;
  margin: 16px 0;
}

/* BANNER STUFF_______________________________ */

.banner-behavior-wrap {
  width: 100%;
  top: 0;
  /* position: relative; */
  position: fixed;
  top: 0;
  margin-top: 48px;
  box-sizing: content-box;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
  z-index: 80;
  /* z-index: 100; */
}

.forum-content {
  position: relative;
  box-sizing: content-box;
  /* border: 1px solid red; */
}

/* ____________________________________________ */
@media only screen and (min-width: 768px) {
  .banner-header {
    margin: 0;
  }
  .banner-header a {
    height: 34px;
    margin: 20px 0 2px;
  }
}

.dropdown-wrap {
  color: #fff;
  font-weight: 500;
  font-size: 0.875rem;
  padding: 4px 8px 4px 16px;
  cursor: pointer;
  -webkit-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
  border-radius: 5px;
}

.dropdown-wrap:hover {
  background: rgba(255, 255, 255, 0.1);
}
.dropdown-wrap:hover > *:last-child {
  opacity: 1;
}

.dropdown-wrap > *:last-child {
  opacity: 0.5;
  margin-left: 8px;
  -webkit-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
}

.banner-tabs-left {
  position: relative;
  height: 48px;
  display: flex;
  align-items: flex-start;
  width: Calc(100% + 16px);
  margin-left: -16px;

  overflow: hidden;
}

.banner-tabs-wrap {
  overflow-x: auto;
}

.banner-tab {
  height: 48px;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1rem;
  color: rgba(255, 255, 255, 0.6);
  padding: 8px 16px;
  box-sizing: border-box;
  -webkit-transition: 0.15s ease-out;
  transition: 0.15s ease-out;
}
.banner-tabs a:hover {
  color: rgba(255, 255, 255, 1);
}
.active-banner-tab {
  color: #fff;
}

.tab-seleciton-lin {
  position: absolute;
  bottom: 0;
  height: 3px;
  border-radius: 3px 3px 0 0;
  background: #fff;

  margin: 0 16px;
  width: 20px;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

/* @media only screen and (min-width: 768px) {
  .banner-tabs-left {
    margin-left: -16px;
  }
} */

.searchbar-wrap {
  width: 32px;

  display: flex;
  align-items: center;
}

.serachbar-icon-wrap {
  position: relative;
  height: 24px;
  width: 0;
  opacity: 1;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  pointer-events: none;
}
.serachbar-icon-wrap svg {
  height: 24px;
  width: 24px;
  position: absolute;
  right: -28px;
  pointer-events: none;
}

.searchbar-wrap input {
  width: 32px;
  margin: 0;
  box-sizing: border-box;
  height: 32px;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 400;
  z-index: 85;

  color: #fff;
  outline: none;
  border: none;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  background: none;
  padding-left: 20px;
}
.searchbar-wrap input:hover {
  background: rgba(255, 255, 255, 0.1);
}

.searchbar-wrap input::-webkit-input-placeholder {
  font-size: 0.875rem;
  font-weight: 500;
  color: #9ea3a7;
}

.searchbar-wrap input:-ms-input-placeholder {
  font-size: 0.875rem;
  font-weight: 500;
  color: #9ea3a7;
}

.searchbar-wrap input::-ms-input-placeholder {
  font-size: 0.875rem;
  font-weight: 500;
  color: #9ea3a7;
}

.searchbar-wrap input::placeholder {
  font-size: 0.875rem;
  font-weight: 500;
  color: #9ea3a7;
}

.searchbar-wrap input:focus {
  position: absolute;
  width: Calc(100% - 32px);
  right: 16px;
  top: 8px;
  height: 32px;
  border: none;
  outline: none;
  color: #323a42;
  background: #fff;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.6),
    0 1px 3px 1px rgba(60, 64, 67, 0.3);
  padding: 0 16px;
}

.searchbar-wrap input:focus ~ .clear-icon-wrap {
  display: flex;
}
.searchbar-wrap input:focus ! .serachbar-icon-wrap {
  opacity: 0;
}

.clear-icon-wrap {
  z-index: 88;
  position: absolute;
  right: 16px;
  border-radius: 5px;
  padding: 4px 8px;
  display: none;
  cursor: pointer;
}
.clear-icon-svg {
  fill: #9ea3a7;
  -webkit-transition: 0.15s ease-in-out;
  transition: 0.15s ease-in-out;
}
.clear-icon-wrap:hover .clear-icon-svg {
  fill: #28323c;
}

@media only screen and (min-width: 768px) {
  .searchbar-wrap {
    width: 100%;
  }
  .serachbar-icon-wrap svg {
    right: -32px;
  }

  .searchbar-wrap input {
    width: 100%;
    padding-left: 40px;
    cursor: text;
    background: rgba(255, 255, 255, 0.1);
  }

  .searchbar-wrap input:focus {
    width: 100%;
    right: 0;
  }

  .clear-icon-wrap {
    right: 0;
  }
}

/* User Banner -------------------------------------------------------*/
.banner-header-user {
  padding: 16px 16px;
  box-sizing: border-box;
  flex-wrap: wrap;
  flex-direction: column;
}

.banner-profile-pic-container {
  position: relative;
}

.banner-profile-pic-wrap {
  border: 2px solid white;
  border-radius: 5px;
  box-sizing: border-box;
  width: 64px;
  height: 64px;
  position: relative;
  background: rgba(255, 255, 255, 0.1);
  overflow: hidden;
}
.banner-profile-pic-wrap img {
  width: 100%;
  margin: 0;
  display: block;
  /* position: absolute;
  top: 0;
  left: 0; */
  z-index: 82;
  object-fit: cover;
}
.banner-profile-pic-wrap svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 80;
}

.prof-badg-cont-adj {
  bottom: -8px;
  right: -8px;
}

.banner-user-info {
  color: #fff;
  margin-left: 16px;
}
.banner-user-info p {
  /* font-size: 0.75rem; */
  /* font-weight: 300; */
  margin: 0;
  opacity: 0.5;
}

.banner-profile-button-wrap {
  justify-content: center;
  display: flex;
  width: 100%;
}

.banner-tabs-userbuttons {
  padding: 0 16px;
}

.banner-profile-button-wrap > *:first-child {
  margin-right: 16px;
}

.banner-profile-button-wrap-noessential {
  display: none;
}

.banner-numbs-container {
  padding: 16px 24px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.1);
  margin-top: 16px;
}
.banner-numbs-wrap {
  color: #ffffff;
  display: flex;
  align-items: center;
}
.banner-numbs-wrap > *:last-child {
  opacity: 0.5;
  font-size: 0.75rem;
  font-weight: 300;
  margin-left: 8px;
}
.banner-numbs-div {
  width: 0px;
  height: 24px;
  border: 0.5px solid #8093a2;
  margin: 0 16px;
}

@media only screen and (min-width: 560px) {
  .banner-header-user {
    /* padding: 16px 0; */
    flex-direction: row;
  }
  .banner-numbs-container {
    margin-top: 0;
  }
  .banner-numbs-wrap {
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .banner-numbs-wrap > *:last-child {
    opacity: 0.5;
    font-size: 0.75rem;
    font-weight: 300;
    margin-left: 0;
  }
  .banner-numbs-div {
    width: 0px;
    height: 33px;
    border: 0.5px solid #8093a2;
    margin: 0 16px;
  }
  .banner-profile-button-wrap {
    justify-content: flex-start;
  }
  .banner-profile-button-wrap-noessential {
    display: flex;
  }
}

@media only screen and (min-width: 768px) {
  .banner-header-user {
    padding: 16px 0;
  }
  .banner-tabs-userbuttons {
    padding: 0;
  }
}

.forum-content {
  min-height: 100%;
  position: relative;
  /* z-index: 50; */
  overflow-x: hidden;
}
.forum-wrap {
  padding: 16px 16px 80px;
  margin: 0 auto;
  box-sizing: border-box;
}
.forum-grid {
  display: grid;
  grid-template-columns: 1fr;
}

/* FILTER--------------------------------------------------------- */
.filter-container {
  display: none;
  -webkit-animation: slidein-left 0.75s cubic-bezier(0.25, 1, 0.5, 1) normal forwards;
          animation: slidein-left 0.75s cubic-bezier(0.25, 1, 0.5, 1) normal forwards;
  opacity: 0;
}

@-webkit-keyframes slidein-left {
  0% {
    opacity: 0;
    -webkit-transform: translatex(-50%);
            transform: translatex(-50%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes slidein-left {
  0% {
    opacity: 0;
    -webkit-transform: translatex(-50%);
            transform: translatex(-50%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
/* HEADER--------------------------------------------------------- */
.posts-section-header {
  height: 40px;
  margin-bottom: 12px;
}

.posts-section-header-button {
  height: 36px;
  padding: 0 6px;
  border-radius: 5px;
  color: #939eab;
  font-weight: 500;
  cursor: pointer;
  -webkit-transition: background 0.15s ease-out;
  transition: background 0.15s ease-out;
}

.posts-section-header-button-focus {
  height: 36px;
  padding: 0 16px;
  border-radius: 5px;
  color: #939eab;
  font-weight: 500;
  cursor: pointer;
  -webkit-transition: 0.1s ease;
  transition: 0.1s ease;
}
.posts-section-header-button:hover,
.posts-section-header-button-focus:hover {
  background: rgba(147, 158, 171, 0.1);
}
.posts-section-header-button-focus > *:first-child {
  margin-right: 12px;
}

/* MORE--------------------------------------------------------- */

.forum-more-legal {
  margin: -20px auto;
  font-size: 0.75rem;
  color: rgba(96, 103, 110, 0.5);
  justify-content: center;
}
.forum-more-legal a {
  margin: 8px;
  cursor: pointer;
  text-align: center;
}
.forum-more-legal a:hover {
  color: #34a8fe;
}

@media only screen and (min-width: 768px) {
  .forum-wrap {
    padding: 0 0 80px;
    margin-top: 24px;
  }
  .forum-grid {
    grid-template-columns: 1fr 256px;
    grid-gap: 24px;
  }

  /* HEADER--------------------------------------------------------- */
  .posts-section-header-button {
    padding: 0 16px;
  }
  .posts-section-header-button > *:first-child {
    margin-right: 12px;
  }

  /* POST--------------------------------------------------------- */
  .post-container {
    grid-column: 1;
  }
  /* MORE--------------------------------------------------------- */
  .more-container {
    grid-column: 2;
    flex-direction: column;
  }
}

@media only screen and (min-width: 960px) {
}
@media only screen and (min-width: 1200px) {
  .forum-grid {
    grid-template-columns: 210px 1fr 256px;
  }
  .post-filter-activation {
    display: none;
  }
  /* FILTER--------------------------------------------------------- */
  .filter-container {
    grid-column: 1;
    display: block;
  }

  /* POST--------------------------------------------------------- */
  .post-container {
    grid-column: 2;
  }

  /* MORE--------------------------------------------------------- */
  .more-container {
    grid-column: 3;
  }
}

.switch-comp-container {
  width: 36px;
  height: 36px;
}

.switch-comp-wrapper {
  height: 16px;
  width: 28px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  position: relative;
  /* justify-content: flex-start; */
  -webkit-transition: 0.15s ease-in-out;
  transition: 0.15s ease-in-out;
}

.switch-comp-dot {
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(76, 93, 110, 0.2),
    0px 0px 0px rgba(76, 93, 110, 0.2);
  width: 12px;
  height: 12px;

  border-radius: 100%;
  -webkit-transition: 0.15s ease-in-out;
  transition: 0.15s ease-in-out;
  margin: 2px;
  position: absolute;
  left: 0;
}

.active-switch .switch-comp-wrapper {
  background: #34a8fe;
  text-align: right;
  /* justify-content: flex-end; */
}
.active-switch .switch-comp-dot {
  left: 12px;
}

.plan-switch-container {
  text-align: left;
  cursor: pointer;
}
.plan-switch-container p {
  margin: 0 0 0 12px;
  -webkit-transition: 0.15s ease;
  transition: 0.15s ease;
}
.plan-switch-container:hover p {
  color: #fff;
  opacity: 1;
}

.plan-auth-button-wrap button {
  padding: 6px 32px;
  margin-left: 12px;
}

.plan-auth-button-wrap {
  flex-direction: column;
}
.plan-auth-button-wrap > *:first-child {
  margin-bottom: 24px;
}

@media only screen and (min-width: 360px) {
  .plan-auth-button-wrap {
    flex-direction: row;
    align-items: center;
    margin-bottom: 24px;
  }
  .plan-auth-button-wrap > *:first-child {
    margin-bottom: 0;
  }
}

.more-container-wrap {
  position: fixed;
  width: 256px;
  z-index: 99;
}
.forum-more-legal {
  margin: -8px auto;
  font-size: 0.75rem;
  color: rgba(96, 103, 110, 0.5);
  justify-content: center;
}
.forum-more-legal a {
  margin: 8px;
  cursor: pointer;
  text-align: center;
}
.forum-more-legal a:hover {
  color: #34a8fe;
}

@media only screen and (min-width: 768px) {
  .more-container-wrap > * {
    margin-bottom: 12px;
    -webkit-animation: slidein-rit 0.75s cubic-bezier(0.25, 1, 0.5, 1) normal forwards;
            animation: slidein-rit 0.75s cubic-bezier(0.25, 1, 0.5, 1) normal forwards;
  }

  @-webkit-keyframes slidein-rit {
    0% {
      opacity: 0;
      -webkit-transform: translatex(20vw);
              transform: translatex(20vw);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @keyframes slidein-rit {
    0% {
      opacity: 0;
      -webkit-transform: translatex(20vw);
              transform: translatex(20vw);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }
  .more-subcat {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 20px;
    color: rgba(96, 103, 110, 0.5);
    padding-left: 16px;
  }
  .forum-more-container {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    flex-direction: column;
  }
  .forum-more-container > *:nth-child(2) {
    margin-top: 16px;
  }
  .forum-more-container > *:nth-last-child(2) {
    margin-bottom: 12px;
  }
  .forum-more-container h4 {
    width: 100%;
    font-size: 0.875rem;
    font-weight: 600;
    /* background: rgba(148, 156, 166, 0.1); */

    border-bottom: 1px solid #eceff1;
    box-sizing: border-box;
    padding: 10px 16px;
  }

  /* .forum-more-container > * :last-child {
    margin-bottom: 8px;
  } */

  /* TRENDINGPOSTS----------------------------------- */
  .trendig-post-cont,
  .trendig-group-cont {
    margin: 8px 16px;
    -webkit-transition: 0.1s ease-in-out;
    transition: 0.1s ease-in-out;
    border-radius: 5px;
    cursor: pointer;
    color: #28323c;
  }
  .trendig-post-cont:hover,
  .trendig-group-cont:hover {
    /* background: #f9fafb; */
    color: #34a8fe;
  }

  .more-container-wrap h3 {
    font-size: 0.875rem;
    font-weight: 500;
  }
  .more-container .more-container-wrap p {
    margin: 0;
    color: #b1b4c7;
    font-size: 0.75rem;
  }
  .more-container-wrap img {
    object-fit: cover;
  }
  .trendig-post-cont img {
    width: 54px;
    min-width: 54px;
    height: 54px;
    border-radius: 5px;
    margin-left: 16px;
  }
  .trending-more-wrap {
    border-top: 1px solid #eceff1;
    padding: 9px 16px;
    font-size: 0.75rem;
    color: #b1b4c7;
    -webkit-transition: 0.1s ease;
    transition: 0.1s ease;
    /* background: #f9fafb; */
    /* border-radius: 0 0 5px 5px; */
  }
  .trending-more-wrap:hover {
    color: #34a8fe;
  }

  /* TRENDING GROUPS--------------------------------------- */
  .trendig-group-cont img {
    width: 36px;
    height: 36px;
    min-width: 36px;
    border-radius: 100%;
    margin-right: 16px;
    border: 1px solid rgba(148, 156, 166, 0.1);
  }
  .trendig-group-cont p {
    line-height: 1rem;
  }
  .more-col-follow-btn {
    font-size: 0.75rem;
    border-radius: 5px;
    height: 24px;
    padding: 0 12px;
    border: 1px solid #34a8fe;
    color: #34a8fe;
    margin: 0;
    -webkit-transition: 0.1s ease;
    transition: 0.1s ease;
  }
  .more-col-follow-btn:hover {
    background: rgba(52, 168, 254, 0.1);
  }
}

@media only screen and (min-width: 960px) {
}
@media only screen and (min-width: 1200px) {
}

.auth-page-wrapper {
  width: 100%;
  height: 100%;
  min-height: 80vh;
}
.signin-container {
  background: #28323c;
  box-sizing: border-box;
  max-width: 280px;
  margin: 16px auto;
  color: #8093a2;
  border-radius: 10px;
  padding: 16px 24px;
}

.signin-up-wrap {
  color: #fff;
  text-transform: uppercase;
  border-bottom: 0.5px solid #8093a2;
}
.signin-up-button {
  width: 150%;
  height: 36px;
  justify-content: center;
}
.signin-up-hilight {
  height: 3px;
  background: #34a8fe;
  width: 50%;
  margin-left: 50%;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  border-radius: 0 0 5px 5px;
  padding: 0 8px;
}

.auth-button-wrap {
  margin-bottom: 16px;
}
.auth-button-wrap .button-silver {
  color: #fff;
}
.auth-button-wrap .button-silver:hover {
  color: #34a8fe;
}
.auth-button-wrap .button-white {
  background: none;
}

.third-party-wrap {
  font-size: 0.75rem;
  text-align: left;
  box-sizing: border-box;
  padding: 16px 0 0;
}
.third-party-button-wrap {
  margin: 6px 0 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 8px;
  width: 100%;
  /* border: 1px solid red; */
}

.third-party-button-wrap button {
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.signin-divider {
  height: 44px;
  flex-direction: column;
  justify-content: center;
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.5);
}
.signin-dividerline {
  margin: 10px 0 -10px;
  width: 100%;
  border: 0.5px solid rgba(255, 255, 255, 0.1);
}
.signin-divider-or {
  background: #28323c;
}

.signin-container input {
  width: 100%;
  height: 36px;
  margin-bottom: 0px;

  padding: 0 12px;
  font-size: 0.875rem;
  font-weight: 400;
  color: #fff;

  display: flex;
  align-items: center;

  background: rgba(255, 255, 255, 0.1);
  border: none;
  outline: none;
  box-sizing: border-box;
  border-radius: 5px;
}
.signin-container input:invalid {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #ff4e71;
  color: #fff;
}

.signin-container input::-webkit-input-placeholder {
  color: #919498;
}

.signin-container input:-ms-input-placeholder {
  color: #919498;
}

.signin-container input::-ms-input-placeholder {
  color: #919498;
}

.signin-container input::placeholder {
  color: #919498;
}

.signup-disclaimer {
  font-size: 0.625rem;
  margin-bottom: 16px;
}
.form-error {
  color: #ff4e71;
}

.post-content-contaienr {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  box-sizing: border-box;
  /* cursor: pointer; */
  background: rgba(50, 58, 66, 0.6);
  overflow-x: hidden;
  -webkit-animation: none;
          animation: none;
  /* overflow-y: scroll;
  -webkit-overflow-scrolling: touch; */
}

.modal-spacer {
  height: 48px;
}
.post-content-wrap {
  margin: auto;
  width: 100%;
  min-height: Calc(100% - 48px);
  max-width: 960px;
  padding-bottom: 24px;
  box-sizing: border-box;
  background: #fff;
  cursor: default;
  font-size: 0.75rem;
  color: #323a42;
}

.content-pop-header,
.content-pop-content,
.content-pop-footer {
  padding: 0 16px;
}

.content-pop-header {
  /* background: #323a42; */
  background: #039be5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.content-pop-header .tab-seleciton-lin {
  margin: 0 16px;
}
.content-pop-header-close {
  padding: 6px;
}
.content-pop-header-close svg path {
  fill: rgba(255, 255, 255, 0.6);
}

.topic-container,
.text-container,
.title-container {
  margin: 16px 0;
}

.pop-lable {
  font-size: 0.75rem;
  margin-bottom: 4px;
  font-weight: 600;
}

/* .post-content-wrap .text-container textarea::placeholder, */
.post-content-wrap input::-webkit-input-placeholder {
  font-size: 0.875rem;
  font-weight: 400;
  color: #b5c0c5;
}
.post-content-wrap input:-ms-input-placeholder {
  font-size: 0.875rem;
  font-weight: 400;
  color: #b5c0c5;
}
.post-content-wrap input::-ms-input-placeholder {
  font-size: 0.875rem;
  font-weight: 400;
  color: #b5c0c5;
}
.post-content-wrap input::placeholder {
  font-size: 0.875rem;
  font-weight: 400;
  color: #b5c0c5;
}
.post-content-wrap select,
#emailinput {
  padding: 6px 12px;
}
/* .question-answer-wrapper input,
.question-answer-wrapper select {
  margin: 8px;
} */

.post-submission-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  box-sizing: border-box;
  min-width: 80px;
  margin-top: 0;
  margin-bottom: 0;
}

.content-pop-footer {
  /* width: 100%; */
  /* flex-direction: column; */
}
.content-pop-footer > *:first-child {
  margin-bottom: 12px;
}

.post-error-message,
.post-error-message svg,
.post-error-message svg path {
  stroke: rgb(249, 41, 83);
  color: rgb(249, 41, 83);
  margin-right: 12px;
}

/* LOCATOININPUT================================ */
.post-loc-input-wrap {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 5px;
  background: rgba(50, 58, 66, 0.1);
  z-index: 100;
}
.post-loc-input-crd {
  position: fixed;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  flex-direction: column;
  top: 48px;
  padding: 16px;
  margin: 16px;
  box-sizing: border-box;
  width: Calc(100% - 32px);
  height: 267px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.12),
    0px 5px 10px rgba(0, 0, 0, 0.09);
}

.post-loc-input-crd-hed {
  border-radius: 5px;
  border: 1px solid #cfd8dc;
  display: flex;
  align-items: center;
  padding: 0 5px;
  width: 100%;
}
.post-loc-input-wrap .post-loc-input-crd .post-loc-input-crd-hed svg {
  position: relative;
  top: 0;
  left: 0;
  min-width: 24px;
}
.post-loc-input-wrap
  .post-loc-input-crd
  .post-loc-input-crd-hed
  svg:last-of-type {
  padding: 4px;
  border-radius: 5px;
  background: #fff;
  cursor: pointer;
  -webkit-transition: 0.1s ease;
  transition: 0.1s ease;
}
.post-loc-input-wrap
  .post-loc-input-crd
  .post-loc-input-crd-hed
  svg:last-of-type:hover,
.post-loc-input-wrap
  .post-loc-input-crd
  .post-loc-input-crd-hed
  svg:last-of-type:hover
  path {
  background: rgba(52, 168, 254, 0.1);
  fill: #039be5;
}
.post-loc-input-wrap .post-loc-input-crd .post-loc-input-crd-hed svg path {
  fill: #cfd8dc;
}

.post-loc-input-wrap
  .post-loc-input-crd
  .post-loc-input-crd-hed
  .autocomplete-dropdown-container {
  position: absolute;
  width: Calc(100% - 32px);
  left: 16px;
  background: #fff;
}

.post-loc-input-wrap .post-loc-input-crd input {
  background: none;
  outline: none;
  border: none;
  height: 40px;
  box-sizing: border-box;
  width: 100%;
  padding: 0 8px;
}
.post-loc-input-wrap .post-loc-input-crd input:hover.post-loc-input-crd-hed {
  border: 1px solid #039be5;
}
.post-location-pop-back {
  text-align: center;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: -1;
  font-size: 1.25rem;
  font-weight: 600;
  color: #bcc2c7;
  padding-top: 40px;
}
.post-location-pop-back svg:first-of-type {
  position: absolute;
  bottom: 0;
  left: 0;
}
.post-location-pop-back svg:last-of-type {
  position: absolute;
  bottom: 0;
  right: 0;
}

@media only screen and (min-width: 768px) {
  .modal-spacer {
    height: 12%;
    min-height: 48px;
  }
  .post-content-wrap {
    width: Calc(100% - 32px);
    min-height: auto;
    border-radius: 10px;
    /* max-height: Calc(100% - 48px); */
  }
  .content-pop-header,
  .content-pop-content,
  .content-pop-footer {
    padding: 0 32px;
  }
  .content-pop-footer {
    /* width: 100%; */
    flex-direction: row;
  }
  .content-pop-header {
    border-radius: 5px 5px 0 0;
  }
  .content-pop-header-close {
    padding: 6px 16px;
  }
  .content-pop-header-close svg {
    display: none;
  }
  /* ------------------------------------------------------------- */
  .post-loc-input-wrap {
    display: flex;
    justify-content: center;
  }
  .post-loc-input-crd {
    position: relative;
    width: 400px;
  }
}

@media only screen and (min-width: 960px) {
  .post-content-wrap {
    /* padding: 32px; */
  }
}

@media only screen and (min-width: 1200px) {
  .post-content-wrap {
    /* padding: 32px; */
  }
}

/* TOPIC INPUT-------------------------------------------------------- */
.topic-wrapper {
  min-height: 40px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #cfd8dc;
  padding: 0 0 0 8px;
  /* overflow: hidden; */

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  /* flex-wrap: wrap; */
}
.fex-wrap {
  flex-wrap: wrap;
}
.topic-input-wrapper {
  position: relative;
  width: 100%;

  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.topic-wrapper input {
  border: none;
  background: none;
  margin: 0;
  padding: 0 12px 0 4px;
  width: 100%;
}

.topic-tag-wrapper {
  max-width: 100%;
  /* flex-wrap: wrap; */
}

.topic-tag {
  margin: 8px 8px 8px 0;
  height: 24px;
  border-radius: 5px;
  background: rgba(52, 168, 254, 0.1);
  color: #039be5;
  padding: 0 12px;
  font-weight: 600;
  cursor: pointer;
  -webkit-transition: 0.1s ease;
  transition: 0.1s ease;
  white-space: nowrap;
}

.topic-tag:hover {
  opacity: 0.5;
}
.topic-tag > *:first-child {
  margin-right: 4px;
}
.topic-tag svg {
  fill: #039be5;
}
.topic-tag svg path {
  fill: #039be5;
}

.tag-input-chrecterCount {
  font-size: 0.875rem;
  font-weight: 400;
  color: #b5c0c5;
}

.tag-input-warning {
  color: rgb(255, 203, 43);
}
.tag-input-err {
  color: rgb(249, 41, 83);
}
/* input counter--------------------------- */
.tag-input-chrecterCount svg {
  margin: 0;
  width: 40px;
  height: 40px;
}
.tag-input-chrecterCount svg circle {
  fill: none;
  stroke-width: 3;
  -webkit-transition: 0.1s ease-in;
  transition: 0.1s ease-in;
}
.tag-input-chrecterCount svg #gray {
  stroke: #f4f6f8;
  /* stroke: #b5c0c5; */
}

/* input dropdown--------------------------- */

.autocomplet-drop-shadow {
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
}
.topic-input-suggestions {
  visibility: hidden;
  position: absolute;
  z-index: 80;
  top: 100%;
  left: 0;
  background: #fff;
  text-align: left;
  border-radius: 5px;
  font-size: 0.875rem;
  box-sizing: border-box;
  cursor: pointer;
}
.topic-wrapper input:focus ~ .topic-input-suggestions {
  visibility: visible;
}
.topic-sugection-wrap {
  flex-wrap: nowrap;
}
.topic-input-suggestions > * {
  padding: 8px 12px;
  box-sizing: border-box;
  margin: 2px 0;
  -webkit-transition: 0.1s ease;
  transition: 0.1s ease;
}
.topic-sugection {
  font-weight: 500;
  color: #323a42;
}
.topic-sugection-follows {
  font-weight: 400;
  color: #b5c0c5;
  margin-left: 12px;
}
.topic-input-suggestions > *:hover,
.topic-input-suggestions > *:hover .topic-sugection {
  color: #34a8fe;
  background: #f9fafb;
}
.topic-input-suggestions > *:first-child {
  padding-top: 16px;
  border-radius: 5px 5px 0 0;
}
.topic-input-suggestions > *:last-child {
  padding-bottom: 16px;
  border-radius: 0 0 5px 5px;
}

.draftJsMentionPlugin__mentionSuggestions__2DWjA {
  position: absolute;
  border-radius: 5px;
  padding: 8px 0;
  background: #fff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.12), 0px 3px 6px rgba(0, 0, 0, 0.09);
  max-width: 250px;
  min-width: 160px;
}

.draftJsMentionPlugin__mentionSuggestionsEntry__3mSwm {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  /* transition: 0.1s ease-in-out; */
  padding: 4px 8px;
  cursor: pointer;
  min-height: 32px;
  min-width: 120px;
}
.draftJsMentionPlugin__mentionSuggestionsEntryFocused__3LcTd {
  background: rgba(52, 168, 254, 0.1);
  color: #34a8fe;
}

/* .draftJsMentionPlugin__mentionSuggestionsEntryAvatar__1xgA9 */
.draftJsMentionPlugin__mentionSuggestions__2DWjA
  .draftJsMentionPlugin__mentionSuggestionsEntry__3mSwm
  img {
  width: 32px;
  height: 32px;
  border-radius: 5px;
  margin-right: 16px;
}

.draftJsMentionPlugin__mentionSuggestionsEntryText__3Jobq {
}

.textiput-container {
  border-radius: 5px;
  border: 1px solid #cfd8dc;
  padding: 12.5px 12.5px 20px;
  /* min-height: 200px; */
  position: relative;
  box-sizing: border-box;
  z-index: 5;
  width: 100%;
}

/* IMPUT AREA----------------------------------- */

.DraftEditor-root {
  /* min-height: 200px; */
  height: 100%;
  width: 100%;
  overflow-y: auto;
  font-size: 0.875rem;
  font-family: inherit;
  cursor: text;
  /* background: #fff; */
}
.DraftEditor-editorContainer {
  height: 100%;
}

.public-DraftEditorPlaceholder-inner {
  position: absolute;
  pointer-events: none;
  font-size: 0.875rem;
  font-weight: 400;
  color: #b5c0c5;
}
.public-DraftStyleDefault-block {
  margin-bottom: 1em;
}

.textiput-toolbar button,
.textiput-toolbar .toolbar-div-cont {
  width: 40px;
  height: 40px;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  border-radius: 5px;
}

.textiput-toolbar {
  height: 40px;
  overflow: hidden;
  align-items: flex-start;
  position: relative;
}
.toolbar-tools {
  overflow-x: auto;
  white-space: nowrap;
}
/* .toolbar-charcnt {
  position: absolute;
  width: 24px;
  height: 40px;
  top: 0;
  right: 40px;
  background: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
} */

.toolbar-divider {
  /* border-right: 2px solid #f4f6f8; */

  border-right: 1px solid #cfd8dc;
  height: 24px;
}
.textiput-toolbar {
  margin: -12px 0 8px -12px;
  width: Calc(100% + 24px);
  max-width: Calc(100% + 24px);
  border: 1px solid red;
}
.textiput-toolbar button {
  outline: none;
  background: #fff;
  -webkit-transition: 0.1s ease;
  transition: 0.1s ease;
  border: none;
  cursor: pointer;
}
.textiput-toolbar button svg {
  pointer-events: none;
}
.textiput-toolbar button:hover,
.textiput-toolbar button:hover svg path {
  fill: #039be5;
  background: rgba(52, 168, 254, 0.1);
  opacity: 1;
}
.active-toolbar-button svg path {
  fill: #039be5;
}

.input-dragbar {
  width: 100%;
  cursor: n-resize;
  padding: 4px;
}
.input-dragbar svg path {
  -webkit-transition: 0.15s ease;
  transition: 0.15s ease;
  fill: #cfd8dc;
}
.input-dragbar:hover svg path {
  fill: #039be5;
}

/* STYLING--------------------------------- */
.textiput-container h2 {
  font-size: 1.25rem;
  font-weight: 500;
  margin: 0 0 2rem;
}
.textiput-container h3 {
  margin: 0;
  font-size: 0.75rem;
  color: #b1b4c7;
  font-weight: 400;
  font-style: italic;
}

.textiput-container ul,
.textiput-container ol {
  box-sizing: border-box;
  margin: 0;
  padding: 15px 0 15px 40px;
}

.input-editor-wra {
  position: relative;
}

.input-editor-wrap img {
  width: 100%;
  max-width: 200px;
  border-radius: 5px;
}

.input-editor-wrap a {
  color: #039be5;
}

/* MENU--------------------------------------------- */
.active-toolbar-text {
  color: #039be5;
}
.toolbar-menu-wrap {
  position: relative;
}
.toolbar-menu-active {
  height: 40px;
  width: 60px;
  font-size: 0.875rem;
  color: #cfd8dc;
  padding: 0 6px 0 12px;
  border-radius: 5px;
  -webkit-transition: 0.1s ease;
  transition: 0.1s ease;
  cursor: pointer;
  text-transform: capitalize;
}
.toolbar-menu-active:hover,
.toolbar-menu-active:hover svg path {
  color: #039be5;
  fill: #039be5;
  background: rgba(52, 168, 254, 0.1);
}
.toolbar-menu-active > * div {
  margin-left: 8px;
}

.toolbar-menu-active svg path {
  fill: #cfd8dc;
}

.toolbar-menu-drop {
  z-index: 12;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  word-wrap: 100%;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.12), 0px 3px 6px rgba(0, 0, 0, 0.09);
}
.toolbar-menu-drop > *:first-child {
  margin-top: 8px;
}
.toolbar-menu-drop > *:last-child {
  margin-bottom: 8px;
}
.toolbar-menu-drop button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: none;
  outline: none;
  border: none;
  padding: 4px 12px;
  cursor: pointer;
  color: #cfd8dc;
  font-size: 0.875rem;
  width: 100%;
}

.toolbar-menu-drop button:hover,
.toolbar-menu-drop button:hover svg path {
  color: #039be5;
  fill: #039be5;
  background: rgba(52, 168, 254, 0.1);
}
.toolbar-menu-drop button svg {
  margin-right: 8px;
}
@media only screen and (min-width: 768px) {
  .toolbar-menu-active {
    width: 155px;
  }
  .toolbar-menu-drop {
    width: 155px;
  }
}

/* LINK PRIEVIEW----------------------------- */
.preview-container {
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 8px;
  margin: 24px 0 -8px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #cfd8dc;
  cursor: pointer;
  -webkit-transition: 0.15s ease-in;
  transition: 0.15s ease-in;
}

.preview-container:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.1);
}

.text-container .preview-container img {
  margin: 0 0 8px 0;
  width: 90px;
  height: 60px;
  border-radius: 5px;
  overflow: hidden;
  background: #f4f6f8;
  object-fit: cover;
}

.content-pop-content .text-container .preview-container h4 {
  font-size: 0.875rem;
}

.text-container .preview-container p {
  margin: 0;
  font-size: 0.75rem;
  color: #757575;
}

.text-container .preview-container button {
  position: absolute;
  top: 0;
  right: 0;
  margin: 8px;
}
.preview-container button svg path {
  -webkit-transition: 0.15s ease-out;
  transition: 0.15s ease-out;
}
.preview-container button:hover,
.preview-container button:hover svg path {
  fill: #039be5;
  background: rgba(52, 168, 254, 0.1);
  opacity: 1;
}

@media only screen and (min-width: 768px) {
  .preview-container {
    flex-direction: row;
    align-items: center;
  }
  .content-pop-content .text-container .preview-container h4 {
    font-size: 1rem;
  }
  .content-pop-content .text-container .preview-container p {
    margin: 0;
    font-size: 0.875rem;
    color: #757575;
  }
  .text-container .preview-container img {
    margin: 0 16px 0 0;
    width: 180px;
    height: 120px;
  }
}

/* IMAGEINPUT================================ */
.image-input-wrap {
  position: fixed;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 5px;
  background: rgba(50, 58, 66, 0.1);
  z-index: 100;
}
.image-input-crd {
  position: fixed;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  flex-direction: column;
  top: 48px;
  padding: 16px;
  margin: 16px 0;
  box-sizing: border-box;
  width: Calc(100% - 32px);
  height: 267px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.12),
    0px 5px 10px rgba(0, 0, 0, 0.09);
}

.image-input-crd-hed {
  border-radius: 5px;
  border: 1px solid #cfd8dc;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 5px;
  width: 100%;
}
.image-input-wrap .image-input-crd .image-input-crd-hed svg {
  position: relative;
  top: 0;
  left: 0;
  min-width: 24px;
}
.image-input-wrap .image-input-crd .image-input-crd-hed svg:last-of-type {
  padding: 4px;
  border-radius: 5px;
  background: #fff;
  cursor: pointer;
  -webkit-transition: 0.1s ease;
  transition: 0.1s ease;
}
.image-input-wrap .image-input-crd .image-input-crd-hed svg:last-of-type:hover,
.image-input-wrap
  .image-input-crd
  .image-input-crd-hed
  svg:last-of-type:hover
  path {
  background: rgba(52, 168, 254, 0.1);
  fill: #039be5;
}
.image-input-wrap .image-input-crd .image-input-crd-hed svg path {
  fill: #cfd8dc;
}

.image-input-wrap
  .image-input-crd
  .image-input-crd-hed
  .autocomplete-dropdown-container {
  position: absolute;
  width: Calc(100% - 32px);
  left: 16px;
  background: #fff;
}

.image-input-wrap .image-input-crd input {
  background: none;
  outline: none;
  border: none;
  height: 40px;
  box-sizing: border-box;
  width: 100%;
  padding: 0 8px;
}
.image-input-wrap .image-input-crdinput:hover .image-input-crd-hed {
  border: 1px solid #039be5;
}
.image-pop-back {
  text-align: center;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: -1;
  font-size: 0.75rem;
  font-weight: 400;
  color: #bcc2c7;
  box-sizing: border-box;
  border: 2px dashed #cfd8dc;
  margin-top: 8px;
  border-radius: 5px;
  -webkit-transition: 0.15s ease-in-out;
  transition: 0.15s ease-in-out;
}
.image-pop-back-active {
  background: rgba(52, 168, 254, 0.1);
  border: 2px dashed #039be5;
}
.image-pop-back svg:first-of-type {
  position: absolute;
  bottom: 0;
  left: 0;
}
.image-pop-back svg:last-of-type {
  position: absolute;
  bottom: 0;
  right: 0;
}

.image-pop-back-text {
  max-width: 230px;
  font-size: 1.25rem;
  font-weight: 600;
  color: #323a42;
  margin-bottom: 8px;
}
.image-pop-back-text span {
  cursor: pointer;
}

.image-input-wrap .image-laoding-wrap {
  width: auto;
  height: auto;
  /* margin: auto; */
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.image-laoding-wrap img {
  max-width: 120px;
  max-height: 80px;
  border-radius: 5px;
  margin-right: 16px;
}
.image-input-upload-txt {
  font-size: 1.25rem;
  font-weight: 600;
  color: #323a42;
  margin: 0;
}
.image-laoding-wrap .progress {
  width: 180px;
  height: 4px;
  box-sizing: border-box;
  border-radius: 5px;
  background: #f9fafb;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 12px;
}
.image-laoding-wrap .progress-value {
  -webkit-animation: loader 8s ease-in-out forwards;
          animation: loader 8s ease-in-out forwards;
  height: 100%;
  background: #039be5;
}
.image-imput-error-msg {
  color: #ff4e71;
  width: 100%;
  text-align: center;
  font-size: 0.75rem;
  height: 32px;
  display: flex;
  align-items: flex-end;
}

@-webkit-keyframes loader {
  0% {
    width: 0;
  }

  20% {
    width: 10%;
  }

  25% {
    width: 24%;
  }

  43% {
    width: 41%;
  }

  56% {
    width: 50%;
  }

  66% {
    width: 52%;
  }

  71% {
    width: 60%;
  }

  75% {
    width: 76%;
  }

  100% {
    width: 86%;
  }
}

@keyframes loader {
  0% {
    width: 0;
  }

  20% {
    width: 10%;
  }

  25% {
    width: 24%;
  }

  43% {
    width: 41%;
  }

  56% {
    width: 50%;
  }

  66% {
    width: 52%;
  }

  71% {
    width: 60%;
  }

  75% {
    width: 76%;
  }

  100% {
    width: 86%;
  }
}

/* LINK-------------------------------------------------------------- */
.toolbar-link-input {
  z-index: 12;
  overflow: hidden;
  position: absolute;
  top: 40px;
  right: 0;
  width: auto;
  border-radius: 5px;
  padding: 8px;
  word-wrap: 100%;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.12), 0px 3px 6px rgba(0, 0, 0, 0.09);
  width: 230px;
  overflow: hidden;
}
.toolbar-link-input form {
  position: relative;
}
.toolbar-link-input input {
  height: 36px;
  border: none;
  outline: none;
  width: 100%;
}

.toolbar-link-input svg {
  padding: 4px;
  border-radius: 5px;
  margin-right: 8px;
  cursor: pointer;
}
.toolbar-link-input svg path {
  fill: #cfd8dc;
}
.toolbar-link-input svg:hover,
.toolbar-link-input svg:hover path {
  background: rgba(52, 168, 254, 0.1);
  fill: #039be5;
}
/* .toolbar-link-input button {
  position: absolute;
  right: 0;
} */

@media only screen and (min-width: 768px) {
  /* ------------------------------------------------------------- */

  .image-input-crd {
    position: relative;
    width: 400px;
  }
  .image-input-wrap .image-laoding-wrap {
    height: 112px;
  }
}

.title-input-container {
  border-radius: 5px;
  border: 1px solid #cfd8dc;
  padding: 0 0 0 8px;
  height: 42px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  position: relative;
  /* width: 100%; */
}
.title-input-input-wrap {
  width: 100%;
}
.title-input-container input {
  outline: none;
  border: none;
  box-shadow: none;
  /* min-width: 100%; */
  width: 100%;
  padding-left: 4px;
  box-sizing: border-box;
}

/* --------------------------------------------- */

.title-input {
  /* font-size: 1rem; */
  font-size: 1.25rem;
  font-weight: 600;
}

.filter-col-cont {
  /* height: Calc(100vh - 56px - 48px - 48px); */
  margin-top: -24px;
  position: fixed;
  width: 210px;
  z-index: 99;
  /* border: 1px solid red; */
  box-sizing: content-box;
  overflow-x: hidden;
  overflow-y: scroll;
}

/* width */
.filter-col-cont::-webkit-scrollbar {
  width: 0;
  height: 0;
}

/* Track */
.filter-col-cont::-webkit-scrollbar-track {
  background: none;
  width: 0;
}

/* Handle */
.filter-col-cont::-webkit-scrollbar-thumb {
  width: 0;
  background: none;
}

/* ------------------------------------------------------------- */

.filter-col-head {
  margin: 24px 0 12px;
  padding: 8px 16px;
  font-weight: 600;
  font-size: 16px;
  color: #28323c;
  background: #f4f6f8;
  width: 100%;
}
.filter-col-head > *:first-child,
.filter-col-cont-blk-cnt div > *:first-child {
  margin-right: 12px;
}

.filter-col-cont-blk-hd {
  height: 36px;
  padding: 0 16px;
  background: rgba(148, 156, 166, 0.1);
  cursor: pointer;
  -webkit-transition: 0.15s ease;
  transition: 0.15s ease;
  font-weight: 600;
  font-size: 14px;
  color: #28323c;
}

.filter-col-cont-blk-hd svg path {
  fill: rgba(148, 156, 166, 0.1);
  -webkit-transition: 0.15s ease;
  transition: 0.15s ease;
}

.filter-col-cont-blk-hd:hover,
.filter-col-cont-blk-hd:hover svg path {
  fill: #949ca6;
  background: rgba(148, 156, 166, 0.2);
}

.filter-col-cont-blk-cnt-wrap {
  -webkit-transition: 0.15s ease-in-out;
  transition: 0.15s ease-in-out;
  overflow-y: hidden;
}

.filter-col-cont-blk-cnt-wrap > *:first-child {
  margin-top: 8px;
}
.filter-col-cont-blk-cnt-wrap > *:last-child {
  margin-bottom: 16px;
}
.filter-col-cont-blk-cnt {
  padding: 4px 16px;
  font-weight: 500;
  font-size: 14px;
  color: #949ca6;
  cursor: pointer;
  -webkit-transition: 0.15s ease;
  transition: 0.15s ease;
}
.filter-col-cont-blk-cnt svg path {
  fill: #949ca6;
}
.filter-col-cont-blk-cnt:hover,
.filter-col-cont-blk-cnt:hover svg path {
  color: #039be5;
  fill: #039be5;
}
.selected-filter,
.selected-filter svg path {
  color: #039be5;
  fill: #039be5;
}
.filter-drop-wrap {
  position: absolute;
  left: 0;
  width: 100%;
  border-radius: 5px;
  box-sizing: border-box;
  background: #fff;
  -webkit-transition: 0.15s cubic-bezier(0, 1, 0.1, 1);
  transition: 0.15s cubic-bezier(0, 1, 0.1, 1);
  z-index: 999;
  opacity: 0;
  overflow: hidden;
  -webkit-transform: translateY(18px);
          transform: translateY(18px);
  font-size: 14px;
}
.filter-drop-wrap > *:first-child {
  margin-top: 16px;
}
.filter-drop-wrap-open {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);

  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.12), 0px 3px 6px rgba(0, 0, 0, 0.09);
}

.filter-topics-more {
  height: 36px;
  cursor: pointer;
}
.filter-topics-more-lin {
  position: absolute;
  left: 16px;
  z-index: -1;
  width: Calc(100% - 32px);
  border-bottom: 1px solid #dbdee2;
  -webkit-transition: 0.1s ease;
  transition: 0.1s ease;
}
.filter-topics-more-cont {
  border: 1px solid #dbdee2;
  border-radius: 5px;
  font-size: 0.75rem;
  padding: 0 12px;
  height: 24px;
  background: #f4f6f8;
  -webkit-transition: 0.1s ease;
  transition: 0.1s ease;
  color: #949ca6;
}
.filter-topics-more:hover .filter-topics-more-cont {
  border: 1px solid #039be5;
  color: #039be5;
}
.filter-topics-more:hover .filter-topics-more-lin {
  border-bottom: 1px solid #039be5;
}

/* DROPDOWN------------------------------- */
.filter-col-cont-srch-container {
  position: absolute;
  z-index: 12;
  width: 100%;
  margin-top: 44px;
  padding: 0 0 0 16px;
}
.filter-col-cont-srch-container svg {
  min-width: 24px;
}
.filter-col-cont-srch-container input {
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  outline: 0;
  background: none;
  border: 0;
  font-size: inherit;
  color: #949ca6;
  width: 100%;
  font-size: 0.875rem;
  border-radius: 0;
}
.filter-col-cont-srch-container input:focus {
  margin-left: -12px;
}

.filter-col-cont-srch-container input::-webkit-input-placeholder {
  font-weight: 500;
  font-size: 14px;
  color: #949ca6;
}

.filter-col-cont-srch-container input:-ms-input-placeholder {
  font-weight: 500;
  font-size: 14px;
  color: #949ca6;
}

.filter-col-cont-srch-container input::-ms-input-placeholder {
  font-weight: 500;
  font-size: 14px;
  color: #949ca6;
}

.filter-col-cont-srch-container input::placeholder {
  font-weight: 500;
  font-size: 14px;
  color: #949ca6;
}

.filter-col-cont-srch-container .autocomplet-drop-shadow {
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.12), 0px 3px 6px rgba(0, 0, 0, 0.09);
}

.filter-col-cont-srch-container .form-input-suggestions {
  text-align: left;
  border-radius: 5px;
  font-size: 0.875rem;
  color: #757575;
  box-sizing: border-box;
  background: #fff;
}
.filter-col-cont-srch-container .form-input-suggestions > * {
  padding: 8px 12px;
  box-sizing: border-box;
}
.filter-col-cont-srch-container .form-input-suggestions > *:hover {
  color: #34a8fe;
  background: #fff;
}
.filter-col-cont-srch-container .form-input-suggestions > *:first-child {
  padding-top: 16px;

  border-radius: 5px 5px 0 0;
}
.filter-col-cont-srch-container .form-input-suggestions > *:last-child {
  padding-bottom: 16px;
  border-radius: 0 0 5px 5px;
}

.filter-col-loc-close {
  padding: 4px;
  border-radius: 5px;
  -webkit-transition: 0.1s ease;
  transition: 0.1s ease;
  cursor: pointer;
}
.filter-col-loc-close:hover,
.filter-col-loc-close:hover svg path {
  background: rgba(148, 156, 166, 0.1);
  fill: #34a8fe;
}

.No-content {
  margin: auto;
  display: flex;
  flex-direction: column;

  font-weight: 600;
  font-size: 1.5rem;
  line-height: 29px;

  text-align: center;

  color: #323a42;
  justify-content: center;
}
.No-content svg {
  width: 100%;
  max-width: 417px;
  margin: 0 auto -40px;
}
.No-content p {
  font-size: 16px;
  line-height: 24px;
  margin-top: 12px;
  color: #959a9f;
}
.Forum404 {
  margin-top: 24px;
  font-size: 3rem;
  color: #c7d1d9;
}

.forum-msg-content {
  min-height: 100%;
}
.forum-msg-wrap {
  padding: 16px 16px 80px;
  margin: 0 auto;
  box-sizing: border-box;
  min-height: 100%;
}
.forum-msg-grid {
  display: grid;
  grid-template-columns: 1fr;
}

.message-threads-wrap {
  display: none;
}

@media only screen and (min-width: 768px) {
  .forum-msg-wrap {
    padding: 0 0 80px;
    margin-top: 24px;
  }
  .forum-msg-grid {
    grid-template-columns: 256px 1fr;
    grid-gap: 24px;
  }
  .message-threads-wrap {
    display: block;
    width: 100%;
    height: 100%;
    padding: 24px 0;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
}
@media only screen and (min-width: 960px) {
  .forum-msg-grid {
    /* grid-template-columns: 1fr 256px; */
  }
  .more-container-wrap {
    width: 256px;
  }
}

.auth-page-wrapper {
  width: 100%;
  height: 100%;
  min-height: 80vh;
}
.signin-container {
  background: #28323c;
  box-sizing: border-box;
  max-width: 280px;
  width: Calc(100% - 32px);
  margin: 16px auto;
  color: #8093a2;
  border-radius: 10px;
  padding: 16px 24px;
}
.pricing-pans-continer {
  max-width: 420px;
}

.signin-up-wrap {
  color: #fff;
  text-transform: uppercase;
  border-bottom: 0.5px solid #8093a2;
}
.signin-up-button {
  width: 150%;
  height: 36px;
  justify-content: center;
}
.signin-up-hilight {
  height: 3px;
  background: #34a8fe;
  width: 50%;
  margin-left: 50%;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  border-radius: 0 0 5px 5px;
  padding: 0 8px;
}

.auth-button-wrap {
}

.third-party-wrap {
  font-size: 0.75rem;
  text-align: left;
  box-sizing: border-box;
  padding: 16px 0 0;
}
.third-party-button-wrap {
  margin: 6px 0 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 8px;
}
.third-party-button-wrap button {
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.usernam-button-sub:disabled:hover {
  cursor: default;
  background: rgba(255, 255, 255, 0.1);
}

.signin-divider {
  height: 44px;
  flex-direction: column;
  justify-content: center;
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.5);
}
.signin-dividerline {
  margin: 10px 0 -10px;
  width: 100%;
  border: 0.5px solid rgba(255, 255, 255, 0.1);
}
.signin-divider-or {
  background: #28323c;
}

.signin-container input {
  width: 100%;
  height: 36px;
  margin-bottom: 0px;

  padding: 0 12px;
  font-size: 0.875rem;
  font-weight: 400;
  color: #fff;

  display: flex;
  align-items: center;

  background: rgba(255, 255, 255, 0.1);
  border: none;
  outline: none;
  box-sizing: border-box;
  border-radius: 5px;

  -webkit-transition: 0.1s ease;

  transition: 0.1s ease;
}
.signin-container input:hover {
  background: rgba(255, 255, 255, 0.05);
}
.signin-container input:focus {
  background: rgba(255, 255, 255, 0.2);
}

.signin-container input:invalid {
  /* background: rgba(255, 255, 255, 0.1); */
  border: 1px solid #ff4e71;
  color: #fff;
}

.signin-container input::-webkit-input-placeholder {
  color: #919498;
}

.signin-container input:-ms-input-placeholder {
  color: #919498;
}

.signin-container input::-ms-input-placeholder {
  color: #919498;
}

.signin-container input::placeholder {
  color: #919498;
}

.password-feedbask-container {
  height: 0;
  width: 100%;
  overflow: hidden;
  -webkit-transition: 0.15s ease-out;
  transition: 0.15s ease-out;
  font-size: 0.875rem;
  font-weight: 300;

  color: #fff;
  /* display: grid;
  grid-template-columns: repeat(3, 1fr); */
}

.pricing-plans-learn-more {
  height: 20px;
  display: flex;
  align-items: flex-end;
  text-align: right;
  font-size: 0.875rem;
  font-weight: 200;
  color: rgba(255, 255, 255, 0.5);
  -webkit-transition: 0.1s ease;
  transition: 0.1s ease;

  text-transform: none;
}

.pricing-plans-learn-more:hover {
  color: #fff;
}

#password-signup:focus + .password-feedbask-container {
  height: 42px;
  border-radius: 0 0 5px 5px;
  margin: -5px 0 4px;
  padding: 5px 12px 0;
  box-sizing: border-box;
  text-align: left;

  border: 1px solid rgba(255, 255, 255, 0.1);
  border-top: none;
  /* border: 1px solid red; */
}

.signup-img-cont {
  position: relative;
  margin: 32px auto 18px;
}
.signup-img-wrap {
  border-radius: 10px;
  overflow: hidden;
  width: 64px;
  height: 64px;
  margin: auto;
  box-sizing: content-box;
  border: 4px solid rgba(255, 255, 255, 0.1);
}
.signup-img-wrap img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.signup-imgChng-wrap {
  position: absolute;
  right: -12px;
  bottom: -12px;
}

.signup-imgChng-button {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background: #34a8fe;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
  position: absolute;
  cursor: pointer;
}

/* .signup-imgChng-button:hover {
  opacity: 0.5;
} */

#avatar {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  opacity: 0;
  border: 1px solid red;
  cursor: pointer;
  /* pointer-events: none; */
  box-sizing: border-box;
  /* padding-left: 32px; */
}

.username-input {
  margin-bottom: 24px;
}
.errorMessage-wrap {
  font-size: 0.75rem;
  /* height:  */
  text-align: left;
  margin: 0 0 4px;
}

.signup-disclaimer {
  font-size: 0.625rem;
  margin-bottom: 16px;
}
.form-error {
  color: #ff4e71;
}

.pricing-page-wrap {
  margin: 0 16px;
}

/* .pricing-header h5 {
  font-size: 1.5rem;
  line-height: 28px;
  font-weight: 500;
  margin: 32px 0 16px;
}
.pricing-header h1 {
  font-size: 52px;
  line-height: 60px;
  font-weight: 500;
  margin: 32px 0;
} */

/* ---------------------------------------------------------- */
.table-card-wrap {
  margin-bottom: 24px;
}

/* .pricing-table-wrap-free {
  max-width: 650px;
  margin: 0 auto;
  padding: 0 0 32px;
  text-align: center;
}
.pricing-table-wrap-free h3 {
  font-size: 2rem;
  font-weight: 600;
  line-height: 54px;
  margin: 32px 0 8px;
}
.pricing-table-wrap-free p {
  font-size: 0.875rem;
  color: #757575;
  margin-bottom: 24px;
}
.pricing-table-wrap-free a {
  color: rgb(52, 168, 254);
} */

.pricing-table-wrap-paidLink {
  opacity: 0.6;
  -webkit-transition: 0.1s ease-in-out;
  transition: 0.1s ease-in-out;
  cursor: pointer;
}
.pricing-table-wrap-paidLink:hover {
  opacity: 1;
}

.pricing-table-wrap-paidLink p {
  font-size: 0.875rem;
  margin: 24px 0 0;
  color: #939eab;
}

.pricing-table-wrap-divider {
  margin: 32px 0 56px;
  width: 100%;
  border-top: 1px solid #cfd8dc;
}

.pricing-dislaimer {
  color: #939eab;
  height: 24px;
  margin: 8px 0;
  text-align: left;
  font-size: 0.75rem;
}
/* ---------------------------------------------------------- */

.prising-mobile-allPlans {
  text-align: center;
  padding: 32px 8px;
  border: 1px solid #eceff1;
  border-radius: 5px;
  margin-bottom: 24px;
  font-size: 0.75rem;
  color: #939eab;
}
.prising-mobile-allPlans div {
  font-size: 1.5rem;
  font-weight: 500;
  color: #323a42;
}

.pricing-icons-wrapper {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 16px auto;
}
.pricing-icon {
  margin: 6px;
  height: 36px;
  width: 36px;
  border-radius: 100%;
  background: #039be5;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ---------------------------------------------------------- */
.pricing-table-wrap table {
  display: none;
  margin-bottom: 48px;
}

@media only screen and (min-width: 768px) {
  .pricing-page-wrap {
    margin: 0;
  }
  .pricing-table-wrap {
    margin: 8px;
  }
  .table-card-wrap {
    margin-bottom: 48px;
  }
  /* .pricing-table-wrap-free p {
    font-size: 1rem;
    line-height: 28px;
  } */
  /* ---------------------------------------------------------- */
  .pricing-mobile-wrap {
    display: none;
  }
  /* ---------------------------------------------------------- */

  .table-card-wrap {
    margin-bottom: 48px;
  }

  /* ---------------------------------------------------------- */

  .pricing-table-wrap thead,
  th,
  td {
    /* box-sizing: content-box; */
    border-bottom: 1px solid #eceff1;
    border-right: 1px solid #eceff1;
    /* border: 0.5px solid #eceff1; */
  }

  .pricing-table-wrap table {
    display: table;
    border-left: 1px solid #eceff1;
    border-radius: 10px;
    width: 100%;
    padding: 0;
    margin: 0;
    table-layout: fixed;
  }
  .pricing-table-wrap table thead {
    /* border: 1px solid #eceff1; */
    border-radius: 10px;
    width: 100%;
  }
  .pricing-table-wrap table thead {
    width: 100%;
  }

  .pricing-table-wrap table tfoot tr th,
  .pricing-table-wrap table thead tr th {
    padding: 20px;
    text-align: left;
    font-weight: 500;
  }
  .pricing-table-wrap table tfoot tr td,
  .pricing-table-wrap table thead tr td {
    padding: 20px;
    text-align: left;
    font-size: 1.25rem;
    font-weight: 700;
  }
  .pricing-table-wrap table tfoot tr th div,
  .pricing-table-wrap table thead tr th div {
    margin-bottom: 8px;
  }
  .pricing-table-header-subtitle {
    font-size: 0.875rem;
    font-weight: 400;
  }
  .pricing-table-header-price {
    font-size: 1.5rem;
  }

  /* ---------------------------------------------------------- */

  .foot-product,
  .header-product {
    border: 1px solid #e8eaed;
    background: #e8eaed;
  }
  .foot-product {
    border-radius: 0 0 0 5px;
  }
  .header-product {
    border-radius: 5px 0 0 0;
  }

  .header-free-plan {
    border: 1px solid #f6fcfe;
    background: #f6fcfe;
    /* border: 1px solid #e1f5fe;
    background: #e1f5fe; */
    /* color: #2e3a4d; */
  }

  .header-flipper-plan {
    border: 1px solid #4ec2f7;
    background: #4ec2f7;
    color: #fff;
  }

  .header-pro-plan {
    border: 1px solid #039be5;
    background: #039be5;
    color: #fff;
  }

  .footer-enterprise-plan,
  .header-enterprise-plan {
    border: 1px solid #2e3a4d;
    background: #2e3a4d;
    color: #fff;
  }
  .header-enterprise-plan {
    border-radius: 0 5px 0 0;
  }
  .footer-enterprise-plan {
    border-radius: 0 0 5px 0;
  }

  /* ---------------------------------------------------------- */

  .pricing-table-wrap table tbody tr th,
  .pricing-table-wrap table tbody tr td {
    box-sizing: content-box;
    /* border: 1px solid #eceff1; */
    color: #939eab;
  }
  .pricing-table-wrap table tbody tr th {
    padding: 20px 20px;
    font-weight: 400;
    text-align: left;
  }

  .pricing-table-wrap table tbody tr td {
    padding: 40px 20px 20px;
    font-weight: 500;
  }

  .pricing-table-wrap table tbody tr th div:not(:first-child) {
    margin: 16px 0;
    line-height: 24px;
  }

  .pricing-table-wrap table tbody tr td p,
  .pricing-table-wrap table tbody tr td div {
    font-size: 1rem;
    color: #757575;
    font-weight: 500;
    line-height: 24px;
  }
  .pricing-table-wrap table tbody tr td p {
    margin: 0;
  }
  .pricing-table-wrap table tbody tr td div {
    margin: 16px 0;
  }
  .pricing-table-wrap table tbody tr td div svg {
    margin: auto;
  }

  .table-Products-header,
  .header-product {
    font-weight: 700;
    font-size: 1rem;
    color: #323a42;
  }

  .table-Products-name {
    cursor: default;
    -webkit-transition: 0.05s ease-in-out;
    transition: 0.05s ease-in-out;
  }
  .table-Products-name:hover {
    color: rgb(223, 226, 230);
    fill: #939eab;
  }
  .table-Products-name > * {
    margin-left: 12px;
  }

  .table-content-free {
    background: #f6fcfe;
    color: #757575;
  }
  /* ---------------------------------------------------------- */

  .pricing-table-wrap table tfoot button {
    margin: 16px 0;
    height: 36px;
    padding: 6px 24px;
  }

  /* ---------------------------------------------------------- */
}

@media only screen and (min-width: 960px) {
  .pricing-table-wrap {
    margin: 56px 24px;
  }
}
@media only screen and (min-width: 1200px) {
  .pricing-table-wrap {
    margin: 72px 56px;
  }
  /* .pricing-table-wrap-free h3 {
    font-size: 2.75rem;
    margin-bottom: 16px;
  } */
}

.prising-header-links {
  padding: 20px;
  margin: 0 auto 60px;
  text-align: center;

  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
}

.prising-header-links a div {
  padding: 6px 8px;
  width: 180px;
}

.prising-header-links h4 {
  font-size: 1.25rem;
  margin: 0 0 8px;
  font-weight: 600;
}

.prising-header-links .producct-link {
  padding: 6px 8px;
  font-size: 1rem;
  color: #34a8fe;
  margin-bottom: 52px;
}
.product-button-container button {
  margin: 8px;
  width: 112px;
}

.product-icon-container {
  flex-wrap: wrap;
  justify-content: center;
}
.product-icon-container > * {
  margin: 8px;
  width: 54px;
  height: 54px;
  border: 8px solid #f6fcfe;
  border-radius: 100%;

  background: #34a8fe;
}
.product-icon-container svg {
  width: 24px;
}

@media only screen and (min-width: 768px) {
  .prising-header-links {
    grid-template-columns: repeat(3, 1fr);
  }
  .prising-header-links div div {
    width: 200px;
  }

  .prising-header-links h4 {
    font-size: 1.25rem;
  }
  .prising-header-links .producct-link {
    margin-bottom: 0;
  }
  .product-icon-container > * {
    width: 62px;
    height: 62px;
  }
}

@media only screen and (min-width: 960px) {
  .prising-header-links {
    margin: 0 32px 60px;
  }
}

.prodbox-container {
  text-align: left;
  list-style-type: none;
  position: relative;
  padding-bottom: 44px;
}

.prodbox-container .product-page-anchor {
  position: relative;
  top: -100px;
}

.prodbox-icon-wrap {
  height: 60px;
  width: 60px;
  border: 8px solid #f6fcfe;
  border-radius: 100%;
  margin-bottom: 8px;
  background: #34a8fe;
}

.prodbox-container h2 {
  height: 24px;
  font-size: 1rem;
  font-weight: 500;
  padding: 4px 0 12px;
}
.prodbox-plans-wrap {
  height: 24px;
  margin-bottom: 16px;
  color: #b5c0c5;
  font-weight: 400;
}
.prodbox-blan-box {
  font-size: 0.625rem;
  border: 1px solid #b5c0c5;
  padding: 4px 6px;
  margin-right: 8px;
  border-radius: 4px;
  cursor: pointer;
  -webkit-transition: 0.1s ease-in-out;
  transition: 0.1s ease-in-out;
}
.prodbox-blan-box:hover {
  border: 1px solid #34a8fe;
  color: #34a8fe;
}

.prodbox-container p {
  font-size: 0.75rem;
  color: #757575;
  font-weight: 400;
  margin-right: 12px;
}
.prodbox-link-wrap {
  margin: 24px 0 0;
  height: 36px;
}

.prodbox-link-wrap a {
  padding: 6px 8px;
  color: #34a8fe;
  margin: 0 16px 0 -8px;
}
.prodbox-link-pricing a {
  color: #757575;
}
.prodbox-link-pricing a div {
  margin-right: 8px;
}

.prodbox-divLine {
  width: 100%;
  padding: 0 0 44px;
  position: absolute;
  bottom: 0;
}
.prodbox-blan-box {
  font-size: 0.625rem;
  border: 1px solid #b5c0c5;
  padding: 4px 6px;
  margin-right: 8px;
  border-radius: px;
}

@media only screen and (min-width: 768px) {
  .prodbox-container h2 {
    font-size: 1.25rem;
  }

  .prodbox-plans-wrap {
    font-size: 0.875rem;
  }
  .prodbox-link-wrap {
    margin: 24px 0 40px;
  }
  .prodbox-divLine {
    width: 100%;
    padding: 40px 0 44px;
  }
  .prodbox-container p {
    font-size: 0.875rem;
    margin-right: 24px;
  }
  .prodbox-divLine > * {
    box-sizing: border-box;
    border-top: 1px solid #eceff1;
  }
}

.ProductGrid-container {
  margin: auto;
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 0;
}
.ProductGrid-container li {
  padding-right: 0;
}

@media only screen and (min-width: 768px) {
  .ProductGrid-container {
    grid-template-columns: auto auto;
    margin-top: 24px;
  }
}

@media only screen and (min-width: 1200px) {
  .ProductGrid-container {
    grid-template-columns: auto auto auto;
    margin-top: 32px;
  }

  .ProductGrid-container li:not(:nth-child(3n)) {
    padding-right: 80px;
  }

  .ProductGrid-container li:not(:nth-child(3n)) .prodbox-divLine div {
    width: Calc(100% + 80px);
  }

  .ProductGrid-container
    li:nth-child(3n + 1):nth-last-child(-n + 3)
    .prodbox-divLine
    div,
  .ProductGrid-container
    li:nth-child(3n + 1):nth-last-child(-n + 3)
    ~ li
    .prodbox-divLine
    div {
    border-top: 1px transparent;
  }
}

@media (max-width: 1200px) and (min-width: 768px) {
  .ProductGrid-container li:not(:nth-child(2n)) {
    padding-right: 80px;
  }
  .ProductGrid-container li:not(:nth-child(2n)) .prodbox-divLine div {
    width: Calc(100% + 80px);
  }

  .ProductGrid-container
    li:nth-child(2n + 1):nth-last-child(-n + 2)
    .prodbox-divLine
    div,
  .ProductGrid-container
    li:nth-child(2n + 1):nth-last-child(-n + 2)
    ~ li
    .prodbox-divLine
    div {
    border-top: 1px transparent;
  }
}

.allpage-header {
  margin: auto;
  padding: 32px 0;
  text-align: center;
  /* min-width: 312px; */
  box-sizing: border-box;
  align-items: flex-start;
}
.allpage-header-image {
  flex-direction: column;
  align-items: center;
}

.allpage-heater-partition-rit,
.allpage-heater-partition-lft {
  width: 80%;
}

.allpage-heater-image {
  padding-top: 32px;
}

.allpage-header h5 {
  font-size: 0.875rem;
  line-height: 1.75rem;
  margin: 0;
  /* font-weight: 600; */
  /* color: #939eab; */
  /* color: #757575; */
  /* color: #b5c0c5 */
  color: #34a8fe;
}
.allpage-header h1 {
  /* 28px */
  font-size: 1.75rem;
  line-height: 2rem;
  margin: 8px 0;
  font-weight: 600;
  color: #fff;
}

@media only screen and (min-width: 768px) {
  .allpage-header {
    margin: 0;
    padding: 56px 24px 64px;
    text-align: left;
    /* max-width: 50% */
  }
  .allpage-header-image {
    flex-direction: row;
    align-items: flex-start;
  }
  .allpage-heater-partition-rit {
    width: 40%;
  }
  .allpage-heater-partition-lft {
    width: 60%;
  }

  .allpage-header h5 {
    /* font-size: 1.5rem;
    line-height: 1.75rem;
    margin: 8px 0;
    font-weight: 400; */
    font-size: 1.375rem;
    line-height: 1.75rem;
    margin: 8px 0;
    font-weight: 400;
  }
  .allpage-header h1 {
    font-size: 1.75rem;
    line-height: 2rem;
    margin: 0 0 24px;
  }
}

@media only screen and (min-width: 960px) {
  .allpage-header {
    padding: 56px 40px 64px;
  }
  /* .allpage-header h1 {
    font-size: 3rem;
    line-height: 3.5rem;
    font-weight: 600;
  } */
  .allpage-header h1 {
    font-size: 2.75rem;
    line-height: 3.375rem;
    font-weight: 600;
  }
}

@media only screen and (min-width: 1200px) {
  .allpage-header {
    padding: 56px 72px 56px;
  }
}

.pricing-mobile-section {
  border-radius: 5px;
  border: 1px solid #eceff1;
  margin-bottom: 24px;
}

.pricing-mobile-section-foot,
.pricing-mobile-section-head {
  padding: 16px;
  text-align: center;
}

.pricing-mobile-section-head {
  border-radius: 5px 5px 0 0;
  color: #fff;
}
.pricing-mobile-section-head > * {
  margin-bottom: 8px;
  font-size: 0.875rem;
  font-weight: 400;
  margin-bottom: 12px;
}
.pricing-mobile-section-head > *:first-child {
  font-size: 1.5rem;
  font-weight: 700;
  margin: 8px 0;
}
.pricing-mobile-section-head > *:last-child {
  font-size: 1.5rem;
  font-weight: 500;
}
.pricing-mobile-section-body {
  padding: 16px 0;
}
.pricing-mobile-section-foot {
  border-radius: 0 0 5px 5px;
}
.pricing-mobile-section-foot button {
  padding: 11.5px 24px;
  min-width: 50%;
}

.mobile-seciton-item {
  width: 100%;
  text-align: left;
  padding: 0 8px;
}

.mobile-seciton-item-head {
  height: 48px;
  padding: 8px;
  box-sizing: content-box;
  cursor: pointer;
}
.mobile-seciton-item-head h4 {
  /* max-width: Calc(100% - 66px); */
}

.mobile-seciton-item-head-icon {
  height: 40px;
  width: 40px;
  border-radius: 100%;
  margin-right: 12px;
}

.mobile-seciton-item-body {
  -webkit-transition: 0.15s ease-in-out;
  transition: 0.15s ease-in-out;
  padding: 8px 0 0 16px;
  color: #757575;
  font-size: 0.875rem;
  overflow: hidden;
  max-width: 550px;
}
.mobile-seciton-item-body div {
  margin-bottom: 8px;
}
.mobile-seciton-item-body div b {
  color: rgb(52, 168, 254);
  font-weight: 600;
}
.mobile-seciton-item-body div span {
  color: #323a42;
  font-size: 0.75rem;
  color: #757575;
  font-weight: 400;
  margin-right: 12px;
}

.pricing-table-wrap-free {
  max-width: 650px;
  margin: 0 auto;
  padding: 0 0 32px;
  text-align: center;
}
.pricing-table-wrap-free h3 {
  font-size: 2rem;
  font-weight: 600;
  line-height: 54px;
  margin: 32px 0 8px;
}
.pricing-table-wrap-free p {
  font-size: 0.875rem;
  color: #757575;
  margin-bottom: 24px;
}
.pricing-table-wrap-free a {
  color: rgb(52, 168, 254);
}


@media only screen and (min-width: 768px) {
  .pricing-table-wrap-free p {
    font-size: 1rem;
    line-height: 28px;
  }
}

@media only screen and (min-width: 960px) {

}

@media only screen and (min-width: 1200px) {
  .pricing-table-wrap-free h3 {
    font-size: 2.75rem;
    /* font-weight: 500; */
    margin-bottom: 16px;
  }
}

.support-page-wrap {
  margin: 0 16px;
}
.support-card {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.24);
  margin-bottom: 24px;
}

.support-card-head,
.support-card-footer,
.support-card-body-header,
.support-card-body-post {
  padding: 0 8px;
}

.support-card-head,
.support-card-footer {
  height: 56px;
  width: 100%;
  background: #f5f5f5;
}
.support-card-head {
  border-bottom: 1px solid #e0e0e0;
  position: relative;
  box-sizing: border-box;
}
.support-card-head form {
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  color: #989898;
  cursor: text;
}

.support-card-head button {
  margin-left: 16px;
}

.support-card-head-search {
  width: 100%;
}
.support-card-head-search svg {
  position: absolute;
}
.support-card-head-search input {
  width: 100%;
  height: 36px;
  padding-left: 32px;
  outline: none;
  border: none;
  background: none;
  box-sizing: border-box;
  -webkit-transition: 0.15s ease-in-out;
  transition: 0.15s ease-in-out;
}
.support-card-head-search input:focus {
  position: absolute;
  width: Calc(100% - 16px);
  left: 8px;
  padding-left: 8px;
  outline: none;
  border: none;
  background: #fff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.24);
}

.support-card-body-header,
.support-card-body-post {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 56px;
}

.support-card-body-header {
  border-bottom: 1px solid #e0e0e0;
  height: 32px;
  width: 100%;
  background: #f5f5f5;
}

.support-card-footer h6,
.support-card-body-header h6 {
  font-size: 0.875rem;
  font-weight: 500;
  color: #707070;
  margin: 0;
}

.support-card-body-post {
  /* height: 48px; */
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
}
.support-card-body-post > *:last-child {
  margin: auto;
}
.support-card-body-post h3,
.support-card-body-post p {
  margin: 14px 0;
  font-size: 0.875rem;
}

.support-card-footer {
  border-top: 1px solid #e0e0e0;
}
.support-card-footer button,
.support-card-footer h6 {
  margin-left: 16px;
}

/* Ticketing */
.ticket-container {
  border-radius: 5px;
  padding: 24px 16px;
  margin-bottom: 32px;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.ticket-container h5,
.ticket-container p,
.ticket-button button {
  position: relative;
  z-index: 6;
}

.ticket-container h5 {
  font-size: 1.5rem;
  margin-bottom: 8px;
  font-weight: 700;
}
.ticket-container p {
  color: #939eab;
}
.ticket-card-bubble {
  width: 70%;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  position: absolute;
  top: -100%;
  right: -30%;
  z-index: 5;
}
.ticket-button {
  justify-content: center;
}
.ticket-button button {
  height: 40px;
}

.ticket-button button svg {
  margin: 0 0 0 12px;
}

@media only screen and (min-width: 660px) {
  /* .support-header-wrapped {
    width: 50%;
  }
  .support-header-wrapped svg {
    margin-top: 0;
  }
  .support-header {
    flex-direction: row;
    text-align: left;
  } */

  .support-card-head-search input {
    padding-left: 36px;
  }
  .support-card-head-search input:focus {
    width: Calc(100% - 32px);
    left: 16px;
    padding-left: 16px;
  }

  .ticket-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
  }
  .ticket-text {
    width: 60%;
  }
  .ticket-button {
    width: 30%;
  }
}

@media only screen and (min-width: 768px) {
  .support-card {
    margin-bottom: 48px;
  }

  .support-card-head,
  .support-card-footer,
  .support-card-body-header,
  .support-card-body-post {
    padding: 0 24px;
  }

  .pricing-page-wrap {
    margin: 0;
  }
  .pricing-table-wrap {
    margin: 8px;
  }
  .support-card-body-header,
  .support-card-body-post {
    grid-template-columns: 1fr 160px 56px;
  }
}

@media only screen and (min-width: 960px) {
  .support-card {
    margin-bottom: 48px;
  }

  .support-card-head,
  .support-card-footer,
  .support-card-body-header,
  .support-card-body-post {
    padding: 0 40px;
  }

  .pricing-table-wrap {
    margin: 56px 24px;
  }
}
@media only screen and (min-width: 1200px) {
  .support-card-head,
  .support-card-footer,
  .support-card-body-header,
  .support-card-body-post {
    padding: 0 72px;
  }

  .pricing-table-wrap {
    margin: 72px 56px;
  }
}


.header-banner-container {
  padding: 0 12px;
}

.usr-banner-top-container {
  padding: 24px 0 32px;
  width: 100%;
  flex-direction: column-reverse;
}

.usr-ban-profile-info {
  display: flex;
  justify-content: flex-start;
  margin-top: -16px;
  width: 100%;
}
.usr-ban-profile-info > *:first-child {
  margin-right: 18px;
}

.usr-ban-profile-info-imgwrap {
  position: relative;
}

.usr-ban-profile-info-contrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.usr-ban-profile-info-contrap h1 {
  color: white;
  margin: 6px 0 0;
}

.usr-ban-profile-info-contrap > *:first-child:first-child {
  color: white;
}

.usr-ban-profile-info-imgwrap img {
  border-radius: 100%;
  border: 6px solid #28323c;
  height: 96px;
  width: 96px;
  display: block;
  margin-left: -6px;
  object-fit: cover;
}
.usr-ban-profile-info-imgwrap-badge {
  position: absolute;
  width: 24px;
  height: 24px;
  left: 78px;
  top: 78px;
  border-radius: 100%;
  background: #ffffff;
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.09);
}

.banner-feature-container {
  width: 100%;
  height: 96px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 0 32px;
  justify-content: space-between;
}

.banner-feature-wrap {
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.banner-feature-wrap > *:last-child {
  opacity: 0.5;
  font-size: 0.75rem;
  font-weight: 300;
  margin-left: 0;
}

.banner-feature-div {
  width: 0px;
  height: 33px;
  border-left: 0.5px solid #8093a2;
  margin: 0 24px;
}

.usr-banner-bottom-container {
  height: 48px;
}
.usr-banner-bottom-buttons button:not(:last-child) {
  margin-right: 8px;
}

.usr-banner-bottom-buttons button {
  height: 36px;
  padding: 0 12px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  outline: none;
  border: none;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 19px;
  display: flex;
  align-items: center;
  cursor: pointer;
  -webkit-transition: 0.1s ease-in;
  transition: 0.1s ease-in;
}
.usr-banner-bottom-buttons button:hover {
  background: rgba(255, 255, 255, 0.2);
}
.usr-banner-bottom-buttons button svg {
  width: 24px;
  height: 24px;
}

@media only screen and (min-width: 768px) {
  .header-banner-container {
    padding: 0;
  }
  .usr-banner-top-container {
    flex-direction: row;
    width: auto;
  }
  .usr-ban-profile-info {
    margin-top: 0;
  }
  .usr-ban-profile-info-imgwrap img {
    border: 6px solid rgba(255, 255, 255, 0.1);
  }
  .usr-ban-profile-info-contrap {
    justify-content: space-between;
  }
  .banner-feature-container {
    width: auto;
  }
}

.home-topic-container {
  /* height: 120px; */
}

/* .posts-header-lin {
  width: 100%;
  border-bottom: 1px solid #939eab;
} */

@media only screen and (min-width: 768px) {
  .posts-container {
    grid-column: 1;
  }
}

.form-card-container section {
  max-width: 940px;
}

.pricing-table-wrap-free {
  width: 100%;
}
.question-wrapper {
  text-align: left;
  margin: auto;
  /* max-width: 300px; */
  padding-bottom: 32px;
}
.question-wrapper p {
  color: inherit;
  margin-bottom: 8px;
  font-size: 1rem;
}

.question-answer-wrapper {
  margin: 8px 0 8px 8px;
}

.input-wrapper {
  margin-bottom: 32px;
  display: grid;
  grid-gap: 24px;
  grid-template-columns: 1fr;
}

.input-text {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  font-size: 0.75rem;
  color: #323a42;
}

.input-text input,
.input-text textarea,
.input-text select {
  box-sizing: border-box;
  margin-top: 4px;
  outline: none;
  border: 1px solid #cfd8dc;
  border-radius: 5px;
  width: 100%;
}
.input-text input,
.input-text select {
  height: 36px;
}
.input-text select,
#emailinput {
  padding: 6px 12px;
}
.input-text textarea {
  height: 200px;
  width: 100%;
  font-family: inherit;
  padding: 6px 12px;
}

.question-answer-wrapper input,
.question-answer-wrapper textarea,
.question-answer-wrapper select {
  margin: 8px;
}

.autocomplet-drop-shadow {
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
}

.form-input-suggestions {
  text-align: left;
  border-radius: 5px;

  /* padding: 12px; */
  font-size: 0.875rem;
  color: #757575;
  box-sizing: border-box;
}
.form-input-suggestions > * {
  padding: 8px 12px;
  box-sizing: border-box;
  margin: 2px 0;
}
.form-input-suggestions > *:hover {
  color: #34a8fe;
  background: #f4f6f8;
}
.form-input-suggestions > *:first-child {
  padding-top: 16px;

  border-radius: 5px 5px 0 0;
}
.form-input-suggestions > *:last-child {
  padding-bottom: 16px;
  border-radius: 0 0 5px 5px;
}

.question-answer-wrapper label {
  margin-left: 8px;
  text-align: left;
  color: #757575;
  display: inline-block;
  font-size: 0.875rem;
}

.suportMsg {
  align-items: flex-start;
}
.suportMsg > * {
  min-width: 24px;
}
.suportMsg > *:first-child {
  margin-right: 8px;
}
.beta-form-legal,
.suportMsg p {
  text-align: left;
  font-size: 0.875rem;
  color: #757575;
  line-height: 22px;
}

@media only screen and (min-width: 768px) {
  .input-wrapper {
    grid-template-columns: 1fr 1fr;
  }
}
@media only screen and (min-width: 1200px) {
  .pricing-table-wrap {
    margin: 72px 56px;
  }
}

.plans-widget-container {
  padding: 16px 0;
}

.plan-item-container {
  margin: 12px auto;
  height: 72px;
  box-sizing: border-box;
  padding: 0 16px;
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  -webkit-transition: 0.1s ease-out;
  transition: 0.1s ease-out;
  cursor: pointer;
}

.plan-item-container:hover {
  border: 1px solid #34a8fe;
  /* background: rgba(52, 168, 254, 0.1); */
}

.plan-item-diabled {
  cursor: default;
  pointer-events: none;
}
.plan-item-diabled:hover {
  border: 1px solid rgba(255, 255, 255, 0.1);
}
.active-plan,
.active-plan:hover {
  border: 1px solid #34a8fe;
  background: rgba(52, 168, 254, 0.1);
}

.plan-select-button-icon-wrap {
  width: 36px;
  height: 36px;
  border-radius: 100%;
  background: rgba(255, 255, 255, 0.1);
  -webkit-transition: 0.1s ease-out;
  transition: 0.1s ease-out;
}

.active-plan .plan-select-button-icon-wrap {
  background: #34a8fe;
}

.plan-select-desc {
  text-align: left;
  margin-left: 16px;
}
.plan-select-pricing {
  text-align: right;
}
.plan-select-desc > *,
.plan-select-pricing > * {
  margin: 0;
}
.plan-select-desc h3,
.plan-select-pricing h3 {
  font-size: 1.09375rem;
  font-weight: 500;
  color: #fff;
}

.plan-item-diabled h3,
.plan-item-diabled p,
.plan-item-diabled svg path {
  color: rgba(255, 255, 255, 0.1);
  fill: #28323c;
}
.plan-select-desc p,
.plan-select-pricing p {
  font-size: 0.75rem;
}

.forum-content {
  min-height: 100%;
  position: relative;
  overflow: hidden;
}

.post-grid-container {
  display: grid;
  grid-template-columns: 1fr;
  padding: 16px 8px 80px;
  margin: 0 auto;
  box-sizing: border-box;
  width: 100%;
  min-height: 100%;
}

/* //------------------------------------------------------------------------ */
.post-sidebar-container {
  display: none;
  /* animation: slidein-trending 0.75s cubic-bezier(0.25, 1, 0.5, 1) normal
    forwards;
  opacity: 0; */
}
.post-sidebar-wrap {
  -webkit-animation: slidein-trending 0.75s cubic-bezier(0.25, 1, 0.5, 1) normal
    forwards;
          animation: slidein-trending 0.75s cubic-bezier(0.25, 1, 0.5, 1) normal
    forwards;
  opacity: 0;
}

@-webkit-keyframes slidein-trending {
  0% {
    opacity: 0;
    -webkit-transform: translateX(50%);
            transform: translateX(50%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes slidein-trending {
  0% {
    opacity: 0;
    -webkit-transform: translateX(50%);
            transform: translateX(50%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

/* //------------------------------------------------------------------------ */
.post-wrapper {
  height: 100%;
  position: relative;
  box-sizing: border-box;
  background: #fff;
  /* width: Calc(100% - 16px); */
  max-width: 100%;

  padding: 32px 16px;
  border-radius: 10px;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
}

.post-back {
  position: fixed;
  top: 0;
  width: 100%;
  /* height: 500px; */
  height: 33vh;
  background: #f4f6f8;
}

.post-page {
  max-width: 50em;
  margin: auto;
}

.post-page h1 {
  font-size: 1.375rem;
  margin: 20px 0 8px;
}

.post-page h2 {
  font-size: 1.25rem;
  font-weight: 500;
  margin: 0 0 2rem;
}
.post-page h3 {
  margin: 0;
  font-size: 0.75rem;
  line-height: 1.5rem;
  color: #b1b4c7;
  font-style: italic;
}

.post-page h6 {
  margin: 0;
  font-size: 0.75rem;
  color: #b1b4c7;
  font-weight: 400;
}
.post-page p {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 300;
}
.post-page img {
  border-radius: 5px;
  width: 100%;
}
.post-page a {
  color: #039be5;
}
.post-page-post-sub {
  margin: 2px 0 16px;
}
.post-page-post-sub > *:not(:first-child) {
  margin: 0 0 0 8px;
}

.post-page-more-section {
  margin-top: 24px;
}

.post-page-author {
  color: #34a8fe;
}
.post-page-author img {
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 5px;
  overflow: hidden;
  margin-right: 8px;
  display: block;
}

.post-location-tag {
  font-size: 0.875rem;
  color: #b1b4c7;
  font-weight: 400;
}
.post-location-tag > *:first-child {
  margin-right: 8px;
}

/* COMMENT CREATE-------------------------------------------------------- */
.post-page-comment-sectoin {
  max-width: 100%;
}
.post-page-comments-sectoin-coments {
  max-width: 100%;
  /* border: 1px solid red; */
}

/* COMMENTS----------------------------------------------------------------- */
.post-page-comment-wrap > *:first-child {
  display: inline-flex;
  width: 100%;
}
.comment-vote-wrap {
  text-align: center;
  align-self: flex-start;
}
.post-page-comment-wrap .comment-vote-wrap {
  margin: 0;
  font-weight: 400;
  text-align: center;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: #b1b4c7;
}

.commment-buttons-wrap {
  display: inline-flex;
  align-items: center;
}
/* .commment-buttons-wrap > *:not(:first-child) {
  margin-left: 12px;
} */

.post-page-header button,
.comment-vote-wrap button {
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 6px;
  margin: -6px;
  border-radius: 5px;
  box-sizing: border-box;
  -webkit-transition: 0.1s ease;
  transition: 0.1s ease;
}
.comment-vote-wrap button:hover,
.comment-vote-wrap button:hover svg path {
  /* background: rgba(52, 168, 254, 0.1); */
  fill: #34a8fe;
}

.comment-cont-wrap {
  width: 100%;
  padding-left: 16px;
  display: flex;
  flex-direction: column;
}
.comment-cont-wrap > *:first-child {
  height: 100%;
}

.comment-user-profile-wrap {
  color: #34a8fe;
  font-weight: 400;
}

.comment-user-profile-wrap img:hover {
  background: rgba(52, 168, 254, 0.1);
}

.comment-user-profile-wrap img {
  height: 24px;
  width: 24px;
  object-fit: cover;
  border-radius: 5px;
  overflow: hidden;
  margin: 0 8px 0 0;
  display: block;
}

.comment-comment-wrap {
  margin: 12px 0 12px 12px;
  border-left: 1px solid #eceff1;
  padding-left: 12px;
  font-weight: 300;
}

@media only screen and (min-width: 768px) {
  .post-grid-container {
    padding: 24px 0;
  }
  .post-wrapper {
    width: 100%;
    padding: 48px 32px;
  }
  .post-page h1 {
    font-size: 2rem;
    margin: 24px 0 8px;
  }
  .post-page h3 {
    margin: 0;
    font-size: 0.75rem;
    color: #b1b4c7;
    font-style: italic;
  }

  .post-page h6 {
    margin: 0;
    font-size: 0.875rem;
    color: #b1b4c7;
    font-weight: 400;
  }
  .post-page p {
    font-size: 1rem;
    line-height: 1.75rem;
    font-weight: 300;
  }
  .post-page-post-sub {
    margin: 0 0 24px;
  }
}

@media only screen and (min-width: 960px) {
  .post-grid-container {
    margin: 24px 0 80px;
    padding: 0;
    grid-template-columns: 1fr 256px;
    grid-gap: 24px;
  }
  .post-grid-container {
  }

  /* POST--------------------------------------------------------- */
  .post-wrapper {
    grid-column: 1;
  }
  /* MORE--------------------------------------------------------- */
  .post-sidebar-container {
    display: block;
    grid-column: 2;
    flex-direction: column;
    position: fixed;
    width: 256px;
    z-index: 99;
  }
  .post-sidebar-container p {
    margin: 0;
    color: #b1b4c7;
    font-size: 0.75rem;
  }

  .post-sidebar-wrap {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    flex-direction: column;
    margin-bottom: 16px;
  }
  .post-sidebar-wrap > *:nth-child(2) {
    margin-top: 16px;
  }
  .post-sidebar-wrap > *:nth-last-child(2) {
    margin-bottom: 12px;
  }
  .post-sidebar-wrap h4 {
    width: 100%;
    font-size: 0.875rem;
    font-weight: 600;
    /* background: rgba(148, 156, 166, 0.1); */

    border-bottom: 1px solid #eceff1;
    box-sizing: border-box;
    padding: 10px 16px;
  }
  /* TRENDINGPOSTS----------------------------------- */
  .post-trending-wrap,
  .trendig-group-cont {
    margin: 8px 16px;
    -webkit-transition: 0.1s ease-in-out;
    transition: 0.1s ease-in-out;
    border-radius: 5px;
    cursor: pointer;
    color: #28323c;
  }
  .post-trending-wrap:hover,
  .trendig-group-cont:hover {
    /* background: #f9fafb; */
    color: #34a8fe;
  }

  .post-sidebar-wrap h3 {
    font-size: 0.875rem;
    font-weight: 500;
  }
  .more-container .more-container-wrap p {
    margin: 0;
    color: #b1b4c7;
    font-size: 0.75rem;
  }
  .more-container-wrap img {
    object-fit: cover;
  }
  .post-trending-wrap img {
    width: 54px;
    min-width: 54px;
    height: 54px;
    border-radius: 5px;
    margin-left: 16px;
    object-fit: cover;
  }
  .trending-post-wrap {
    border-top: 1px solid #eceff1;
    padding: 9px 16px;
    font-size: 0.75rem;
    color: #b1b4c7;
    -webkit-transition: 0.1s ease;
    transition: 0.1s ease;
    /* background: #f9fafb; */
    /* border-radius: 0 0 5px 5px; */
  }
  .trending-post-wrap:hover {
    color: #34a8fe;
  }
}

