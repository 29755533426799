.more-container-wrap {
  position: fixed;
  width: 256px;
  z-index: 99;
}
.forum-more-legal {
  margin: -8px auto;
  font-size: 0.75rem;
  color: rgba(96, 103, 110, 0.5);
  justify-content: center;
}
.forum-more-legal a {
  margin: 8px;
  cursor: pointer;
  text-align: center;
}
.forum-more-legal a:hover {
  color: #34a8fe;
}

@media only screen and (min-width: 768px) {
  .more-container-wrap > * {
    margin-bottom: 12px;
    animation: slidein-rit 0.75s cubic-bezier(0.25, 1, 0.5, 1) normal forwards;
  }

  @keyframes slidein-rit {
    0% {
      opacity: 0;
      transform: translatex(20vw);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .more-subcat {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 20px;
    color: rgba(96, 103, 110, 0.5);
    padding-left: 16px;
  }
  .forum-more-container {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    flex-direction: column;
  }
  .forum-more-container > *:nth-child(2) {
    margin-top: 16px;
  }
  .forum-more-container > *:nth-last-child(2) {
    margin-bottom: 12px;
  }
  .forum-more-container h4 {
    width: 100%;
    font-size: 0.875rem;
    font-weight: 600;
    /* background: rgba(148, 156, 166, 0.1); */

    border-bottom: 1px solid #eceff1;
    box-sizing: border-box;
    padding: 10px 16px;
  }

  /* .forum-more-container > * :last-child {
    margin-bottom: 8px;
  } */

  /* TRENDINGPOSTS----------------------------------- */
  .trendig-post-cont,
  .trendig-group-cont {
    margin: 8px 16px;
    transition: 0.1s ease-in-out;
    border-radius: 5px;
    cursor: pointer;
    color: #28323c;
  }
  .trendig-post-cont:hover,
  .trendig-group-cont:hover {
    /* background: #f9fafb; */
    color: #34a8fe;
  }

  .more-container-wrap h3 {
    font-size: 0.875rem;
    font-weight: 500;
  }
  .more-container .more-container-wrap p {
    margin: 0;
    color: #b1b4c7;
    font-size: 0.75rem;
  }
  .more-container-wrap img {
    object-fit: cover;
  }
  .trendig-post-cont img {
    width: 54px;
    min-width: 54px;
    height: 54px;
    border-radius: 5px;
    margin-left: 16px;
  }
  .trending-more-wrap {
    border-top: 1px solid #eceff1;
    padding: 9px 16px;
    font-size: 0.75rem;
    color: #b1b4c7;
    transition: 0.1s ease;
    /* background: #f9fafb; */
    /* border-radius: 0 0 5px 5px; */
  }
  .trending-more-wrap:hover {
    color: #34a8fe;
  }

  /* TRENDING GROUPS--------------------------------------- */
  .trendig-group-cont img {
    width: 36px;
    height: 36px;
    min-width: 36px;
    border-radius: 100%;
    margin-right: 16px;
    border: 1px solid rgba(148, 156, 166, 0.1);
  }
  .trendig-group-cont p {
    line-height: 1rem;
  }
  .more-col-follow-btn {
    font-size: 0.75rem;
    border-radius: 5px;
    height: 24px;
    padding: 0 12px;
    border: 1px solid #34a8fe;
    color: #34a8fe;
    margin: 0;
    transition: 0.1s ease;
  }
  .more-col-follow-btn:hover {
    background: rgba(52, 168, 254, 0.1);
  }
}

@media only screen and (min-width: 960px) {
}
@media only screen and (min-width: 1200px) {
}
