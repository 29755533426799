.prising-header-links {
  padding: 20px;
  margin: 0 auto 60px;
  text-align: center;

  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
}

.prising-header-links a div {
  padding: 6px 8px;
  width: 180px;
}

.prising-header-links h4 {
  font-size: 1.25rem;
  margin: 0 0 8px;
  font-weight: 600;
}

.prising-header-links .producct-link {
  padding: 6px 8px;
  font-size: 1rem;
  color: #34a8fe;
  margin-bottom: 52px;
}
.product-button-container button {
  margin: 8px;
  width: 112px;
}

.product-icon-container {
  flex-wrap: wrap;
  justify-content: center;
}
.product-icon-container > * {
  margin: 8px;
  width: 54px;
  height: 54px;
  border: 8px solid #f6fcfe;
  border-radius: 100%;

  background: #34a8fe;
}
.product-icon-container svg {
  width: 24px;
}

@media only screen and (min-width: 768px) {
  .prising-header-links {
    grid-template-columns: repeat(3, 1fr);
  }
  .prising-header-links div div {
    width: 200px;
  }

  .prising-header-links h4 {
    font-size: 1.25rem;
  }
  .prising-header-links .producct-link {
    margin-bottom: 0;
  }
  .product-icon-container > * {
    width: 62px;
    height: 62px;
  }
}

@media only screen and (min-width: 960px) {
  .prising-header-links {
    margin: 0 32px 60px;
  }
}
