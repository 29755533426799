.forum-content {
  min-height: 100%;
  position: relative;
  /* z-index: 50; */
  overflow-x: hidden;
}
.forum-wrap {
  padding: 16px 16px 80px;
  margin: 0 auto;
  box-sizing: border-box;
}
.forum-grid {
  display: grid;
  grid-template-columns: 1fr;
}

/* FILTER--------------------------------------------------------- */
.filter-container {
  display: none;
  animation: slidein-left 0.75s cubic-bezier(0.25, 1, 0.5, 1) normal forwards;
  opacity: 0;
}

@keyframes slidein-left {
  0% {
    opacity: 0;
    transform: translatex(-50%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
/* HEADER--------------------------------------------------------- */
.posts-section-header {
  height: 40px;
  margin-bottom: 12px;
}

.posts-section-header-button {
  height: 36px;
  padding: 0 6px;
  border-radius: 5px;
  color: #939eab;
  font-weight: 500;
  cursor: pointer;
  transition: background 0.15s ease-out;
}

.posts-section-header-button-focus {
  height: 36px;
  padding: 0 16px;
  border-radius: 5px;
  color: #939eab;
  font-weight: 500;
  cursor: pointer;
  transition: 0.1s ease;
}
.posts-section-header-button:hover,
.posts-section-header-button-focus:hover {
  background: rgba(147, 158, 171, 0.1);
}
.posts-section-header-button-focus > *:first-child {
  margin-right: 12px;
}

/* MORE--------------------------------------------------------- */

.forum-more-legal {
  margin: -20px auto;
  font-size: 0.75rem;
  color: rgba(96, 103, 110, 0.5);
  justify-content: center;
}
.forum-more-legal a {
  margin: 8px;
  cursor: pointer;
  text-align: center;
}
.forum-more-legal a:hover {
  color: #34a8fe;
}

@media only screen and (min-width: 768px) {
  .forum-wrap {
    padding: 0 0 80px;
    margin-top: 24px;
  }
  .forum-grid {
    grid-template-columns: 1fr 256px;
    grid-gap: 24px;
  }

  /* HEADER--------------------------------------------------------- */
  .posts-section-header-button {
    padding: 0 16px;
  }
  .posts-section-header-button > *:first-child {
    margin-right: 12px;
  }

  /* POST--------------------------------------------------------- */
  .post-container {
    grid-column: 1;
  }
  /* MORE--------------------------------------------------------- */
  .more-container {
    grid-column: 2;
    flex-direction: column;
  }
}

@media only screen and (min-width: 960px) {
}
@media only screen and (min-width: 1200px) {
  .forum-grid {
    grid-template-columns: 210px 1fr 256px;
  }
  .post-filter-activation {
    display: none;
  }
  /* FILTER--------------------------------------------------------- */
  .filter-container {
    grid-column: 1;
    display: block;
  }

  /* POST--------------------------------------------------------- */
  .post-container {
    grid-column: 2;
  }

  /* MORE--------------------------------------------------------- */
  .more-container {
    grid-column: 3;
  }
}
