.draftJsMentionPlugin__mentionSuggestions__2DWjA {
  position: absolute;
  border-radius: 5px;
  padding: 8px 0;
  background: #fff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.12), 0px 3px 6px rgba(0, 0, 0, 0.09);
  max-width: 250px;
  min-width: 160px;
}

.draftJsMentionPlugin__mentionSuggestionsEntry__3mSwm {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  /* transition: 0.1s ease-in-out; */
  padding: 4px 8px;
  cursor: pointer;
  min-height: 32px;
  min-width: 120px;
}
.draftJsMentionPlugin__mentionSuggestionsEntryFocused__3LcTd {
  background: rgba(52, 168, 254, 0.1);
  color: #34a8fe;
}

/* .draftJsMentionPlugin__mentionSuggestionsEntryAvatar__1xgA9 */
.draftJsMentionPlugin__mentionSuggestions__2DWjA
  .draftJsMentionPlugin__mentionSuggestionsEntry__3mSwm
  img {
  width: 32px;
  height: 32px;
  border-radius: 5px;
  margin-right: 16px;
}

.draftJsMentionPlugin__mentionSuggestionsEntryText__3Jobq {
}
