.Back-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  z-index: -1;
  overflow: hidden;
}
.Back-wrap {
  position: relative;
  min-height: 100%;
}

.Back-wrap .back-blob-1 {
  position: absolute;
  top: -3640px;
  left: -650px;
}

.back-blob-2 {
  position: absolute;
  bottom: -200px;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
}
.Back-wrap .back-blob-3 {
  position: absolute;
  top: 3500px;
  transform: translateX(-40%) rotate(-5deg) scaleX(-1);
}

.Back-wrap .back-blob-4 {
  position: absolute;
  /* top: -3140px;
  left: -1050px; */
  top: -3150px;
  left: -1000px;
  transform: rotate(-10deg);
}
/* }
.back-blob-2 {
  position: absolute;
  bottom: -200px;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
} */

.back-blob-forum {
  position: absolute;
  background: #f4f6f8;
  height: 100%;
  width: 100%;
}

.back-blob-auth {
  position: absolute;
  top: -50%;
  left: 50%;
  transform: rotate(-20deg) translateX(-40%);

  /* bottom: auto;
  top: -90%;
  height: 50%;
  transform: translateX(-60%) rotate(5deg); */
}
.back-blob-auth path {
  fill: #f4f6f8;
}

@media only screen and (min-width: 768px) {
  .Back-wrap .back-blob-1 {
    top: -3578px;
    left: -400px;
  }
}

@media only screen and (min-width: 1200px) {
  .Back-wrap .back-blob-1 {
    top: -3520px;
    left: -650px;
    transform: rotate(3deg);
  }
}

/* .Back-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  z-index: -1;
  overflow: hidden;
}
.Back-wrap {
  position: relative;
  min-height: 100%;
}

.Back-wrap .back-blob-1 {
  position: absolute;
  top: -3640px;
  left: -650px;
}

.Back-wrap .back-blob-2 {
  position: absolute;
  top: -200px;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
}
.back-blob-forum {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100%;
  width: 100%;
  background: #f4f6f8;
}

@media only screen and (min-width: 768px) {
  .Back-wrap .back-blob-1 {
    top: -3578px;
    left: -400px;
  }
}

@media only screen and (min-width: 1200px) {
  .Back-wrap .back-blob-1 {
    top: -3520px;
    left: -650px;
    transform: rotate(3deg);
  }
} */
