.Price-Card-wrapper {
  background: rgba(255, 0, 0, 0);
  max-width: 320px;
  margin: auto;
  border-radius: 10px;
  transition: 0.25s ease-in-out;
}
.active-card-back {
  background: #fff;
}

.priceCard-plan {
  margin: 24px auto 16px;
  font-size: 1.25rem;
  font-weight: 600;
}

.priceCard-price {
  font-weight: 600;
  /* font-size: 4rem; */
  font-size: 3.375rem;
  transition: 0.15s ease-in-out;

  /* line-height: 77px; */
}
.plan-timeferame {
  font-size: 2.75rem;
}

.card-shdow-lg .priceCard-price {
  color: #34a8fe;
}
.priceCard-timeframe {
  /* text-transform: uppercase; */
  margin: 9px auto 16px;
  font-size: 0.875rem;
  /* font-size: 0.75rem; */
  /* font-weight: 600; */
  color: #adb7be;
}

.priceCard-text {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 24px;
  text-align: center;
  width: 100%;
  /* max-width: 175px; */
  margin: 0 auto 32px;
  color: #757575;
  font-weight: 600;
}
.price-includes-container {
  margin: auto;
  align-items: flex-start;
}
.price-includes-container > * {
  /* height: 24px; */
  margin: 2px;
}
.price-includes-container svg {
  margin-right: 8px;
}
.price-includes-container svg path {
  fill: #757575;
  transition: 0.15s ease-in-out;
}
.card-shdow-lg .price-includes-container svg path {
  fill: #34a8fe;
}
.price-includes-container a {
  width: auto;
  padding: 8px 16px;
  transition: 0.15s ease-in-out;
  border-radius: 5px;
}
.price-includes-container a:hover {
  /* background: #d0eefd; */
  background: rgb(244, 246, 248);
}

.priceCard-button {
  height: 40px;
  padding: 6px 24px;
  margin-bottom: 24px;
}
/*
.price-icon-wrap {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background: #adb7be;
  margin: 8px;
}

.card-shdow-lg .price-icon-wrap {
  background: #34a8fe;
} */

.card-shdow-lg .price-includes-container {
  color: #34a8fe;
}
