.home-console-new-container {
  position: relative;
  padding: 16px 16px 80px;
  box-sizing: border-box;
  /* border: 1px solid red; */
  /* width: 100%; */
  /* display: block; */
}
.home-console-new-container > * {
  transition: 0.2s ease-in-out;
}
.home-console-new-container > *:hover {
  transform: scale(1.02);
}

.console-slider-container {
  width: 50%;
  display: flex;
  align-items: center;
  position: relative;
  background: none;
}
.console-slider-left {
  justify-content: flex-start;
}
.console-slider-right {
  justify-content: flex-end;
}

.lrg-console-img {
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.32),
    0px 15px 25px rgba(0, 0, 0, 0.12), 0px 5px 10px rgba(0, 0, 0, 0.09);
  width: 50%;
  position: absolute;
  margin: 0 Calc(25% - 16px);
  z-index: 12;
  border-radius: 7px;
}
.lrg-console-img img {
  width: 100%;
  margin: 0;
  display: block;
}

.sml-console-img {
  box-shadow: 0px 15px 32px rgba(0, 0, 0, 0.24),
    0px 5px 10px rgba(0, 0, 0, 0.09);
  width: 66.66667%;
}
.sml-console-img-left {
  transform: translateX(40px);
}
.sml-console-img-right {
  transform: translateX(-40px);
}

.console-img {
  border-radius: 7px;
  overflow: hidden;
  transition: transform 0.45s cubic-bezier(0.25, 1, 0.5, 1);
}
.console-image-active {
  transform: translateX(0);
}

.console-img-badge-constainer {
  position: absolute;
  bottom: -40px;
  transition: transform 0.45s cubic-bezier(0.25, 1, 0.5, 1),
    opacity 0.45s cubic-bezier(0.25, 1, 0.5, 1);
  transition-delay: 0.3s;
  transform: translateY(50px) scale(0);
}
.console-img-badge-constainer-lg {
  left: -24px;
}

.console-img-badge-wrap {
  width: 64px;
  height: 64px;

  border-radius: 100%;
  background: rgba(52, 168, 254, 0.3);
}

.console-img-badge-wrap-sml {
  width: 48px;
  height: 48px;
  bottom: -24px;
  box-shadow: 0px 20px 35px rgba(33, 56, 69, 0.2),
    0px 4px 12px rgba(17, 51, 83, 0.02);
}

.console-img-badge-lft {
  transform: translateY(50px) scale(0);
  left: 0;
  margin-left: -20px;
  transition-delay: 0.15s;
}
.console-img-badge-rit {
  transform: translateY(50px) scale(0);
  right: -20px;
  transition-delay: 0.45s;
}

.animate-badge1,
.animate-badge2,
.animate-badge3 {
  animation: float-alt-b 12s ease-in-out infinite;
}
.animate-badge1 {
  animation-delay: -2s;
}

.animate-badge3 {
  animation-delay: -4s;
}

.console-img-badge-active {
  transform: translateY(0) scale(1);
}

.console-img-badge-wrap div {
  width: 48px;
  height: 48px;
  border-radius: 100%;
  background: #34a8fe;
}
.console-img-badge-wrap-sml div {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background: #34a8fe;
}

.badge-ilnk-text {
  opacity: 0;
  color: #fff;
  font-weight: 700;
  position: absolute;
  line-height: 30px;
  top: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  padding: 12px;
  border-radius: 5px;
  box-sizing: border-box;
  transition: opacity 0.45s cubic-bezier(0.25, 1, 0.5, 1);
}
.console-tab-wrap-lft .badge-ilnk-text {
  left: 0;
  width: 66.66667%;
}
.console-tab-wrap-cent .badge-ilnk-text {
  left: 0;
  width: 100%;
}
.console-tab-wrap-rit .badge-ilnk-text {
  right: 0;
  width: 66.66667%;
}
.console-tab-wrap-lft:hover .badge-ilnk-text,
.console-tab-wrap-cent:hover .badge-ilnk-text,
.console-tab-wrap-rit:hover .badge-ilnk-text {
  opacity: 1;
}

@keyframes float-alt-b {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px) rotate(-8deg);
  }
  50% {
    box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    transform: translatey(-16px) rotate(8deg);
  }
  100% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px) rotate(-8deg);
  }
}

@media only screen and (min-width: 768px) {
  .home-console-new-container {
    margin-left: -16px;
    padding: 24px 0 120px;
  }
  .console-img-badge-wrap {
    width: 96px;
    height: 96px;
  }
  .console-img-badge-wrap-sml {
    width: 64px;
    height: 64px;
  }
  .console-img-badge-wrap div {
    width: 80px;
    height: 80px;
  }
  .console-img-badge-wrap-sml div {
    width: 52px;
    height: 52px;
  }
  .lrg-console-img {
    margin: 0 25%;
  }
}
