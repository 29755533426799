.support-page-wrap {
  margin: 0 16px;
}
.support-card {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.24);
  margin-bottom: 24px;
}

.support-card-head,
.support-card-footer,
.support-card-body-header,
.support-card-body-post {
  padding: 0 8px;
}

.support-card-head,
.support-card-footer {
  height: 56px;
  width: 100%;
  background: #f5f5f5;
}
.support-card-head {
  border-bottom: 1px solid #e0e0e0;
  position: relative;
  box-sizing: border-box;
}
.support-card-head form {
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  color: #989898;
  cursor: text;
}

.support-card-head button {
  margin-left: 16px;
}

.support-card-head-search {
  width: 100%;
}
.support-card-head-search svg {
  position: absolute;
}
.support-card-head-search input {
  width: 100%;
  height: 36px;
  padding-left: 32px;
  outline: none;
  border: none;
  background: none;
  box-sizing: border-box;
  transition: 0.15s ease-in-out;
}
.support-card-head-search input:focus {
  position: absolute;
  width: Calc(100% - 16px);
  left: 8px;
  padding-left: 8px;
  outline: none;
  border: none;
  background: #fff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.24);
}

.support-card-body-header,
.support-card-body-post {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 56px;
}

.support-card-body-header {
  border-bottom: 1px solid #e0e0e0;
  height: 32px;
  width: 100%;
  background: #f5f5f5;
}

.support-card-footer h6,
.support-card-body-header h6 {
  font-size: 0.875rem;
  font-weight: 500;
  color: #707070;
  margin: 0;
}

.support-card-body-post {
  /* height: 48px; */
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
}
.support-card-body-post > *:last-child {
  margin: auto;
}
.support-card-body-post h3,
.support-card-body-post p {
  margin: 14px 0;
  font-size: 0.875rem;
}

.support-card-footer {
  border-top: 1px solid #e0e0e0;
}
.support-card-footer button,
.support-card-footer h6 {
  margin-left: 16px;
}

/* Ticketing */
.ticket-container {
  border-radius: 5px;
  padding: 24px 16px;
  margin-bottom: 32px;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.ticket-container h5,
.ticket-container p,
.ticket-button button {
  position: relative;
  z-index: 6;
}

.ticket-container h5 {
  font-size: 1.5rem;
  margin-bottom: 8px;
  font-weight: 700;
}
.ticket-container p {
  color: #939eab;
}
.ticket-card-bubble {
  width: 70%;
  transform: rotate(-90deg);
  position: absolute;
  top: -100%;
  right: -30%;
  z-index: 5;
}
.ticket-button {
  justify-content: center;
}
.ticket-button button {
  height: 40px;
}

.ticket-button button svg {
  margin: 0 0 0 12px;
}

@media only screen and (min-width: 660px) {
  /* .support-header-wrapped {
    width: 50%;
  }
  .support-header-wrapped svg {
    margin-top: 0;
  }
  .support-header {
    flex-direction: row;
    text-align: left;
  } */

  .support-card-head-search input {
    padding-left: 36px;
  }
  .support-card-head-search input:focus {
    width: Calc(100% - 32px);
    left: 16px;
    padding-left: 16px;
  }

  .ticket-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
  }
  .ticket-text {
    width: 60%;
  }
  .ticket-button {
    width: 30%;
  }
}

@media only screen and (min-width: 768px) {
  .support-card {
    margin-bottom: 48px;
  }

  .support-card-head,
  .support-card-footer,
  .support-card-body-header,
  .support-card-body-post {
    padding: 0 24px;
  }

  .pricing-page-wrap {
    margin: 0;
  }
  .pricing-table-wrap {
    margin: 8px;
  }
  .support-card-body-header,
  .support-card-body-post {
    grid-template-columns: 1fr 160px 56px;
  }
}

@media only screen and (min-width: 960px) {
  .support-card {
    margin-bottom: 48px;
  }

  .support-card-head,
  .support-card-footer,
  .support-card-body-header,
  .support-card-body-post {
    padding: 0 40px;
  }

  .pricing-table-wrap {
    margin: 56px 24px;
  }
}
@media only screen and (min-width: 1200px) {
  .support-card-head,
  .support-card-footer,
  .support-card-body-header,
  .support-card-body-post {
    padding: 0 72px;
  }

  .pricing-table-wrap {
    margin: 72px 56px;
  }
}
