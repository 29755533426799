.footer-container {
  text-align: center;
  background: #28323c;
  box-sizing: content-box;
  /* border: 1px solid red;
  position: sticky;
  bottom: 0; */
  /* width: 100%;
  position: absolute;
  bottom: 0; */
}
.footer-wrap {
  display: grid;
  padding: 32px 16px;
  color: white;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 32px;
}

.footer-wrap ul {
  list-style: none;
  padding: 0;
  margin: 0 0 6px;
}
.footer-wrap ul > *:last-child {
  margin-bottom: 0;
}

.footer-wrap li {
  width: 100%;
  margin-bottom: 10px;
}
.footer-wrap li > *:first-child {
  margin-right: 14px;
}
.footer-wrap li p {
  margin: 0;
}
.footer-wrap li a p {
  transition: 0.1s ease-in-out;
  cursor: pointer;
}
.footer-wrap li a:hover p {
  opacity: 1;
  color: #34a8fe;
}

.footer-wrap p {
  font-size: 12px;
  font-weight: 300;
  opacity: 0.6;
}

.footer-wrap h4 {
  line-height: 24px;
  margin-bottom: 16px;
}

.footer-logo {
  height: 22px;
  margin: 0 auto 18px;
}

.footer-wrap li,
.footer-subscribe form,
.footer-app-badges {
  justify-content: center;
}

.footer-app-badges {
  flex-wrap: wrap;
}
.footer-app-badges > * {
  margin-top: 12px;
}

.footer-social-icons > *:not(:last-child),
.footer-app-badges > *:first-child {
  margin-right: 12px;
}

.footer-subscribe .sub-form button {
  border: none;
  border-radius: 0 5px 5px 0;
  padding: 4px;
  background: #34a8fe;
}

.footer-subscribe input {
  background: #323a42;
  border: none;
  color: #fff;
  border-radius: 5px 0 0 5px;
  width: Calc(100% - 32px);
  max-width: 160px;
  height: 32px;
  font-family: inherit;
  box-sizing: border-box;
  padding: 6px 0 6px 12px;
}
.footer-subscribe input ::placeholder {
  color: #8093a2;
}

.footer-legal {
  height: 24px;
  background: #1c252e;
  text-align: center;
}
.footer-legal p {
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  margin: 0;

  color: #8093a2;
}
.futter-sub-button-adj {
  margin: auto;
  padding: 0 16px;
}

/* large phone */
@media only screen and (min-width: 420px) {
  .footer-wrap {
    grid-template-columns: repeat(2, 1fr);
    text-align: left;
    grid-gap: 24px;
  }
  .footer-wrap > * {
    position: relative;
  }
  .footer-wrap li,
  .footer-subscribe form,
  .footer-app-badges {
    justify-content: flex-start;
  }
  .footer-logo {
    margin: 0 0 18px;
  }
  .footer-social-icons > *:first-child {
    margin-left: -6px;
  }
  .futter-sub-button-adj {
    margin: 0;
  }
}

@media only screen and (min-width: 768px) {
  .footer-wrap {
    grid-template-columns: repeat(4, 1fr);

    padding: 32px 0;
  }
  .posts-container {
    grid-column: 1 / 6;
  }
}
