.ProductGrid-container {
  margin: auto;
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 0;
}
.ProductGrid-container li {
  padding-right: 0;
}

@media only screen and (min-width: 768px) {
  .ProductGrid-container {
    grid-template-columns: auto auto;
    margin-top: 24px;
  }
}

@media only screen and (min-width: 1200px) {
  .ProductGrid-container {
    grid-template-columns: auto auto auto;
    margin-top: 32px;
  }

  .ProductGrid-container li:not(:nth-child(3n)) {
    padding-right: 80px;
  }

  .ProductGrid-container li:not(:nth-child(3n)) .prodbox-divLine div {
    width: Calc(100% + 80px);
  }

  .ProductGrid-container
    li:nth-child(3n + 1):nth-last-child(-n + 3)
    .prodbox-divLine
    div,
  .ProductGrid-container
    li:nth-child(3n + 1):nth-last-child(-n + 3)
    ~ li
    .prodbox-divLine
    div {
    border-top: 1px transparent;
  }
}

@media (max-width: 1200px) and (min-width: 768px) {
  .ProductGrid-container li:not(:nth-child(2n)) {
    padding-right: 80px;
  }
  .ProductGrid-container li:not(:nth-child(2n)) .prodbox-divLine div {
    width: Calc(100% + 80px);
  }

  .ProductGrid-container
    li:nth-child(2n + 1):nth-last-child(-n + 2)
    .prodbox-divLine
    div,
  .ProductGrid-container
    li:nth-child(2n + 1):nth-last-child(-n + 2)
    ~ li
    .prodbox-divLine
    div {
    border-top: 1px transparent;
  }
}
