.modal {
  background: #ffffff;
  box-shadow: 0px 20px 35px rgba(33, 56, 69, 0.2),
    0px 4px 12px rgba(17, 51, 83, 0.02);
  border-radius: 5px;

  font-size: 0.875rem;
}

.h-pop-wrap {
  position: absolute;
  top: 64px;
  left: 0;
  display: flex;
  justify-content: flex-end;
  max-width: 100%;
  box-sizing: border-box;
  pointer-events: none;
  z-index: 110;
}
.h-pop {
  width: 280px;
  margin-right: 16px;
  pointer-events: all;
}

.h-pop-sml,
.h-pop-sml p {
  font-size: 0.75rem;
  color: #919498;
  white-space: nowrap;
}
.pop-b-adj {
  width: Calc(50% - 6px);
}

.pop-sec {
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
  border-bottom: 1px solid #e8eaed;
}
.pop-sec-txt {
  margin: 12px 0;
}
.pop-sec-txt {
  color: #5f6368;
}
.pop-alert-empt {
  color: #dadce0;
}
.pop-alert-empt div {
  height: 32px;
  width: 32px;
  border-radius: 100%;
  margin-right: 12px;
  background: #dadce0;
}
.pop-foot {
  padding: 12px;
  border-bottom: none;
  justify-content: center;
}
/* .pop-foot {
  color: #919498;
} */

.pop-or-lin {
  margin: 10px 0 -10px;
  width: 100%;
  border: 0.5px solid #e8eaed;
}
.h-pop input {
  width: 100%;
  height: 32px;
  /* margin-bottom: 12px; */

  padding: 0 12px;
  font-size: 0.875rem;
  font-weight: 400;
  color: #323a42;

  display: flex;
  align-items: center;

  background: #ffffff;
  border: 1px solid #34a8fe;
  box-shadow: 0 0 1px 0px white inset, 0 0 1px 0px white;
  outline: none;
  box-sizing: border-box;
  border-radius: 5px;
}

/* .auth-pass {

} */

.h-pop input::placeholder {
  color: #919498;
}
.pop-sinup {
  cursor: pointer;
  justify-content: center;
  transition: 0.15s;

  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  color: #919498;
}
.pop-sinup:hover,
.pop-foot a:hover {
  color: #34a8fe;
}

.prof {
  position: relative;
}
.prof .userProPic {
  width: 64px;
  height: 64px;
  border-radius: 100%;
  overflow: hidden;
}
.prof .userProPic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.pop-sec-txt {
  margin: 8px auto 12px;
}
.user-email {
  color: rgba(47, 61, 77, 0.4);
}

.user-username {
  font-size: 1rem;
  font-weight: 500;
  color: #2f3d4d;
  /* height: 18px; */
}
.pop-alert-emp {
  color: rgba(47, 61, 77, 0.3);
  font-weight: 700;
}
.pop-alert-emp div {
  border-radius: 100%;
  margin-right: 16px;
  width: 32px;
  height: 32px;
  background: rgba(47, 61, 77, 0.2);
}

@media only screen and (min-width: 768px) {
  .h-pop {
    top: 64px;
    right: 16px;
  }
}

/* Small Desktop */
@media only screen and (min-width: 960px) {
}

/* Desktop */
@media only screen and (min-width: 1200px) {
}
