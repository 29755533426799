.title-input-container {
  border-radius: 5px;
  border: 1px solid #cfd8dc;
  padding: 0 0 0 8px;
  height: 42px;
  transition: 0.4s;
  position: relative;
  /* width: 100%; */
}
.title-input-input-wrap {
  width: 100%;
}
.title-input-container input {
  outline: none;
  border: none;
  box-shadow: none;
  /* min-width: 100%; */
  width: 100%;
  padding-left: 4px;
  box-sizing: border-box;
}

/* --------------------------------------------- */

.title-input {
  /* font-size: 1rem; */
  font-size: 1.25rem;
  font-weight: 600;
}
