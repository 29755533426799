.auth-page-wrapper {
  width: 100%;
  height: 100%;
  min-height: 80vh;
}
.signin-container {
  background: #28323c;
  box-sizing: border-box;
  max-width: 280px;
  margin: 16px auto;
  color: #8093a2;
  border-radius: 10px;
  padding: 16px 24px;
}

.signin-up-wrap {
  color: #fff;
  text-transform: uppercase;
  border-bottom: 0.5px solid #8093a2;
}
.signin-up-button {
  width: 150%;
  height: 36px;
  justify-content: center;
}
.signin-up-hilight {
  height: 3px;
  background: #34a8fe;
  width: 50%;
  margin-left: 50%;
  transition: 0.2s ease-in-out;
  border-radius: 0 0 5px 5px;
  padding: 0 8px;
}

.auth-button-wrap {
  margin-bottom: 16px;
}
.auth-button-wrap .button-silver {
  color: #fff;
}
.auth-button-wrap .button-silver:hover {
  color: #34a8fe;
}
.auth-button-wrap .button-white {
  background: none;
}

.third-party-wrap {
  font-size: 0.75rem;
  text-align: left;
  box-sizing: border-box;
  padding: 16px 0 0;
}
.third-party-button-wrap {
  margin: 6px 0 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 8px;
  width: 100%;
  /* border: 1px solid red; */
}

.third-party-button-wrap button {
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.signin-divider {
  height: 44px;
  flex-direction: column;
  justify-content: center;
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.5);
}
.signin-dividerline {
  margin: 10px 0 -10px;
  width: 100%;
  border: 0.5px solid rgba(255, 255, 255, 0.1);
}
.signin-divider-or {
  background: #28323c;
}

.signin-container input {
  width: 100%;
  height: 36px;
  margin-bottom: 0px;

  padding: 0 12px;
  font-size: 0.875rem;
  font-weight: 400;
  color: #fff;

  display: flex;
  align-items: center;

  background: rgba(255, 255, 255, 0.1);
  border: none;
  outline: none;
  box-sizing: border-box;
  border-radius: 5px;
}
.signin-container input:invalid {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #ff4e71;
  color: #fff;
}

.signin-container input::placeholder {
  color: #919498;
}

.signup-disclaimer {
  font-size: 0.625rem;
  margin-bottom: 16px;
}
.form-error {
  color: #ff4e71;
}
