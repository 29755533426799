.home-topic-container {
  /* height: 120px; */
}

/* .posts-header-lin {
  width: 100%;
  border-bottom: 1px solid #939eab;
} */

@media only screen and (min-width: 768px) {
  .posts-container {
    grid-column: 1;
  }
}
