.mobile-seciton-item {
  width: 100%;
  text-align: left;
  padding: 0 8px;
}

.mobile-seciton-item-head {
  height: 48px;
  padding: 8px;
  box-sizing: content-box;
  cursor: pointer;
}
.mobile-seciton-item-head h4 {
  /* max-width: Calc(100% - 66px); */
}

.mobile-seciton-item-head-icon {
  height: 40px;
  width: 40px;
  border-radius: 100%;
  margin-right: 12px;
}

.mobile-seciton-item-body {
  transition: 0.15s ease-in-out;
  padding: 8px 0 0 16px;
  color: #757575;
  font-size: 0.875rem;
  overflow: hidden;
  max-width: 550px;
}
.mobile-seciton-item-body div {
  margin-bottom: 8px;
}
.mobile-seciton-item-body div b {
  color: rgb(52, 168, 254);
  font-weight: 600;
}
.mobile-seciton-item-body div span {
  color: #323a42;
  font-size: 0.75rem;
  color: #757575;
  font-weight: 400;
  margin-right: 12px;
}
