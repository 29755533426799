.header-banner-container {
  padding: 0 12px;
}

.usr-banner-top-container {
  padding: 24px 0 32px;
  width: 100%;
  flex-direction: column-reverse;
}

.usr-ban-profile-info {
  display: flex;
  justify-content: flex-start;
  margin-top: -16px;
  width: 100%;
}
.usr-ban-profile-info > *:first-child {
  margin-right: 18px;
}

.usr-ban-profile-info-imgwrap {
  position: relative;
}

.usr-ban-profile-info-contrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.usr-ban-profile-info-contrap h1 {
  color: white;
  margin: 6px 0 0;
}

.usr-ban-profile-info-contrap > *:first-child:first-child {
  color: white;
}

.usr-ban-profile-info-imgwrap img {
  border-radius: 100%;
  border: 6px solid #28323c;
  height: 96px;
  width: 96px;
  display: block;
  margin-left: -6px;
  object-fit: cover;
}
.usr-ban-profile-info-imgwrap-badge {
  position: absolute;
  width: 24px;
  height: 24px;
  left: 78px;
  top: 78px;
  border-radius: 100%;
  background: #ffffff;
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.09);
}

.banner-feature-container {
  width: 100%;
  height: 96px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 0 32px;
  justify-content: space-between;
}

.banner-feature-wrap {
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.banner-feature-wrap > *:last-child {
  opacity: 0.5;
  font-size: 0.75rem;
  font-weight: 300;
  margin-left: 0;
}

.banner-feature-div {
  width: 0px;
  height: 33px;
  border-left: 0.5px solid #8093a2;
  margin: 0 24px;
}

.usr-banner-bottom-container {
  height: 48px;
}
.usr-banner-bottom-buttons button:not(:last-child) {
  margin-right: 8px;
}

.usr-banner-bottom-buttons button {
  height: 36px;
  padding: 0 12px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  outline: none;
  border: none;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 19px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.1s ease-in;
}
.usr-banner-bottom-buttons button:hover {
  background: rgba(255, 255, 255, 0.2);
}
.usr-banner-bottom-buttons button svg {
  width: 24px;
  height: 24px;
}

@media only screen and (min-width: 768px) {
  .header-banner-container {
    padding: 0;
  }
  .usr-banner-top-container {
    flex-direction: row;
    width: auto;
  }
  .usr-ban-profile-info {
    margin-top: 0;
  }
  .usr-ban-profile-info-imgwrap img {
    border: 6px solid rgba(255, 255, 255, 0.1);
  }
  .usr-ban-profile-info-contrap {
    justify-content: space-between;
  }
  .banner-feature-container {
    width: auto;
  }
}
