.post-card-container {
  background: #fff !important;
  border-radius: 10px;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  margin: 0 0 8px -8px;
  width: Calc(100% + 16px);

  transition: 0.25s ease;
  cursor: pointer;
  animation: ;
}
.post-card-container:hover {
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.09);
}

.post-delete-animation {
  animation: delete-card 2s cubic-bezier(0, 1, 0.1, 1) forwards;
}

@keyframes delete-card {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.5);
  }
}

.post-card-header,
.post-card-footer,
.post-card-content {
  min-height: 48px;
  box-sizing: border-box;
  padding: 12px;
}

.post-card-header > *:first-child {
  flex-wrap: wrap;
}

.post-card-header-tag {
  height: 24px;
  padding: 0 6px;
  margin-right: 4px;
  box-sizing: border-box;
  border-radius: 5px;
  color: #34a8fe;
  font-size: 0.75rem;

  cursor: pointer;
  transition: 0.15s ease;
}

.post-doc-type {
  font-weight: 600;
  background: rgba(52, 168, 254, 0.1);
}
.post-doc-type:hover {
  background: rgba(52, 168, 254, 0.3);
}
.post-top-type {
  border: 1px solid #34a8fe;
}
.post-top-type:hover {
  background: rgba(52, 168, 254, 0.1);
}

/* CONTENT----------------------------------------------- */
.post-card-content-image {
  width: Calc(100% + 24px);
  margin: 0 0 -12px -12px;
  object-fit: cover;
  overflow: hidden;
  max-height: 440px;
  box-sizing: border-box;
  display: block;
}

.post-card-content h3 {
  font-weight: 600;
  font-size: 18px;
  display: flex;
  align-items: center;
  color: #323a42;
}
.post-card-content h6 {
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  letter-spacing: 0.75px;
  color: #b1b4c7;
  margin: 6px 0 12px;
}
.post-card-content p {
  color: #757575;
}

/* PREVIEW----------------------------------------------- */

.forum-card-link-prev-wrap {
  width: 100%;
  padding: 12px;
  margin: 0 0 -0;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #cfd8dc;
  cursor: pointer;
  transition: 0.15s ease-in;
}
.LinkPreview-image-container {
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  max-width: 120px;
  min-width: 80px;
  height: 88px;
  margin-right: 16px;
  border-radius: 5px;
}

.forum-card-link-prev-text-wrap {
  width: 100%;
  text-align: left;
}
.forum-card-link-prev-wrap h4 {
  font-size: 0.75rem;
  font-weight: 500;
  word-break: break-all;
}
.forum-card-link-prev-wrap p {
  font-size: 0.75rem;
  margin: 0;
  word-break: break-all;
}

.forum-card-link-prev-wrap:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.1);
}

.forum-card-link-prev-wrap img {
  min-width: 100%;
  height: 100%;
  background: #f4f6f8;
  object-fit: cover;
}
.LinkPreview-url-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  background: rgba(0, 0, 0, 0.65);
}

/* DROPDOWN----------------------------------------------- */
.more-dots-drop {
  position: relative;
  color: #939eab;
  /* font-size: 0.875rem; */
  font-weight: 400;
}
.more-dots-drop-dropdown {
  cursor: default;
  position: relative;
  z-index: 6;
}
.more-dots-drop-item {
  padding: 4px 24px;
  transition: 0.15s ease;
  cursor: pointer;
}

.more-dots-drop-item svg {
  margin-right: 16px;
}
.more-dots-drop .more-dots-drop-item svg path {
  fill: #939eab;
}

.more-dots-drop-item:hover,
.more-dots-drop-item:hover svg path {
  /* background: #f4f6f8; */

  background: rgba(52, 168, 254, 0.1);
  color: #34a8fe;
  fill: #34a8fe;
}

.more-dots-drop-dropdown {
  position: absolute;
  top: 32px;
  right: -4px;
  padding: 8px 0 12px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.12), 0px 3px 6px rgba(0, 0, 0, 0.09);
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.1); */
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.1); */
}

/* OPTIONSMODAL---------------------------------------- */

.options-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 120;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  cursor: auto;
}

.popup-container {
  padding: 24px;
  margin: 24px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.14);
}
.pop-container-text {
  margin: 0 0 24px;
  max-width: 264px;
  text-align: center;
  font-size: 1rem;
  line-height: 1.6rem;
  font-weight: 500;
}

.popup-container button {
  min-width: 120px;
}

/* SAVEPOPUP--------------------------------------------- */
.pop-save-wrap {
  width: 100%;
  max-width: 540px;
  position: relative;
  box-sizing: border-box;
}

.pop-save-wrap-divlin {
  border-bottom: 1px solid #cfd8dc;
  margin: 16px -24px;
}

.pop-save-wrap input {
  border: 1px solid #cfd8dc;
  border-radius: 5px;
  box-shadow: none;
  background: none;
  width: 100%;
  margin-top: 16px;
  box-sizing: border-box;
  height: 40px;
}

.save-pop-collection-wrap {
  margin: 16px 0 16px;
  border: 1px solid #cfd8dc;
  border-radius: 5px;
  width: 100%;
  height: 200px;
}

/* FOOTER--------------------------------------------- */
.post-card-footer {
  background: #f9fafb;
}
.post-card-footer-like-wrap button,
.post-card-footer-profile-wrap,
.post-card-footer-comment-wrap,
.pop-close-button,
.more-dots-button {
  padding: 6px;
  margin: -6px;
  border-radius: 5px;
  box-sizing: border-box;
  transition: 0.1s ease;
  cursor: pointer;
}
.post-card-footer-like-wrap button:hover,
.post-card-footer-profile-wrap:hover,
.post-card-footer-comment-wrap:hover,
.pop-close-button:hover,
.more-dots-button:hover {
  background: rgba(52, 168, 254, 0.1);
}

.post-card-footer-profile-wrap img {
  height: 24px;
  width: 24px;
  object-fit: cover;
  border-radius: 5px;
  overflow: hidden;
}
.post-card-footer-profile-wrap p {
  margin: 0 0 0 8px;
}

.post-card-footer-comment-wrap svg {
  margin-right: 12px;
}

.post-card-footer-like-wrap button {
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
}

.post-card-footer-comment-wrap svg path,
.post-card-footer-like-wrap button svg path,
.pop-close-button:hover svg path {
  transition: fill 0.1s ease;
}
.post-card-footer-like-wrap button:hover svg path,
.post-card-footer-comment-wrap:hover p,
.post-card-footer-comment-wrap:hover svg path,
.more-dots-button:hover .more-dots-icon path,
.pop-close-button:hover svg path {
  color: #34a8fe;
  fill: #34a8fe;
}
.post-card-footer-like-wrap p,
.post-card-footer-comment-wrap p {
  margin: 0 12px;
  font-weight: 500;
  color: #b1b4c7;
}

.active-like-button svg path,
.post-card-footer-like-wrap .active-like-text p {
  fill: #039be5;
  color: #039be5;
}

@media only screen and (min-width: 768px) {
  .post-card-container {
    margin: 0 0 8px 0;
    width: 100%;
  }
  .forum-card-link-prev-wrap h4 {
    font-size: 1rem;
  }
  .forum-card-link-prev-wrap p {
    font-size: 0.875rem;
  }
  .LinkPreview-image-container {
    width: 180px;
    min-width: 180px;
    height: 120px;
  }
  .post-card-header-tag {
    padding: 0 12px;
    margin-right: 8px;
  }
}
