.head-container {
  width: 100%;
  z-index: 100;
  position: sticky;
  /* position: fixed; */

  top: 0;
  background: #ffffff;
  transition: 0.15s ease-in-out;
  /* overflow-y: hidden; */
}
.head-container.active {
  /* box-shadow: 0px 1px 2px rgba(60, 64, 67, 0.3),
    0px 2px 6px rgba(60, 64, 67, 0.15); */

  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
}
